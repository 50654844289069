//src/blog/pages/ListOfSameCategoryBlogPosts.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import useFetchAllSameCatBlogPosts from '../../hooks/useFetchAllSameCatBlogPosts';
import { RoutePaths } from '../../routes/RoutePaths';

const ListOfSameCategoryBlogPosts: React.FC = () => {
  const { categoryName } = useParams<{ categoryName: string }>();
  const { BlogPosts } = useFetchAllSameCatBlogPosts(categoryName);
  const { CLIENT_BASE_URL, CLIENT_BLOG_URL } = config;

  return (
    <div>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">All blog posts in "{categoryName}"
            </h2>
          </div>
          <hr className="border border-2" />
          <div className="mx-auto max-w-screen-sm divide-y divide-gray-200 dark:divide-gray-700">

            {BlogPosts.map((blogPost) => (
              <article key={blogPost.id}
                className="py-12 text-center"
              >
                <div className='flex justify-center mb-6'>
                  {blogPost.categoryNames.map((categoryName, index) => (
                    <Link key={index} to={RoutePaths.ListOfSameCategoryBlogPosts.replace(':categoryName', categoryName)}>
                      <span className="ml-2 bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                        <svg className="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z">
                          </path>
                        </svg>
                        {categoryName}
                      </span>
                    </Link>
                  ))}
                </div>
                <div className='flex justify-center'>
                  <Link to={CLIENT_BASE_URL + CLIENT_BLOG_URL + blogPost.slug}>
                    <img src={blogPost.featuredMediaUrl}
                      className="max-w-sm my-shadow relatedArticleImg"
                      alt={blogPost.altText}
                      loading="lazy"
                    />
                  </Link>
                </div>
                <h2 className="my-4 text-2xl font-bold tracking-tight text-gray-900 md:text-3xl dark:text-white">
                  <Link to={CLIENT_BASE_URL + CLIENT_BLOG_URL + blogPost.slug}>
                    {blogPost.title}
                  </Link>
                </h2>
                <p className="mb-4 text-lg font-normal dark:text-gray-300">
                  {blogPost.excerpt}
                </p>
                <div className="flex justify-center items-center space-x-4 text-gray-500 sm:space-x-6">
                  {/* <div className="flex items-center space-x-3">
              <img
                className="w-6 h-6 rounded-full"
                alt={blogPost.authorName}
                src={blogPost.authorImageUrl}
              />
              <span className="text-lg font-medium text-gray-900 dark:text-white">
                {blogPost.authorName}
              </span>
            </div> */}
                  <span>
                    {new Date(blogPost.date).toLocaleDateString()}
                  </span>
                </div>
              </article>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};
export default ListOfSameCategoryBlogPosts;
