import React, { useEffect } from 'react';

interface DragControlsProps {
  onMaxHeightChange: (value: number) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  initialHeight: number;
  onDragStart: () => void;
  onDragEnd: () => void;
}

const DragControls: React.FC<DragControlsProps> = ({ onMaxHeightChange, initialHeight, onDragStart, onDragEnd }) => {
  const handleRef = React.useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = React.useState(false);
  const [startY, setStartY] = React.useState(0);
  const [startHeight, setStartHeight] = React.useState(0);
  React.useEffect(() => {
    setStartHeight(initialHeight);
  }, [initialHeight]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const handleMouseDown = (event: React.MouseEvent) => {
    setIsDragging(true);
    const mouseY = event.clientY;
    onDragStart();
    setStartY(mouseY);
    document.body.style.userSelect = 'none';
  };

  const handleMouseUp = (e: MouseEvent) => {
    if (isDragging && handleRef.current) {
      onDragEnd();
      const finalDelta = e.clientY - startY;
      const finalHeight = Math.max(0, startHeight + finalDelta);
      onMaxHeightChange(finalHeight);
      document.body.style.userSelect = '';
    }
    setIsDragging(false);
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging && handleRef.current) {
      const delta = e.clientY - startY;
      const newHeight = Math.max(0, startHeight + delta);
      onMaxHeightChange(newHeight);
    }
  };


  return (
    <div
      ref={handleRef}
      onMouseDown={handleMouseDown}
      className="draggable-boundary cursor-ns-resize  h-1 w-full flex justify-center items-center text-xs font-medium text-gray-900 dark:text-gray-100"
    >
    </div>
  );

};

export default DragControls;
