import React from 'react';

interface AboutFileProps {
  patentStatus: string;
  latestPatentStatusChangeDate: string;
  languageOfFiling: string;
  firstApplicationReferenceCountry: string;
  firstApplicationReferenceDocNumber: string;
}

const AboutFile: React.FC<AboutFileProps> = ({ firstApplicationReferenceCountry, firstApplicationReferenceDocNumber, patentStatus, latestPatentStatusChangeDate, languageOfFiling }) => {
  return (
    <div id="about">
      <div className="relative mx-auto max-w-screen-sm overflow-x-auto rounded shadow-md shadow-gray-500/50 dark:shadow-none mb-8">
        <table className="w-full text-xs lg:text-base text-left ">
          <thead className="text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-white">
            <tr>
              <th scope="col" className="px-2 sm:px-6 py-3 w-1/3 ">
                About this file:
              </th>
              <th scope="col" className="pr-2 sm:pr-6 py-3 w-2/3">
                <div className="flex items-center justify-start">
                  {firstApplicationReferenceCountry}{firstApplicationReferenceDocNumber}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
                <p className="text-gray-700 dark:text-white font-normal">Status:</p>
              </th>
              <td className="pr-2 sm:pr-6 w-2/3">
                <p className="dark:text-white font-normal">{patentStatus}</p>
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
                <p className="text-gray-700 dark:text-white font-normal">Status update:</p>
              </th>
              <td className="pr-2 sm:pr-6 w-2/3">
                <p className="dark:text-white font-normal">{latestPatentStatusChangeDate}</p>
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
                <p className="text-gray-700 dark:text-white font-normal">Language of EP filing:</p>
              </th>
              <td className="pr-2 sm:pr-6 w-2/3">
                <p className="dark:text-white font-normal">{languageOfFiling.toUpperCase()}</p>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AboutFile;