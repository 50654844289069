// src/components/dashboard/categories/Categories.tsx
import React, { useState } from 'react';
import AllCategories from './AllCategories';
import CreateCategory from './CreateCategory';

const Categories: React.FC = () => {
  const [categoryCreated, setCategoryCreated] = useState(false);

  const handleCategoryCreation = () => {
    setCategoryCreated(true);
  };
  return (
    <div>
      <section className="py-4 bg-white dark:bg-gray-900 lg:pt-12 lg:pb-16">
        <div className="px-4 mx-auto max-w-8xl lg:px-4 lg:text-center">

          <h2 className="mt-16 mb-4 text-2xl font-bold tracking-tight text-gray-900 lg:font-extrabold lg:text-4xl lg:leading-none dark:text-white lg:text-center xl:px-36 lg:mb-7">
            Organize your queries by categories
          </h2>
          <p className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 lg:text-center lg:text-xl xl:px-60">
            Create, edit, and delete categories.
          </p>
          <AllCategories categoryCreated={categoryCreated} setCategoryCreated={setCategoryCreated} />
          <CreateCategory onCategoryCreate={handleCategoryCreation} />
        </div>
      </section>
    </div>
  );
};

export default Categories;
