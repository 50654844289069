import React from 'react';
import generateTimelineMarks from '../utils/generateTimelineMarks';

interface TimelineProps {
  startDate: Date;
  endDate: Date;
  fixedMarkerDistance: number;
  maxHeight: number;
}

const Timeline: React.FC<TimelineProps> = ({ startDate, endDate, fixedMarkerDistance, maxHeight }) => {
  const timelineMarks = generateTimelineMarks(startDate, endDate, fixedMarkerDistance);

  return (
    <div className="mx-auto pb-16">
      {timelineMarks.map((mark, index) => (
        <div key={mark.year}
          className={`absolute border-t h-2 z-20 bg-gray-500 dark:bg-gray-300 ${index === 0 || index === timelineMarks.length - 1 ? 'hidden' : ''}`}
          style={{ left: `${mark.leftPosition}px` }}>
          <div className='flex justify-center'>
            <span className="text-xs absolute -bottom-6 dark:text-white py-1 px-1.5 border border-2 rounded-full border-purple-400 my-shadow-sharp mx-auto bg-gray-200 dark:bg-gray-700 text-xs font-medium text-gray-900 dark:text-gray-100">
              {mark.year}
            </span>
          </div>
          <div className="border-dashed border-r border-gray-500 h-full border-purple-400"
            style={{ height: `${maxHeight}px` }}>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
