//src/business/pages/hooks/useFlagLogic.ts
import { RowItem } from "../../models/FamilyTreeInterfaces";

const useFlagLogic = (item: RowItem) => {
  let flagSrc = '';
  let flagTitle = '';
  let isRoDisplayed = false;

  if (item.country !== "WO") {
    if (item.country === "EP") {
      flagSrc = "https://plevanltd.com/images/light/EPO.webp";
      flagTitle = "European Patent Office";
    } else if (item.country === "EA") {
      flagSrc = "https://flagcdn.com/w80/ru.webp";
      flagTitle = "Eurasian Patent Organization";
    } else {
      flagSrc = `https://flagcdn.com/w80/${item.country.toLowerCase()}.webp`;
      flagTitle = "Jurisdiction: " + item.countryName;
    }
  } else {
    isRoDisplayed = true;
  }

  return { flagSrc, flagTitle, isRoDisplayed };
};

export default useFlagLogic;