// src/blog/components/CommentSection.tsx

import React, { useEffect, useState } from 'react';
import { BlogPostCommentDTO, commentService } from '../../services/commentService';
import CommentForm from './CommentForm';
import CommentList from './CommentList';
import LoadingComments from './LoadingComments';
import { useAuthContext } from '../../contexts/AuthContext';
import useOrganizedComments from '../hooks/useOrganizedComments';


interface CommentSectionProps {
  postId: number;
  onCommentCountUpdate: (count: number) => void;
}

const CommentSection: React.FC<CommentSectionProps> = ({ postId, onCommentCountUpdate }) => {
  const [comments, setComments] = useState<BlogPostCommentDTO[]>([]);
  const organizedComments = useOrganizedComments(comments);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const authContext = useAuthContext();
  if (!authContext) {
    return null;
  }
  const { user } = authContext;

  useEffect(() => {
    fetchComments();
  }, [postId]);

  const fetchComments = async () => {
    setIsLoading(true);
    try {
      const fetchedComments: BlogPostCommentDTO[] = await commentService.fetchBlogPostComments(postId);
      setComments(fetchedComments);
      onCommentCountUpdate(fetchedComments.length);
      setError(null);
    } catch (err) {
      setError('Error fetching comments');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteComment = async (commentId: number) => {
    try {
      await commentService.deleteBlogPostComment(commentId);
      setComments(prevComments => {
        // Create a new list of comments, excluding the deleted one
        const updatedComments = prevComments.filter(comment => comment.id !== commentId);
        // Update the comment count
        onCommentCountUpdate(updatedComments.length);
        return updatedComments;
      });
    } catch (err) {
      console.error('Error deleting comment', err);
    }
  };

  if (isLoading || error || !comments) {
    return <LoadingComments message={isLoading ? "Loading content..." : error || "No comments found"} />;
  }

  return (
    <div className="comments-section">
      {user &&
        <CommentForm
          postId={postId}
          onCommentPosted={fetchComments}
        />}
      <CommentList
        comments={organizedComments}
        onDeleteComment={handleDeleteComment}
        postId={postId}
        onCommentPosted={fetchComments}
      />
    </div>
  );
};

export default CommentSection;