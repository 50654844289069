import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import '../../FamilyTree.css';
import { graphNodes, graphLinks, GraphNode, GraphLink } from '../models/graphData';

const Graph: React.FC = () => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const svgElement = svgRef.current;
    if (!svgElement) return;

    const width = 960;
    const height = 500;

    const svg = d3.select(svgElement)
      .attr("width", width)
      .attr("height", height);

    const simulation = d3.forceSimulation<GraphNode>(graphNodes)
      .force("link", d3.forceLink<GraphNode, GraphLink>().id(d => d.id).links(graphLinks).distance(100))
      .force("charge", d3.forceManyBody().strength(-400))
      .force("center", d3.forceCenter(width / 2, height / 2));

    const link = svg.append("g")
      .attr("class", "links")
      .selectAll("line")
      .data(graphLinks)
      .enter().append("line")
      .attr("strokeWidth", 2)
      .attr("stroke", "#999");

    const node = svg.append("g")
      .attr("class", "nodes")
      .selectAll("g")
      .data(graphNodes)
      .enter().append("g")
      .attr("class", "node");

    node.append("circle")
      .attr("r", 10)
      .attr("fill", d => d.fill);

    node.append("text")
      .attr("x", 15)
      .attr("dy", ".35em")
      .text(d => d.name);

    simulation.on("tick", () => {
      link
        .attr("x1", d => (d.source as GraphNode).x!)
        .attr("y1", d => (d.source as GraphNode).y!)
        .attr("x2", d => (d.target as GraphNode).x!)
        .attr("y2", d => (d.target as GraphNode).y!);

      node
        .attr("transform", d => `translate(${d.x},${d.y})`);
    });

    return () => {
      simulation.stop();
    };
  }, []);

  return (
    <svg ref={svgRef}></svg>
  );
}

export default Graph;
