import React, { useState, useMemo } from 'react';
import usePrioLinesData from '../hooks/usePrioLinesData';
import { SingleRow } from '../../models/FamilyTreeInterfaces';
import PriorityLineModal from './PriorityLineModal';
import { LineData } from '../../models/PowerDashboardInterfaces';

interface PrioLinesSVGProps {
  data: SingleRow[];
}

const PrioLinesSVG: React.FC<PrioLinesSVGProps> = ({ data }) => {
  const linesToDraw = usePrioLinesData(data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLineData, setSelectedLineData] = useState<LineData | null>(null);
  const openModal = (lineData: LineData) => {
    setSelectedLineData(lineData);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);
  const arrowheadMarkerPrio = useMemo(() => (
    <marker
      id="arrowhead"
      markerWidth="10"
      markerHeight="10"
      refX="-5"
      refY="3.5"
      orient="auto"
      markerUnits="strokeWidth"
    >
      <polygon points="0 0, 10 3.5, 0 7" fill="magenta" />
    </marker>
  ), []);

  return (
    <>
      <svg style={{ position: 'relative', top: -5, left: 0, overflow: 'visible' }}>
        {arrowheadMarkerPrio} {/* Add the arrowhead marker to the SVG */}
        {linesToDraw.map((line, index) => {
          const lineData: LineData = {
            fromItem: {
              reference: line.fromItem.fullReference,
              fullReference: line.fromItem.reference,
              kind: line.fromItem.kind,
              type: line.fromItem.type,
              id: line.fromItem.id,
              country: line.fromItem.country,
              isQuery: line.fromItem.isQuery,
              isRepresentative: line.fromItem.isRepresentative,
              date: line.fromItem.date,
              originalReference: line.fromItem.originalReference,
              plainLanguageType: line.fromItem.plainLanguageType,
              countryName: line.fromItem.countryName
            },
            toItem: {
              reference: line.toItem.fullReference,
              fullReference: line.toItem.reference,
              kind: line.toItem.kind,
              type: line.toItem.type,
              id: line.toItem.id,
              country: line.toItem.country,
              isQuery: line.toItem.isQuery,
              isRepresentative: line.toItem.isRepresentative,
              date: line.toItem.date,
              originalReference: line.toItem.originalReference,
              plainLanguageType: line.toItem.plainLanguageType,
              countryName: line.toItem.countryName
            }
          };

          const midX = (line.from.x + line.to.x) / 3;
          const controlPointX1 = (midX + line.from.x) / 3;
          const controlPointX2 = (midX + line.to.x) / 3;

          const textX = line.from.x - 80;
          const textY = line.from.y + 12;
          const pathD = `M ${line.from.x} ${line.from.y} C ${controlPointX1} ${line.from.y}, ${controlPointX2} ${line.to.y}, ${line.to.x} ${line.to.y}`;

          return (
            <g key={index}
              className='opacity-30 hover:opacity-100 transition duration-300 ease-in-out cursor-pointer pointer-events-auto'

            >
              <path
                onClick={() => openModal(lineData)}
                d={pathD}
                stroke="transparent"
                strokeWidth="20"
                fill="none"
                opacity="50"
              />
              <path
                onClick={() => openModal(lineData)}
                d={pathD}
                stroke="magenta"
                strokeWidth="2"
                fill="none"
                markerStart="url(#arrowhead)"
              />
              <text x={textX} y={textY} textAnchor="middle" fill="magenta" fontSize="12"
              >
                claims priority of
              </text>
            </g>
          );
        })}
      </svg>
      <PriorityLineModal
        isOpen={isModalOpen}
        onClose={closeModal}
        lineData={selectedLineData || undefined}
      />
    </>
  );
};

export default PrioLinesSVG;