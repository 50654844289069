//src/blog/pages/BlogPost.tsx

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import config from '../../config/config';
import { Tooltip } from 'flowbite-react';
import { Link } from 'react-router-dom';
import RelatedBlogPosts from '../components/RelatedBlogPosts';
import SideBar from '../components/SideBar';
import CommentSection from '../components/CommentSection';
import useFetchBlogPost from '../../hooks/useFetchBlogPost';
import LoadingHeader from '../components/LoadingHeader';
import './../../blogHeroImageStyle.css';
import useDynamicBackgroundImage from '../../hooks/useDynamicBackgroundImage';
import { Helmet } from 'react-helmet';
import { RoutePaths } from '../../routes/RoutePaths';

const BlogPost: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { blogPost, isLoading, error } = useFetchBlogPost(slug);
  const { CLIENT_BASE_URL, CLIENT_BLOG_URL } = config;
  useDynamicBackgroundImage(blogPost?.featuredMediaUrl);

  const [commentCount, setCommentCount] = useState(0);

  const handleCommentCountUpdate = (newCount: number) => {
    setCommentCount(newCount);
  };

  if (isLoading || error || !blogPost) {
    return <LoadingHeader message={isLoading ? "Loading content..." : error || "No post found"} />;
  }


  return (
    <div>
      <Helmet>
        <title>{blogPost.title}</title>
        <meta name="description" content={blogPost.metaDescription || blogPost.excerpt} />
        <meta property="og:title" content={blogPost.title} />
        <meta property="og:description" content={blogPost.metaDescription || blogPost.excerpt} />
        <meta property="og:image" content={blogPost.featuredMediaUrl} />
      </Helmet>
      <main className="pb-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <header className="headerBackground dynamicBackgroundImage" aria-labelledby={blogPost.altText}>
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50">
          </div>
          <div className="absolute top-20 left-1/2 px-4 mx-auto w-full max-w-screen-xl -translate-x-1/2 xl:top-1/2 xl:-translate-y-1/2 xl:px-0">
            <span className="block mb-4 text-gray-300">Published in<span> </span>
              {blogPost.categoryNames.map((categoryName, index) => (
                <Link key={index} to={RoutePaths.ListOfSameCategoryBlogPosts.replace(':categoryName', categoryName)}>
                  <span className="ml-2 bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                    <svg className="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z">
                      </path>
                    </svg>
                    {categoryName}
                  </span>
                </Link>
              ))}
            </span>
            <h1 className="mb-4 max-w-4xl text-2xl font-extrabold leading-none text-white sm:text-3xl lg:text-4xl">
              {blogPost.title}
            </h1>
            <p className="text-lg font-normal text-gray-300">
              <span className='bg-primary-700 dark:bg-primary-600 shadow-lg'>
              {blogPost.excerpt}
              </span>
            </p>
          </div>
        </header>
        <div className="flex relative my-shadow-sharp z-10 justify-between p-6 sm:-m-36 sm:mx-4 max-w-screen-xl bg-white dark:bg-gray-800 rounded xl:-m-32 xl:p-9 xl:mx-auto">
          <article className="xl:w-[828px] w-full max-w-none format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
            <div className="flex flex-col lg:flex-row justify-between lg:items-center">
              <div className="flex items-center space-x-3 text-gray-900 dark:text-gray-400 font-medium customp mb-2 lg:mb-0">
                {/* <div className="flex justify-center">
                  <img src={blogPost.authorImageUrl} alt={blogPost.authorName} className="h-20 w-20 rounded-full mx-auto mb-4" />
                </div> */}
                {/* <a href={blogPost.authorSocialMediaUrl} target="_blank" title={`Social media link to ${blogPost.authorName}`} rel="noopener noreferrer" className="cursor-pointer underline text-gray-900 dark:text-white hover:underline no-underline font-semibold">
                  {blogPost.authorName}
                </a>
                <span className="bg-green-500 dark:bg-green-400 w-2 h-2 rounded-full shadow-green-500/50">
                </span> */}
                <span>Team Patalytica</span>
                <div className="hidden md:block flex justify-start">
                  <span><time className="hidden lg:block font-normal text-gray-900 dark:text-gray-400">Updated: {new Date(blogPost.modified).toLocaleDateString()} AM UTC</time></span>
                </div>
              </div>
              <div className="block md:hidden mb-2">
                <span><time className="font-normal text-gray-900 dark:text-gray-400 font-semibold">Updated: {new Date(blogPost.modified).toLocaleDateString()} AM UTC</time></span>
              </div>
              <aside aria-label="ShareOnSocialMedia">
                <div className="flex justify-start py-2">
                  <Tooltip content="Share on Facebook" placement="top">
                    <Link
                      target="_blank"
                      to={
                        "https://www.facebook.com/sharer/sharer.php?u="
                        + encodeURIComponent(CLIENT_BASE_URL)
                        + CLIENT_BLOG_URL
                        + encodeURIComponent(blogPost.slug)
                        + "&title="
                        + encodeURIComponent(blogPost.title)
                      }
                      className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                      aria-label="Share on facebook"
                    >
                      <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                        <path fillRule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clipRule="evenodd" />
                      </svg>
                    </Link>
                  </Tooltip>
                  <Tooltip content="Share on Twitter" placement="top">
                    <Link
                      target="_blank"
                      to={
                        "https://twitter.com/intent/tweet?text="
                        + encodeURIComponent(blogPost.title)
                        + "&url="
                        + encodeURIComponent(CLIENT_BASE_URL + CLIENT_BLOG_URL + blogPost.slug)
                      }
                      className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                      aria-label="Share on Twitter"
                    >
                      <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path fill="currentColor" d="M12.186 8.672 18.743.947h-2.927l-5.005 5.9-4.44-5.9H0l7.434 9.876-6.986 8.230h2.927l5.434-6.4 4.82 6.4H20L12.186 8.672Zm-2.267 2.671L8.544 9.515 3.2 2.42h2.2l4.312 5.719 1.375 1.828 5.731 7.613h-2.2l-4.699-6.237Z" />
                      </svg>
                    </Link>
                  </Tooltip>
                  <Tooltip content="Share on LinkedIn" placement="top">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={
                        "https://www.linkedin.com/shareArticle?mini=true&url="
                        + encodeURIComponent(CLIENT_BASE_URL)
                        + encodeURIComponent(CLIENT_BLOG_URL)
                        + encodeURIComponent(blogPost.slug)
                        + "&title="
                        + encodeURIComponent(blogPost.title)
                        + "&summary="
                        + encodeURIComponent(blogPost.excerpt)
                      }
                      className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                      aria-label="Share on LinkedIn"
                    >
                      <svg className="w-5 h-5 text-gray-900 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z"></path>
                      </svg>
                    </Link>
                  </Tooltip>
                </div>
              </aside>
            </div>
            <div dangerouslySetInnerHTML={{
              __html: blogPost.content
            }}>
            </div>
            <hr className="my-6 border-gray-200 dark:border-gray-700" />
            <section className="not-format">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">
                  Discussion ({commentCount})
                </h2>
              </div>
              {blogPost && <CommentSection postId={blogPost.id} onCommentCountUpdate={handleCommentCountUpdate} />}
            </section>
          </article>
          <SideBar />
        </div>
      </main>
      <div className="py-16"></div>
      <RelatedBlogPosts categoryNames={blogPost.categoryNames} />
    </div>
  );

}

export default BlogPost;