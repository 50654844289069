
import { Spinner } from 'flowbite-react';

interface DisplayLoaderProps {
  applicationNr: string;
}

const DisplayLoader: React.FC<DisplayLoaderProps> = ({ applicationNr }) => {
  return (
    <div>
      <div className="flex justify-center">
        <div className="mt-60 max-w-xl">
          <Spinner size='xl' color='pink' />
        </div>
      </div>
      <div className="flex justify-center">
        <div className="max-w-xl mb-60 text-center">
          <h2 className="mt-10 mb-8 tracking-tight font-semibold text-gray-900 dark:text-white text-2xl md:text-4xl lg:text-5xl">
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-orange-400 from-pink-700">
              Fetching data for {applicationNr}...
            </span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default DisplayLoader;