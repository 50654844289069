// src/services/metaService.ts
import config from '../config/config';
import { axiosBase} from './axiosConfig';

const { SERVER_BASE_URL, SERVER_API_META } = config;

export interface VersionInfoDTO {
    version: string;
    assemblyVersion: string;
    fileVersion: string;
}

const getServerVersion = async (): Promise<VersionInfoDTO> => {
    try {
        const response = await axiosBase.get<VersionInfoDTO>(`${SERVER_BASE_URL}${SERVER_API_META}version`);
        return response.data;
    } catch (error) {
        console.error('Error fetching server version', error);
        throw error;
    }
};

export default {
    getServerVersion
};