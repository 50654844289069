import React from "react";

interface PriorityProps {
  index: number;
  totalPriorityClaims: number;
  priorityCountry: string;
  priorityDocNr: string;
  priorityDateFormatted?: string;
  referenceChangeGazetteNumber?: string;
  priorityKind?: string;
}

const Priorities: React.FC<PriorityProps> = ({
  index,
  totalPriorityClaims,
  priorityKind,
  priorityCountry,
  priorityDocNr,
  priorityDateFormatted,
  referenceChangeGazetteNumber
}) => {

  let flagSrc = '';
  let flagTitle = priorityCountry; // Set the title to the reference country

  if (priorityCountry === "EP") {
    flagSrc = "https://plevanltd.com/images/light/EPO.webp"; // EP flag
  } else if (priorityCountry === "WO") {
    flagSrc = "https://plevanltd.com/images/light/uno-wipo.webp"; // WO flag
  } else {
    flagSrc = `https://flagcdn.com/w40/${priorityCountry.toLowerCase()}.webp`; // Other country flag
  }

  return (
    <div>
      <div className="flex justify-center items-center relative mx-auto max-w-screen-sm">
        <p className="font-normal text-gray-900 dark:text-white text-xs">
          Change date: {priorityDateFormatted}
        </p>
      </div>
      <div className="mb-8 flex justify-center items-center relative mx-auto max-w-screen-sm">
        <p className="font-normal text-gray-900 dark:text-white text-xs">
          Gazette: {referenceChangeGazetteNumber}
        </p>
      </div>
      <div className="relative mx-auto max-w-screen-sm overflow-x-auto rounded shadow-md shadow-gray-500/50 dark:shadow-none mb-8">
        <table className="w-full text-xs lg:text-base text-left ">
          <thead className="text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-white">
            <tr>
              <th scope="col" className="px-2 sm:px-6 py-3 w-1/3">
                Priority claim {index}/{totalPriorityClaims}
              </th>
              <th scope="col" className="pr-2 sm:pr-6 py-3 w-1/3">
                <div className="flex items-center justify-start">
                  <img id="flagDisplay" className="h-4" src={flagSrc} alt={flagTitle} title={flagTitle} />
                </div>
              </th>
              <th scope="col" className="py-3 w-1/3 pr-2 sm:pr-6">
                <div className="flex justify-start items-center">
                  {priorityCountry}{priorityDocNr}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white">
                <p className="text-gray-700 dark:text-white font-normal">
                  Priority kind:
                </p>
              </th>
              <td className="pr-2 sm:pr-6">
                <div className="flex justify-start font-normal dark:text-white">
                  <p className="flex items-center space-x-3">
                    {priorityKind}
                  </p>
                </div>
              </td>
              <td className="pr-2 sm:pr-6">
                <div className="flex justify-start items-center">
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white">
                <p className="text-gray-700 dark:text-white font-normal">
                  Priority country:
                </p>
              </th>
              <td className="pr-2 sm:pr-6">
                <div className="flex justify-start font-normal dark:text-white">
                  <p className="flex items-center space-x-3">
                    {priorityCountry}
                  </p>
                </div>
              </td>
              <td className="pr-2 sm:pr-6">
                <div className="flex justify-start items-center">
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white">
                <p className="text-gray-700 dark:text-white font-normal">
                  Priority number:
                </p>
              </th>
              <td className="pr-2 sm:pr-6">
                <div className="flex justify-start font-normal dark:text-white">
                  <p className="flex items-center space-x-3">
                    {priorityDocNr}
                  </p>
                </div>
              </td>
              <td className="pr-2 sm:pr-6">
                <div className="flex justify-start items-center">
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white">
                <p className="text-gray-700 dark:text-white font-normal">
                  Priority date:
                </p>
              </th>
              <td className="pr-2 sm:pr-6">
                <div className="flex justify-start font-normal dark:text-white">
                  <p className="flex items-center space-x-3">
                    {priorityDateFormatted}
                  </p>
                </div>
              </td>
              <td className="pr-2 sm:pr-6">
                <div className="flex justify-start items-center">
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div >
  );
};
export default Priorities;