//src/business/pages/components/GeneralInfo.tsx
import { EpRegisterDTO } from '../../models/EpRegisterInterfaces';
// import useImageBase64 from '../../hooks/useImageBase64';
import AboutFile from './../components/AboutFile';
import Titles from './../components/Titles';
import ApplicationReference from './../components/ApplicationReference';
import PublicationReference from './../components/PublicationReference';
import Classification from './../components/Classification';
import Priorities from './../components/Priorities';
import Applicant from './../components/Applicant';
import Inventor from './../components/Inventor';
import Representative from './../components/Representative';
import RenewalFee from './../components/RenewalFee';
import { convertImageToBase64 } from '../../utils/imageUtils';


interface GeneralInfoProps {
  data: EpRegisterDTO;
  base64Image: string;
  firstApplicationReferenceCountry: string;
  firstApplicationReferenceDocNumber: string;
  patentStatus: string;
  latestPatentStatusChangeDate: string;
  languageOfFiling: string;
  inventorChangeDate: string;
  inventorChangeGazetteNumber: string;
  applicantChangeDate: string;
  applicantChangeGazetteNumber: string;
  representativeChangeDate: string;
  representativeChangeGazetteNumber: string;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({
  data,
  firstApplicationReferenceCountry }) => {
  if (!data) {
    return <p>No data found</p>;
  }
  // Call the utility function directly
  const base64Image = data.imageData ? convertImageToBase64(data.imageData) : '';

  const firstApplicationReferenceDocNumber = data.applicationReferences?.[0]?.documentId?.docNumber || 'No document number available';
  const patentStatus = data.patentStatus || 'No status available';
  const latestPatentStatusChangeDate = data.latestPatentStatusChangeDate || 'No date available';
  const languageOfFiling = data.languageOfFiling || 'No filing language information available';
  const inventorChangeDate = data.inventorsDTO?.formattedDate || 'No change date available';
  const inventorChangeGazetteNumber = data.inventorsDTO?.changeGazetteNum || 'No change gazette number available';
  const applicantChangeDate = data.applicantsDTO?.formattedDate || 'No change date available';
  const applicantChangeGazetteNumber = data.applicantsDTO?.changeGazetteNum || 'No change gazette number available';
  const representativeChangeDate = data.agentsDTO?.formattedDate || 'No change date available';
  const representativeChangeGazetteNumber = data.agentsDTO?.changeGazetteNum || 'No change gazette number available';
  return (
    <div>
      <section className="pt-8 pt-16 lg:headergap lg:pb-24 bg-white dark:bg-neutral-900">
        <div className="py-16 px-4 flex justify-center">
          <span className="flex items-center">
            <span className="ml-3 text-xl md:text-3xl lg:text-4xl tracking-tight font-semibold text-gray-900 dark:text-gray-400">EP Register info for {firstApplicationReferenceCountry}{firstApplicationReferenceDocNumber}</span>
          </span>
        </div>
        <div id="image" className="flex justify-center pb-16">
          <div className="relative mx-auto max-w-screen-sm overflow-hidden rounded shadow-md shadow-gray-500/50 dark:shadow-none">
            {data.imageData && <img src={base64Image} alt="Ep Register" className="block max-w-full h-auto align-middle" />}
          </div>
        </div>
        <AboutFile
          firstApplicationReferenceCountry={firstApplicationReferenceCountry}
          firstApplicationReferenceDocNumber={firstApplicationReferenceDocNumber}
          patentStatus={patentStatus}
          latestPatentStatusChangeDate={latestPatentStatusChangeDate}
          languageOfFiling={languageOfFiling.toUpperCase()}
        />
        <div id="titles">
          <div className="flex justify-center items-center relative mx-auto max-w-screen-sm">
            <h2 className="my-8 font-semibold text-gray-900 dark:text-white text-lg md:text-2xl lg:text-3xl">
              Titles of the invention
            </h2>
          </div>
          {data.inventionTitles && data.inventionTitles.map((title, index) => (
            <Titles
              key={index}
              titleLanguage={title.lang || 'No title language available'}
              titleText={title.text || 'No title text available'}
              titleChangeDate={title.changeDate || 'No title change date available'}
              changeGazetteNumber={title.changeGazetteNum || 'No change gazette number available'}
            />
          ))}
        </div>
        <div id="applications">
          <div className="flex justify-center items-center relative mx-auto max-w-screen-sm">
            <h2 className="my-8 font-semibold text-gray-900 dark:text-white text-lg md:text-2xl lg:text-3xl">
              Applications
            </h2>
          </div>
          {data.applicationReferences && data.applicationReferences.map((reference, index) => (
            <ApplicationReference
              key={index}
              referenceCountry={reference.documentId?.country || 'No country available'}
              referenceDoc={reference.documentId?.docNumber || 'No document number available'}
              referenceDate={reference.documentId?.formattedDate}
              referenceChangeGazetteNumber={reference.changeGazetteNum}
            />
          ))}
        </div>
        <div id="publications">
          <div className="flex justify-center items-center relative mx-auto max-w-screen-sm">
            <h2 className="my-8 font-semibold text-gray-900 dark:text-white text-lg md:text-2xl lg:text-3xl">
              Publications
            </h2>
          </div>
          {data.publicationReferences && data.publicationReferences.map((reference, index) => (
            <PublicationReference
              key={index}
              referenceCountry={reference.documentId?.country || 'No country available'}
              referenceDoc={reference.documentId?.docNumber || 'No document number available'}
              referenceDateFormatted={reference.documentId?.formattedDate}
              referenceDate={reference.documentId?.date}
              referenceKind={reference.documentId?.kind || 'No kind available'}
              referenceChangeGazetteNumber={reference.changeGazetteNum}
              referenceExplanation={reference.kindExplanation || 'No explanation available'}
              referenceLanguage={reference.documentId?.language || 'No language available'}
            />
          ))}
        </div>
        <div id="classifications">
          <div className="flex justify-center items-center relative mx-auto max-w-screen-sm">
            <h2 className="my-8 font-semibold text-gray-900 dark:text-white text-lg md:text-2xl lg:text-3xl">
              Classification
            </h2>
          </div>
          {data.ipcrCollection.classifications && data.ipcrCollection.classifications.map((classification, index) =>
            <Classification
              key={index}
              classificationDateFormatted={data.ipcrCollection.formattedDate || 'No date available'}
              classificationChangeGazetteNumber={data.ipcrCollection.changeGazetteNum || 'No change gazette number available'}
              classificationText={classification.classificationText || 'No classification text available'}
            />
          )}
        </div>
        <div id="priorities">
          <div className="flex justify-center items-center relative mx-auto max-w-screen-sm">
            <h2 className="my-8 font-semibold text-gray-900 dark:text-white text-lg md:text-2xl lg:text-3xl">
              Priority claims
            </h2>
          </div>
          {data.priorityClaimsCollection.priorityClaims && data.priorityClaimsCollection.priorityClaims.map((priorityclaim, index) => (
            <Priorities
              key={index}
              index={index + 1}
              priorityCountry={priorityclaim.country || 'No country available'}
              priorityKind={priorityclaim.kind || 'No kind available'}
              totalPriorityClaims={data.priorityClaimsCollection.priorityClaims?.length || 0}
              priorityDocNr={priorityclaim.docNumber || 'No document number available'}
              priorityDateFormatted={priorityclaim.formattedDate}
              referenceChangeGazetteNumber={data.priorityClaimsCollection.changeGazetteNum || 'No change gazette number available'}
            />
          ))}
        </div>
        <div id="applicants">
          <div className="flex justify-center items-center relative mx-auto max-w-screen-sm">
            <h2 className="my-8 font-semibold text-gray-900 dark:text-white text-lg md:text-2xl lg:text-3xl">
              Applicants
            </h2>
          </div>
          <div>
            <div className="flex justify-center items-center relative mx-auto max-w-screen-sm">
              <p className="font-normal text-gray-900 dark:text-white text-xs">
                Change date: {applicantChangeDate}
              </p>
            </div>
            <div className="mb-8 flex justify-center items-center relative mx-auto max-w-screen-sm">
              <p className="font-normal text-gray-900 dark:text-white text-xs">
                Gazette: {applicantChangeGazetteNumber}
              </p>
            </div>
          </div>
          {data.applicants && data.applicants.map((applicants, index) => (
            <Applicant
              key={index}
              index={index + 1}
              totalApplicants={data.applicants?.length || 0}
              applicantCountry={applicants.addressBook?.address?.country || 'No country available'}
              applicantName={applicants.addressBook?.name || 'No name available'}
              applicantDesignatedStates={applicants.designation || 'No document number available'}
              applicantAddress1={applicants.addressBook?.address?.address1 || 'No address available'}
              applicantAddress2={applicants.addressBook?.address?.address2}
              applicantAddress3={applicants.addressBook?.address?.address3}
              applicantAddress4={applicants.addressBook?.address?.address4}
              applicantAddress5={applicants.addressBook?.address?.address5}
            />
          ))}
        </div>
        <div id="inventors">
          <div className="flex justify-center items-center relative mx-auto max-w-screen-sm">
            <h2 className="my-8 font-semibold text-gray-900 dark:text-white text-lg md:text-2xl lg:text-3xl">
              Inventors
            </h2>
          </div>
          <div>
            <div className="flex justify-center items-center relative mx-auto max-w-screen-sm">
              <p className="font-normal text-gray-900 dark:text-white text-xs">
                Change date: {inventorChangeDate}
              </p>
            </div>
            <div className="mb-8 flex justify-center items-center relative mx-auto max-w-screen-sm">
              <p className="font-normal text-gray-900 dark:text-white text-xs">
                Gazette: {inventorChangeGazetteNumber}
              </p>
            </div>
          </div>
          {data.inventors && data.inventors.map((inventors, index) => (
            <Inventor
              key={index}
              index={index + 1}
              totalInventors={data.inventors?.length || 0}
              inventorCountry={inventors.addressBook?.address?.country || 'No country available'}
              inventorName={inventors.addressBook?.name || 'No name available'}
              inventorAddress1={inventors.addressBook?.address?.address1 || 'No address available'}
              inventorAddress2={inventors.addressBook?.address?.address2}
              inventorAddress3={inventors.addressBook?.address?.address3}
              inventorAddress4={inventors.addressBook?.address?.address4}
              inventorAddress5={inventors.addressBook?.address?.address5}
            />
          ))}

        </div>
        <div id="representatives">
          <div className="flex justify-center items-center relative mx-auto max-w-screen-sm">
            <h2 className="my-8 font-semibold text-gray-900 dark:text-white text-lg md:text-2xl lg:text-3xl">
              Representatives
            </h2>
          </div>
          <div>
            <div className="flex justify-center items-center relative mx-auto max-w-screen-sm">
              <p className="font-normal text-gray-900 dark:text-white text-xs">
                Change date: {representativeChangeDate}
              </p>
            </div>
            <div className="mb-8 flex justify-center items-center relative mx-auto max-w-screen-sm">
              <p className="font-normal text-gray-900 dark:text-white text-xs">
                Gazette: {representativeChangeGazetteNumber}
              </p>
            </div>
          </div>
          {data.agents && data.agents.map((representatives, index) => (
            <Representative
              key={index}
              index={index + 1}
              totalRepresentatives={data.agents?.length || 0}
              representativeCountry={representatives.addressBook?.address?.country || 'No country available'}
              representativeName={representatives.addressBook?.name || 'No name available'}
              representativeAddress1={representatives.addressBook?.address?.address1 || 'No address available'}
              representativeAddress2={representatives.addressBook?.address?.address2}
              representativeAddress3={representatives.addressBook?.address?.address3}
              representativeAddress4={representatives.addressBook?.address?.address4}
              representativeAddress5={representatives.addressBook?.address?.address5}
            />
          ))}
        </div>
        <div id="renewalfees">
          <div className="flex justify-center items-center">
            <h2 className="mt-8 mb-4 font-semibold text-gray-900 dark:text-white text-lg md:text-2xl lg:text-3xl">
              Paid renewal fees
            </h2>
          </div>
          <div className="mb-8 flex justify-center items-center relative mx-auto max-w-screen-sm">
            <p className="font-normal text-gray-900 dark:text-white text-xs">
              Patent year 3 fee includes patent years 1 and 2
            </p>
          </div>
          <div className="relative mx-auto max-w-screen-sm overflow-x-auto rounded shadow-md shadow-gray-500/50 dark:shadow-none mb-8">
            <table className="w-full text-xs lg:text-base text-left ">
              <thead className="text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-white">
                <tr>
                  <th scope="col" className="px-2 sm:px-6 py-3 w-1/3">
                    Patent Year
                  </th>
                  <th scope="col" className="w-1/3">
                    Description
                  </th>
                  <th scope="col" className="w-1/3">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.epRegisterRenewalFees && data.epRegisterRenewalFees.map((renewalFee, index) => (
                  <RenewalFee
                    key={index}
                    renewalYear={renewalFee?.year || 'No year available'}
                    renewalFeeDescription={renewalFee?.description || 'No description available'}
                    paymentDate={renewalFee?.formattedDate || 'No payment date available'}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section >
    </div>
  );
};

export default GeneralInfo;