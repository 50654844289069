import { useMemo } from 'react';
import { SingleRow } from '../../models/FamilyTreeInterfaces';
import { Line } from '../../models/PowerDashboardInterfaces';
import { usePositions } from '../contexts/PositionsContext';

const useOptimalPrioLinesData = (data: SingleRow[]): Line[] => {
  const { positions } = usePositions(); // Get positions from context

  return useMemo(() => {
    const lines: Line[] = []; //lines represent connections between items in the dataset. Each connection is determined by either rootRef or prioRef references

  // Function to find an item by its reference in the data array
    const findItemByReference = (reference: string) => {
      for (const singleRow of data) {
        for (const item of singleRow.row) {
          if (item.reference === reference) {
            return item;
          }
        }
      }
      return null;
    };

      data.forEach(singleRow => {
      singleRow.row.forEach(item => {
        // Check if the item is representative
        const isRepresentative = item.isRepresentative === "YES";

        // Process prioRefs if the item is representative or there are no rootRefs
        if (isRepresentative || !item.rootRefs) {
          item.prioRefs?.forEach(prioRef => {
            
              const referencedItem = findItemByReference(prioRef.reference);
            if (referencedItem && positions[item.reference] && positions[referencedItem.reference]) {
                const fromItemDate = new Date(item.date); // Ensure item.date is a Date object
                const toItemDate = new Date(referencedItem.date); // Ensure referencedItem.date is a Date object
                const timeSpan = Math.abs(fromItemDate.getTime() - toItemDate.getTime());

                const line: Line = {
                  from: positions[item.reference],
                  to: positions[referencedItem.reference],
                  fromItem: item,
                  toItem: referencedItem,
                  timeSpan: timeSpan
                };
                lines.push(line);
              }
            }
          );
        }
   // Process rootRefs regardless of isRepresentative
    item.rootRefs?.forEach(rootRef => {
      const referencedItem = findItemByReference(rootRef.reference);
      if (referencedItem && positions[item.reference] && positions[referencedItem.reference]) {
        const fromItemDate = new Date(item.date); 
            const toItemDate = new Date(referencedItem.date); 
            const timeSpan = Math.abs(fromItemDate.getTime() - toItemDate.getTime());
        const line: Line = {
            from: positions[item.reference],
            to: positions[referencedItem.reference],
            fromItem: item,
            toItem: referencedItem,
            timeSpan: timeSpan
          };
        lines.push(line);
      }
    });   
      }
   );  
 });
    // Create a graph representation
    const graph = new Map();
    lines.forEach(line => {
      console.log(line);
      if (!graph.has(line.fromItem.id)) {
        graph.set(line.fromItem.id, new Set());
      }
      graph.get(line.fromItem.id).add(line.toItem.id);

      if (!graph.has(line.toItem.id)) {
        graph.set(line.toItem.id, new Set());
      }
      graph.get(line.toItem.id).add(line.fromItem.id);
    });


    // Find triangles in the graph
    const triangles = findTriangles(graph);

// Process each triangle
const linesToRemove = new Set();
triangles.forEach(triangle => {
  const triangleLines = findLinesForTriangle(triangle, lines);

const longestLine = triangleLines.reduce((a, b) => 
    (a.timeSpan || 0) > (b.timeSpan || 0) ? a : b
  );

  // Check if removing the longest line leaves an item without connections
  if (canRemoveLine(longestLine, lines)) {
    linesToRemove.add(longestLine);
  }
});
// Remove lines that are part of a triangle
linesToRemove.forEach(lineToRemove => {
  const index = lines.findIndex(line => line === lineToRemove);
  if (index > -1) {
    lines.splice(index, 1);
  }
});
    // Remove lines that are part of a triangle
    linesToRemove.forEach(lineToRemove => {
      const index = lines.findIndex(line => line === lineToRemove);
      if (index > -1) {
        lines.splice(index, 1);
      }
    });

    return lines;
  }, [data, positions]);
  };
  
function findTriangles(graph: Map<string, Set<string>>): string[][] {
  const triangles = new Set<string>();
  graph.forEach((neighbors, nodeId) => {
    neighbors.forEach(neighborId => {
      graph.get(neighborId)?.forEach(secondNeighborId => {
        if (secondNeighborId !== nodeId && graph.get(secondNeighborId)?.has(nodeId)) {
          const triangle = [nodeId, neighborId, secondNeighborId].sort();
          const triangleStr = triangle.join("-");
          triangles.add(triangleStr);
        }
      });
    });
  });

  // Convert the string representation back to arrays of item IDs
  return Array.from(triangles).map(triangleStr => triangleStr.split("-"));
}

function findLinesForTriangle(triangle: string[], lines: Line[]): Line[] {
  return triangle.flatMap(id => 
    lines.filter(line => line.fromItem.id === id || line.toItem.id === id)
  ).filter((value, index, self) => self.indexOf(value) === index);
}

function canRemoveLine(lineToRemove: Line, lines: Line[]): boolean {
  const fromItemId = lineToRemove.fromItem.id;
  const toItemId = lineToRemove.toItem.id;

  const fromItemLines = lines.filter(line => line.fromItem.id === fromItemId || line.toItem.id === fromItemId);
  const toItemLines = lines.filter(line => line.fromItem.id === toItemId || line.toItem.id === toItemId);

  // Check if removing the line leaves any item with no other connections
  return fromItemLines.length > 1 && toItemLines.length > 1;
}

export default useOptimalPrioLinesData;
