import { useMemo } from 'react';

const useGetPositionFromDate = (fixedMarkerDistance: number) => {
  return useMemo(() => {
    return (dateString: Date, startDate: Date): number => {
      const date = new Date(dateString);
      const start = new Date(startDate);
      const timeDifference = date.getTime() - start.getTime();
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      const leftPosition = daysDifference * fixedMarkerDistance / 365;
      return leftPosition;
    };
  }, [fixedMarkerDistance]);
};

export default useGetPositionFromDate;