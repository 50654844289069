//src/business/pages/PublicDashboard.tsx
import { useParams } from 'react-router-dom';
import useDashboard from './hooks/useDashboard';
import Dashboard from './components/Dashboard';

const PublicDashboard = () => {
  const { applicationNumber } = useParams<{ applicationNumber: string }>();
  const dashboardData = useDashboard(applicationNumber || '');
  return <Dashboard {...(dashboardData)} />;
}

export default PublicDashboard;