import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Position } from '../../models/PowerDashboardInterfaces';

interface PositionsContextData {
  positions: { [reference: string]: Position };
  handlePositionChange: (reference: string, position: Position) => void;
}

const defaultContextData: PositionsContextData = {
  positions: {},
  handlePositionChange: () => { } // No-op function as default
};

const PositionsContext = createContext<PositionsContextData>(defaultContextData);

export const usePositions = () => {
  const context = useContext(PositionsContext);
  if (!context) {
    throw new Error('usePositions must be used within a PositionsProvider');
  }
  return context;
}

interface PositionsProviderProps {
  children: ReactNode;
}

export const PositionsProvider: React.FC<PositionsProviderProps> = ({ children }) => {
  const [positions, setPositions] = useState<{ [reference: string]: Position }>({});

  const handlePositionChange = (reference: string, position: Position) => {
    setPositions(prevPositions => ({
      ...prevPositions,
      [reference]: position
    }));
  };

  return (
    <PositionsContext.Provider value={{ positions, handlePositionChange }}>
      {children}
    </PositionsContext.Provider>
  );
};
