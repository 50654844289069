// src/blog/components/CommentList.tsx

import React, { useState } from 'react';
import { Dropdown } from 'flowbite-react';
import { useAuthContext } from '../../contexts/AuthContext';
import CommentForm from './CommentForm';
import { BlogPostCommentWithRepliesDTO } from '../hooks/useOrganizedComments';

interface CommentListProps {
  comments: BlogPostCommentWithRepliesDTO[];
  postId: number;
  commentId?: number;
  onDeleteComment: (commentId: number) => void;
  onCommentPosted: () => void;
}

const CommentList: React.FC<CommentListProps> = ({ comments, postId, onDeleteComment, onCommentPosted }) => {
  const authContext = useAuthContext();
  if (!authContext) {
    return null;
  }
  const { user } = authContext;

  const [replyToCommentId, setReplyToCommentId] = useState<number | null>(null);

  const renderComments = (comments: BlogPostCommentWithRepliesDTO[]) => {
    return comments.map((comment) => (
      <div key={comment.id}>
        <article className="p-6 pb-6 mb-6 text-base bg-gray-50 rounded-lg dark:bg-gray-700 my-shadow-sharp">
          <footer className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <p className="inline-flex items-center mr-3 font-semibold text-sm text-gray-900 dark:text-white">
                <img
                  className="mr-2 w-6 h-6 rounded-full"
                  src={comment.authorImageUrl}
                  alt={comment.userName}
                />{comment.userName}
              </p>
              <p className="text-sm text-gray-600 dark:text-gray-400"><time dateTime="2022-02-08"
                title="February 8th, 2022">{new Date(comment.date).toLocaleString()}</time></p>
            </div>
            <Dropdown label={<svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
              <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
            </svg>} arrowIcon={false} inline={true}>
              {user && user.userId === comment.userId && (
                <Dropdown.Item onClick={() => onDeleteComment(comment.id)}>
                  <div>Remove</div>
                </Dropdown.Item>
              )}
              <Dropdown.Item>
                <div>Report</div>
              </Dropdown.Item>
            </Dropdown>
          </footer>
          <p className="text-gray-500 dark:text-gray-400 break-words overflow-hidden" dangerouslySetInnerHTML={{ __html: comment.content }}></p>
          <div className="flex items-center mt-4 space-x-4">
            {/* <button title="default" type="button"
              className="flex items-center font-medium text-sm text-gray-500 hover:underline dark:text-gray-400">
              <svg className="mr-1 w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                <path d="M17.947 2.053a5.209 5.209 0 0 0-3.793-1.53A6.414 6.414 0 0 0 10 2.311 6.482 6.482 0 0 0 5.824.5a5.2 5.2 0 0 0-3.8 1.521c-1.915 1.916-2.315 5.392.625 8.333l7 7a.5.5 0 0 0 .708 0l7-7a6.6 6.6 0 0 0 2.123-4.508 5.179 5.179 0 0 0-1.533-3.793Z" />
              </svg>
              11 Likes
            </button> */}
            {user && (
              <button
                title="default"
                type="button"
                onClick={() => setReplyToCommentId(comment.id)} // Set the ID of the comment being replied to
                className="flex items-center font-medium text-sm text-gray-500 hover:underline dark:text-gray-400">
                <svg className="mr-1.5 w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                  <path d="M18 0H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h2v4a1 1 0 0 0 1.707.707L10.414 13H18a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5 4h2a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2ZM5 4h5a1 1 0 1 1 0 2H5a1 1 0 0 1 0-2Zm2 5H5a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Zm9 0h-6a1 1 0 0 1 0-2h6a1 1 0 1 1 0 2Z" />
                </svg>
                Reply
              </button>
            )}
          </div>
        </article>
        {replyToCommentId === comment.id && user && (
          <CommentForm
            postId={postId}
            parentCommentId={comment.id}
            onCommentPosted={() => {
              setReplyToCommentId(null);
              onCommentPosted();
            }}
          />
        )}

        {comment.replies.length > 0 && (
          <div className="ml-8">
            {renderComments(comment.replies)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div>
      {renderComments(comments)}
    </div>
  );
};

export default CommentList;