//src/business/pages/hooks/useLinesData.ts
import { useMemo } from 'react';
import { SingleRow } from '../../models/FamilyTreeInterfaces';
import { Line } from '../../models/PowerDashboardInterfaces';
import { usePositions } from '../contexts/PositionsContext';

const usePrioLinesData = (data: SingleRow[]): Line[] => {
  const { positions } = usePositions(); // Get positions from context

    return useMemo(() => {
    const lines: Line[] = [];

    // Function to find an item by its reference in the data array
    const findItemByReference = (reference: string) => {
      for (const singleRow of data) {
        for (const item of singleRow.row) {
          if (item.reference === reference) {
            return item;
          }
        }
      }
      return null;
    };

    data.forEach(singleRow => {
      singleRow.row.forEach(item => {
          item.prioRefs?.forEach(prioRef => {
            const referencedItem = findItemByReference(prioRef.reference);
            if (
              referencedItem &&
              positions[item.reference] &&
              positions[referencedItem.reference]
            ) {
              const line: Line = {
                from: positions[item.reference],
                to: positions[referencedItem.reference],
                fromItem: item,
                toItem: referencedItem
              };
              lines.push(line);
            }
          });        
      });
    });

    return lines;
  }, [data, positions]);
};

export default usePrioLinesData;