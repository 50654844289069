// src/contexts/VersionInfoContext.tsx
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import metaService from '../services/metaService';
import { VersionInfoDTO } from '../services/metaService';

const VersionInfoContext = createContext<VersionInfoDTO | null>(null);

export const useVersionInfo = () => useContext(VersionInfoContext);
interface VersionInfoProviderProps {
  children: ReactNode; // Define the type for children here
}
export const VersionInfoProvider: React.FC<VersionInfoProviderProps> = ({ children }) => {
  const [versionInfo, setVersionInfo] = useState<VersionInfoDTO | null>(null);

  useEffect(() => {
    const fetchVersionInfo = async () => {
      try {
        const data = await metaService.getServerVersion();
        setVersionInfo(data);
      } catch (error) {
        console.error('Failed to fetch version info', error);
      }
    };

    fetchVersionInfo();
  }, []);

  return (
    <VersionInfoContext.Provider value={versionInfo}>
      {children}
    </VersionInfoContext.Provider>
  );
};