// src/business/hooks/useDashboard.ts
import { useState, useRef } from 'react';
import useGetPositionFromDate from './useGetPositionFromDate';
import useFetchGanttChartData from './useFetchGanttChartData';
import useDateBounds from './useDateBounds';

const useDashboard = (applicationNumber: string) => {
  const initialRowHeight = 90;
  const containerRef = useRef<HTMLDivElement>(null);
  const [rowHeight, setRowHeight] = useState(initialRowHeight);
  const [fixedMarkerDistance, setFixedMarkerDistance] = useState(280);
  const getPositionFromDate = useGetPositionFromDate(fixedMarkerDistance);
  const [showPrioLines, setShowPrioLines] = useState(true);
  const [showRootLines, setShowRootLines] = useState(true);
  const [showOptimalPrioLines, setShowOptimalPrioLines] = useState(false);
  const toggleOptimalPrioLines = () => setShowOptimalPrioLines(prev => !prev);

  const togglePrioLines = () => setShowPrioLines(prev => !prev);
  const toggleRootLines = () => setShowRootLines(prev => !prev);
  const [maxHeight, setMaxHeight] = useState(rowHeight * 10);

  const { data, dependencies, isLoading, metaTitle, metaQuery, totalNumberOfRowItems, totalNumberOfRows, applicants, requestTime, error } = useFetchGanttChartData(applicationNumber);
  const { startDate, endDate } = useDateBounds(data);

  const totalHeight = data.length * rowHeight + rowHeight;
  const timeSpanInYears = endDate ? endDate.getFullYear() - startDate.getFullYear() : 0;
  const totalWidth = (timeSpanInYears + 1) * fixedMarkerDistance;

  const handleHorizontalZoomChange = (newDistance: number) => {
    setFixedMarkerDistance(newDistance);
  };

  const handleVerticalZoomChange = (newRowHeight: number) => {
    setRowHeight(newRowHeight);
  };

  const handleMaxHeightChange = (e: number) => {
    setMaxHeight(e);
  };

  const allDates2 = data.reduce((acc, currentRow) => {
    const rowDates = currentRow.row.map(item => new Date(item.date));
    return acc.concat(rowDates as any);
  }, []);

  let earliestDate = new Date(Math.min(...allDates2));
  let latestDate2 = new Date(Math.max(...allDates2));

  earliestDate.setFullYear(earliestDate.getFullYear() - 1);
  latestDate2.setFullYear(latestDate2.getFullYear() + 2);
  const firstDayofYear = new Date(earliestDate.getFullYear(), 0, 1); // 1st of January

  return {
    containerRef,
    rowHeight,
    setRowHeight,
    fixedMarkerDistance,
    setFixedMarkerDistance,
    getPositionFromDate,
    showPrioLines,
    togglePrioLines,
    showRootLines,
    toggleRootLines,
    showOptimalPrioLines,
    toggleOptimalPrioLines,
    maxHeight,
    setMaxHeight,
    data,
    dependencies,
    isLoading,
    metaTitle,
    metaQuery,
    requestTime,
    totalNumberOfRowItems,
    totalNumberOfRows,
    applicants,
    error,
    startDate,
    endDate,
    totalHeight,
    totalWidth,
    handleHorizontalZoomChange,
    handleVerticalZoomChange,
    handleMaxHeightChange,
    firstDayofYear,
    latestDate2,
    applicationNumber
  };
};

export default useDashboard;
