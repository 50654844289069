// src/services/roleService.ts
import { axiosWithInterceptor } from './axiosConfig';
import config from '../config/config';
import axios from 'axios';

export interface AspRoleDTO {
    id: string;
    name: string;
    description: string;
    createdAt: string;
    lastEditedAt: string;
}

export interface UpdateAspRoleDTO {
    oldRoleName: string;
    newRoleName: string;
    description: string;
}

export interface RoleUserAssignmentDTO {
    roleName: string;
    userName: string;
}

//write an interface createAspRoleDTO with the following properties: name and description
export interface CreateAspRoleDTO {
    name: string;
    description: string;
}

export interface UserRoleAssignmentDTO {    
    userName: string;
    roleNames: string[];
}

export interface DeleteUserByAdminDTO {
    userName: string;
}


const { SERVER_BASE_URL, SERVER_API_ROLES } = config;

class RoleService {
        static async getRoles(): Promise<AspRoleDTO[]> {    
            try {
                const response = await axiosWithInterceptor.get(`${SERVER_BASE_URL}${SERVER_API_ROLES}getRoles`);
                if (response.status === 200) {
                    return response.data;
                } else {
                    throw new Error('Unexpected response status');
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        // Handle unauthorized error
                        if (error.response.status === 401 || error.response.status === 403) {
                            throw new Error('You are not authorized to perform this action.');
                        }
                        // Handle bad request
                        if (error.response.status === 400) {
                            throw new Error(error.response.data || 'Bad request');
                        }
                    }
                    throw new Error(error.message || 'Network error');
                }
                throw error; // Throw other types of errors (non-Axios errors)
            }
        }

        static async deleteRole(roleName: string): Promise<any> {
            try {
                const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_ROLES}deleteRole`, { roleName });
                if (response.status === 200) {
                    return response.data;
                } else {
                    throw new Error('Unexpected response status');
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        // Handle unauthorized error
                        if (error.response.status === 401 || error.response.status === 403) {
                            throw new Error('You are not authorized to perform this action.');
                        }
                        // Handle bad request
                        if (error.response.status === 400) {
                            throw new Error(error.response.data || 'Bad request');
                        }
                    }
                    throw new Error(error.message || 'Network error');
                }
                throw error; // Throw other types of errors (non-Axios errors)
            }
        }
}
export default RoleService;


const fetchRoleDetails = async (roleId: string) => {
    try {
        const response = await axiosWithInterceptor.get(`${SERVER_BASE_URL}${SERVER_API_ROLES}getRole/${roleId}`);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response status');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Handle unauthorized error
                if (error.response.status === 401 || error.response.status === 403) {
                    throw new Error('You are not authorized to perform this action.');
                }
                // Handle not found
                if (error.response.status === 404) {
                    throw new Error('Role not found');
                }
                // Handle bad request
                if (error.response.status === 400) {
                    throw new Error(error.response.data || 'Bad request');
                }
            }
            throw new Error(error.message || 'Network error');
        }
        throw error; // Throw other types of errors (non-Axios errors)
    }
};
export { fetchRoleDetails };


 const updateRole = async (roleData: UpdateAspRoleDTO) => {
        try {
            const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_ROLES}updateRole`, roleData);

            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    // Handle unauthorized error
                    if (error.response.status === 401 || error.response.status === 403) {
                        throw new Error('You are not authorized to perform this action.');
                    }
                    // Handle bad request
                    if (error.response.status === 400) {
                        throw new Error(error.response.data || 'Bad request');
                    }
                }
                throw new Error(error.message || 'Network error');
            }
            throw error; // Throw other types of errors (non-Axios errors)
        }
    };

    export { updateRole };



    //write an api call to create a role
    const createRole = async (roleData: CreateAspRoleDTO) => {
        try {
            const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_ROLES}createRole`, roleData);

            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    // Handle unauthorized error
                    if (error.response.status === 401 || error.response.status === 403) {
                        throw new Error('You are not authorized to perform this action.');
                    }
                    // Handle bad request
                    if (error.response.status === 400) {
                        throw new Error(error.response.data || 'Bad request');
                    }
                }
                throw new Error(error.message || 'Network error');
            }
            throw error; // Throw other types of errors (non-Axios errors)
        }
    };
    export { createRole };

    //write an api call to assign a user to a role
   const assignUserToRole = async (roleUserAssignmentData: RoleUserAssignmentDTO) => {
    try {
        const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_ROLES}assignRole`, {
            RoleName: roleUserAssignmentData.roleName, // Ensure correct property names
            UserName: roleUserAssignmentData.userName,
        });

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Handle unauthorized error
                if (error.response.status === 401 || error.response.status === 403) {
                    throw new Error('You are not authorized to perform this action.');
                }
                // Handle bad request
                if (error.response.status === 400) {
                    throw new Error(error.response.data.join('\n') || 'Bad request'); // Assuming the errors are sent as an array of strings
                }
            }
            throw new Error(error.message || 'Network error');
        }
        throw error; // Throw other types of errors (non-Axios errors)
    }
};
export { assignUserToRole };


const unassignUserToRole = async (roleUserUnassignmentData: RoleUserAssignmentDTO) => {
    try {
        const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_ROLES}unassignRole`, {
            RoleName: roleUserUnassignmentData.roleName, // Ensure correct property names
            UserName: roleUserUnassignmentData.userName,
        });

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Handle unauthorized error
                if (error.response.status === 401 || error.response.status === 403) {
                    throw new Error('You are not authorized to perform this action.');
                }
                // Handle bad request
                if (error.response.status === 400) {
                    throw new Error(error.response.data.join('\n') || 'Bad request'); // Assuming the errors are sent as an array of strings
                }
            }
            throw new Error(error.message || 'Network error');
        }
        throw error; // Throw other types of errors (non-Axios errors)
    }
};
export { unassignUserToRole };

    

const getAllRoleAssignments = async () => {
    try {
        const response = await axiosWithInterceptor.get(`${SERVER_BASE_URL}${SERVER_API_ROLES}allRoleAssignments`);
        const roleAssignments: UserRoleAssignmentDTO[] = response.data;
        return roleAssignments;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Handle unauthorized error
                if (error.response.status === 401 || error.response.status === 403) {
                    throw new Error('You are not authorized to perform this action.');
                }
                // Handle bad request
                if (error.response.status === 400) {
                    throw new Error(error.response.data.join('\n') || 'Bad request'); // Assuming the errors are sent as an array of strings
                }
            }
            throw new Error(error.message || 'Network error');
        }
        throw error; // Throw other types of errors (non-Axios errors)
    }
};

export { getAllRoleAssignments };

const deleteUserByAdmin = async (deleteUserByAdminData: DeleteUserByAdminDTO) => {
    try {
      const response = await axiosWithInterceptor.delete(
        `${SERVER_BASE_URL}${SERVER_API_ROLES}deleteUserByAdmin`, 
        {
          data: deleteUserByAdminData // 'data' is used to send the request body in DELETE
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // Handle unauthorized error
          if (error.response.status === 401 || error.response.status === 403) {
            throw new Error('You are not authorized to perform this action.');
          }
          // Handle bad request
          if (error.response.status === 400) {
            throw new Error(error.response.data.join('\n') || 'Bad request'); // Assuming the errors are sent as an array of strings
          }
        }
        throw new Error(error.message || 'Network error');
      }
      throw error; // Throw other types of errors (non-Axios errors)
    }
  };
  
  export { deleteUserByAdmin };
  
