// src/components/dashboard/accountsettings/UpdateUserProfile.tsx
import React, { useState, useEffect } from 'react';
import { TextInput, Button, Modal } from 'flowbite-react';
import { userService, UserProfileDto } from '../../../services/userService';
import CustomLabel from '../../../themes/CustomLabel';
import ErrorAlert from '../../../htmlBlocks/ErrorAlert';
import SuccessAlert from '../../../htmlBlocks/SuccessAlert';
import { userCountryList } from '../../../config/config';
import { useAuthContext } from '../../../contexts/AuthContext';

const UpdateUserProfile: React.FC = () => {
    const [profile, setProfile] = useState<UserProfileDto>({
        firstName: '',
        lastName: '',
        userCountry: '',
        userOrganization: '',
        userRoleInUserOrganization: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const authContext = useAuthContext();
    if (!authContext) {
        throw new Error('Auth context is not properly initialized');
    }
    const { user } = authContext;    // Get the user from the context
    // State to manage modal visibility
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [initialProfile, setInitialProfile] = useState<UserProfileDto>({
        firstName: '',
        lastName: '',
        userCountry: '',
        userOrganization: '',
        userRoleInUserOrganization: ''
    });
    const [isFormChanged, setIsFormChanged] = useState(false);
    // Function to toggle the modal
    const toggleModal = () => setIsModalOpen(!isModalOpen);
    // Fetch user profile data on component mount
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const userProfile = await userService.getUserProfile();
                setProfile(userProfile);
                setInitialProfile(userProfile);
            } catch (error) {
                console.error('Error fetching user profile:', error);
                // Handle error appropriately
            }
        };
        fetchProfile();
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedProfile = { ...profile, [event.target.name]: event.target.value };
        setProfile(updatedProfile);
        setIsFormChanged(isProfileChanged(updatedProfile));
    };


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            const updatedUserDataDto = await userService.updateProfile(profile);
            setProfile(updatedUserDataDto); // Update the local state with the new data
            setSuccessMessage('Profile updated successfully. Log out and log back in to see the changes everywhere.');
            authContext.updateUserDataPostEdit

        } catch (error: unknown) {
            let message = 'Failed to update profile';
            if (typeof error === 'object' && error !== null) {
                message = (error as { message: string }).message;
            }
            setErrorMessage(message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const updatedProfile = { ...profile, userCountry: event.target.value };
        setProfile(updatedProfile);
        setIsFormChanged(isProfileChanged(updatedProfile));
    };

    const isProfileChanged = (updatedProfile: UserProfileDto) => {
        return (
            (updatedProfile.firstName || "") !== (initialProfile.firstName || "") ||
            (updatedProfile.lastName || "") !== (initialProfile.lastName || "") ||
            (updatedProfile.userCountry || "") !== (initialProfile.userCountry || "") ||
            (updatedProfile.userOrganization || "") !== (initialProfile.userOrganization || "") ||
            (updatedProfile.userRoleInUserOrganization || "") !== (initialProfile.userRoleInUserOrganization || "")
        );
    };

    const clearMessages = () => {
        setErrorMessage('');
        setSuccessMessage('');
    };
    return (

        <div>
            <form onSubmit={handleSubmit} className="p-4 bg-white border border-gray-200 sm:p-6 xl:p-8 dark:bg-gray-800 dark:border-gray-700 my-shadow-sharp rounded-lg">
                {
                    successMessage &&
                    <SuccessAlert
                        successMessage={successMessage}
                        clearMessages={clearMessages}
                    />
                }
                {
                    errorMessage &&
                    <ErrorAlert
                        errorMessage={errorMessage}
                        clearMessages={clearMessages}
                    />
                }
                <Modal show={isModalOpen} onClose={toggleModal}>
                    <Modal.Header className='dark:bg-purple-900 rounded-t-lg'>
                        <div className='text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white'>
                            Change email address
                        </div>
                    </Modal.Header>
                    <Modal.Body className="dark:bg-neutral-900">
                        <div>
                            <div className="mt-4">
                                <CustomLabel
                                    htmlFor="old-email">
                                    Old email address
                                </CustomLabel>
                                <TextInput
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="example@company.com"
                                    value={user?.email ?? ''}
                                    onChange={handleInputChange}
                                    autoComplete='email'
                                />
                            </div>
                            <div className="mt-4">
                                <CustomLabel
                                    htmlFor="new-email">
                                    Type your new email address
                                </CustomLabel>
                                <TextInput
                                    disabled

                                    type="email"
                                    id="new-email"
                                    placeholder="example@company.com"
                                    autoComplete='new-email'
                                />
                            </div>
                            <div className="mt-4">
                                <CustomLabel
                                    htmlFor="confirmation-code">
                                    We've sent a confirmation code to your new email address.
                                    Please type the code here to confirm your new email address.
                                    Note: If you didn't receive the code, please check your spam folder.
                                </CustomLabel>
                                <TextInput

                                    type="text"
                                    id="confirmation-code"
                                    placeholder="000000"
                                    autoComplete='confirmation-code'
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="dark:bg-neutral-900">
                        <Button color='purple' size="lg" onClick={() => {/* Handle email change logic */ }}>
                            Change Email
                        </Button>
                        <Button color="gray" size="lg" onClick={toggleModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
                    General information
                </h2>
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                        <CustomLabel htmlFor="firstName">
                            First Name
                        </CustomLabel>
                        <TextInput required
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="Bonnie"
                            value={profile.firstName || ''}
                            onChange={handleInputChange}
                            autoComplete='given-name'
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                        <CustomLabel htmlFor="lastName">
                            Last Name
                        </CustomLabel>
                        <TextInput
                            required
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Green"
                            value={profile.lastName || ''}
                            onChange={handleInputChange}
                            autoComplete='family-name'
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                        <CustomLabel htmlFor="email">
                            Email
                        </CustomLabel>
                        <div className="relative">
                            <TextInput
                                disabled
                                type="email"
                                name="email"
                                id="email"
                                placeholder="example@company.com"
                                value={user?.email ?? ''}
                                onChange={handleInputChange}
                                autoComplete='email'
                            />
                            <button
                                type='button'
                                aria-label="Change"
                                typeof="Button"
                                className="absolute top-1 bottom-1 right-1 text-xs font-medium uppercase px-2 py-1 text-blue-500 rounded-lg hover:text-blue-600 dark:hover:text-blue-400 hover:underline bg-gray-100 dark:bg-gray-700"
                                onClick={toggleModal}
                            >
                                Change
                            </button>

                        </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                        <CustomLabel htmlFor="userCountry">Country</CustomLabel>
                        <select
                            aria-label="list of countries"
                            id="countries"
                            value={profile.userCountry || ""}
                            onChange={handleCountryChange}
                            autoComplete='country'
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            <option value="">Select your country</option>
                            {userCountryList.map((country, index) => (
                                <option key={index} value={country}>
                                    {country}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                        <CustomLabel htmlFor="userOrganization">
                            Organization
                        </CustomLabel>
                        <TextInput
                            type="text"
                            name="userOrganization"
                            id="organization"
                            placeholder="Facebook, Google"
                            value={profile.userOrganization || ''}
                            onChange={handleInputChange}
                            autoComplete='organization'
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                        <CustomLabel htmlFor="role">
                            Role in Organization
                        </CustomLabel>
                        <TextInput
                            type="text"
                            name="userRoleInUserOrganization"
                            id="role"
                            placeholder="IP Professional"
                            value={profile.userRoleInUserOrganization || ''}
                            onChange={handleInputChange}
                            autoComplete='role'
                        />
                    </div>
                    <div className="col-span-6 sm:col-full">
                        <Button color="purple" size="lg" type="submit" className="mb-6" disabled={!isFormChanged || isLoading}
                        >
                            {isLoading && (
                                <span className="flex justify-center items-center">
                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                    </svg>
                                </span>
                            )}
                            <span className="flex justify-center items-center">
                                {isLoading ? 'Attempting to sign in...' : 'Update your user profile'}
                            </span>
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UpdateUserProfile;