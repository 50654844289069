// src/dashboard/pages/CategoryEdit.tsx
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchCategoryDetails, updateCategory, CategoryDTO, UpdateCategoryDTO } from '../../../services/categoryService';
import { Button, TextInput } from 'flowbite-react';

const CategoryEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [category, setCategory] = useState<CategoryDTO | null>(null);
    const [originalCategory, setOriginalCategory] = useState<CategoryDTO | null>(null); // State for original category data
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (id) {
            fetchCategoryDetails(id)
                .then(data => {
                    setCategory(data);
                    setOriginalCategory(data); // Set original category data
                    setLoading(false);
                })
                .catch(err => {
                    setErrorMessage('Error fetching category details: ' + err.message);
                    setLoading(false);
                });
        }
    }, [id]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCategory(prevState => prevState ? { ...prevState, [name]: value } : null);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage('');

        try {
            if (category && originalCategory) {
                const categoryDataToUpdate: UpdateCategoryDTO = {
                    oldCategoryName: originalCategory.name, // Use the original category name
                    newCategoryName: category.name,
                    description: category.description
                };

                await updateCategory(categoryDataToUpdate);
                navigate('/dashboard/categories');
            }
        } catch (error: any) {
            setErrorMessage(error.message || 'Unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (errorMessage) {
        return <div>Error: {errorMessage}</div>;
    }

    return (
        <div>
            <section className="py-4 bg-white dark:bg-gray-900 lg:pt-12 lg:pb-16">
                <div className="px-4 mx-auto max-w-8xl lg:px-4 lg:text-center ">

                    <h1 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 lg:font-extrabold lg:text-6xl lg:leading-none dark:text-white lg:text-center xl:px-36 lg:mb-7">
                        Update category
                    </h1>
                    <p className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 lg:text-center lg:text-xl xl:px-60">
                        Change the name and description of a category.
                    </p>
                    <div className='w-full justify-center'>
                        <form onSubmit={handleSubmit} className="grid gap-6">
                            <TextInput
                                id="id"
                                type="text"
                                name="id"
                                value={category?.id || ''}
                                readOnly
                                disabled
                            />
                            <TextInput
                                id="name"
                                type="text"
                                name="name"
                                value={category?.name || ''}
                                onChange={handleInputChange}
                            />
                            <TextInput
                                id="description"
                                type="text"
                                name="description"
                                value={category?.description || ''}
                                onChange={handleInputChange}
                            />
                            <Button type="submit">
                                Update Category
                            </Button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CategoryEdit;