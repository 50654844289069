import React, { useState, useEffect } from 'react';
import {
  getAllRoleAssignments,
  UserRoleAssignmentDTO,
  unassignUserToRole,
  deleteUserByAdmin,
  DeleteUserByAdminDTO,
} from '../../services/roleService';
import { Button } from 'flowbite-react';

const UserRoleAssignmentsTable: React.FC<{ refreshTrigger: boolean }> = ({ refreshTrigger }) => {
  const [userRoleAssignments, setUserRoleAssignments] = useState<UserRoleAssignmentDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleUnassignRole = async (userName: string, roleName: string) => {
    try {
      await unassignUserToRole({ userName, roleName });
      // Update local state to remove the unassigned role
      setUserRoleAssignments(prevAssignments =>
        prevAssignments.map(assignment => {
          if (assignment.userName === userName) {
            return {
              ...assignment,
              roleNames: assignment.roleNames.filter(role => role !== roleName),
            };
          }
          return assignment;
        })
      );
    } catch (error) {
      // Handle error (e.g., setting an error state or showing a message)
      setError('Failed to unassign role');
    }
  };

  const handleDelete = async (userName: string) => {
    try {
      const deletePayload: DeleteUserByAdminDTO = { userName };
      await deleteUserByAdmin(deletePayload);
      // Update local state to remove the deleted user
      setUserRoleAssignments(prevAssignments =>
        prevAssignments.filter(assignment => assignment.userName !== userName)
      );
    } catch (error) {
      setError('Failed to delete user');
    }
  };

  useEffect(() => {
    const fetchUserRoleAssignments = async () => {
      setIsLoading(true);
      try {
        const data = await getAllRoleAssignments();
        setUserRoleAssignments(data);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message); // Now safely accessing message property
        } else {
          setError('Failed to fetch user-role assignments'); // Fallback error message
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserRoleAssignments();
  }, [refreshTrigger]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="overflow-x-auto my-shadow-sharp rounded-lg">
      <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
              User Name
            </th>
            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
              Roles assigned to user
            </th>
            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
              Delete User
            </th>
          </tr>
        </thead>
        <tbody>
          {userRoleAssignments.map((assignment, userIndex) => (
            <tr key={userIndex} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td className="px-5 py-5 text-sm">{assignment.userName}</td>
              <td className="px-5 py-5 text-sm">
                {assignment.roleNames.map((roleName, roleIndex) => (
                  <div key={roleIndex} className="flex items-center justify-between mb-1">
                    <strong> {roleName}</strong>
                    <button
                      type="button"
                      onClick={() => handleUnassignRole(assignment.userName, roleName)}
                      className="ml-2 text-red-500 hover:text-red-700"
                    >
                      Remove {roleName} role from {assignment.userName}
                    </button>
                  </div>
                ))}
              </td>
              <td className="px-6 py-4">
                <Button
                  color="failure"
                  size="sm"
                  gradient="failure"
                  className="mr-2"
                  onClick={() => handleDelete(assignment.userName)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserRoleAssignmentsTable;
