//src/business/pages/utils/generateTimelineMarks.ts
const generateTimelineMarks = (start: Date, end: Date, fixedMarkerDistance: number) => {
  const marks = [];
  const startYear = start.getFullYear();
  const endYear = end.getFullYear();

  // Generate a mark for each year from startYear to endYear
  for (let year = startYear; year <= endYear; year++) {
    // Calculate the position of the mark based on the distance for each year
    const leftPosition = (year - startYear) * fixedMarkerDistance;
    marks.push({ year, leftPosition });
  }

  return marks;
};

export default generateTimelineMarks;