// src/routes/ProtectedRoute.tsx
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

interface ProtectedRouteProps {
    children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const authContext = useContext(AuthContext);

    if (!authContext) {
        // Handle the missing context appropriately
        return <div>Loading...</div>; // or any other fallback UI
    }

    if (!authContext.user) {
        // If there is no user in the context, redirect to the login page
        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedRoute;
