// src/components/AccountConfirm.tsx
import React, { useEffect, useState, useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { confirmEmail } from '../services/authService';
import { TextInput, Button, ToggleSwitch } from 'flowbite-react';
import CustomLabel from '../themes/CustomLabel';
import ErrorAlert from '../htmlBlocks/ErrorAlert';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';
import { RoutePaths } from '../routes/RoutePaths';
import { useAuthContext } from '../contexts/AuthContext';

const AccountConfirm: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [confirmationStatus, setConfirmationStatus] = useState<'loading' | 'success' | 'error'>('loading');
    const [errorMessage, setErrorMessage] = useState<string>(''); // State to hold error message
    const isConfirmationStarted = useRef(false);
    const authContext = useAuthContext();

    useEffect(() => {
        async function confirmUserEmail() {
            if (isConfirmationStarted.current) {
                return;
            }

            isConfirmationStarted.current = true; // Mark that the confirmation process has started

            const uid = searchParams.get('uid');
            const code = searchParams.get('code');
            if (!uid || !code) {
                setConfirmationStatus('error');
                setErrorMessage('Invalid request parameters.');
                return;
            }

            try {
                const confirmEmailData = {
                    userId: uid,
                    code: code
                };
                const response = await confirmEmail(confirmEmailData);

                if (response.succeeded) {
                    setConfirmationStatus('success');
                } else {
                    setConfirmationStatus('error');
                    setErrorMessage(response.message || 'Unknown error occurred');
                }
            } catch (error) {
                setConfirmationStatus('error');
                if (error instanceof Error) { // Better error handling
                    setErrorMessage(error.message);
                }
            }
        }

        confirmUserEmail(); // Call the async function
    }, [searchParams]);

    let message;
    let alertStyle = "";

    switch (confirmationStatus) {
        case 'loading':
            message = "Confirming your email...";
            alertStyle = "bg-green-100 dark:bg-green-200 text-green-700 dark:text-green-800";
            break;
        case 'success':
            message = "Your email has been successfully confirmed!";
            alertStyle = "bg-green-100 dark:bg-green-200 text-green-700 dark:text-green-800";
            break;
        case 'error':
            message = errorMessage;
            alertStyle = "bg-red-100 dark:bg-red-200 text-red-700 dark:text-red-800";
            break;
        default:
            message = "Unexpected state.";
            alertStyle = "bg-gray-100 dark:bg-gray-200 text-gray-700 dark:text-gray-800";
    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState('');

    const navigate = useNavigate();

    // Use AuthContext
    if (!authContext) {
        throw new Error('Auth context is not properly initialized');
    }

    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async (event: React.FormEvent,) => {
        event.preventDefault();
        setIsLoading(true);
        setLoginErrorMessage('');

        try {
            const response = await authService.loginViaAuthService({ email, password, rememberMe });
            // Use the updateUserContextInAC function to update the context
            if (response.token) {
                authContext.updateUserContext(response.token);
                navigate(RoutePaths.Home); // Navigate to the dashboard or desired path after successful login
            }
        } catch (error: unknown) {
            let message = 'Failed to log in';
            if (typeof error === 'object' && error !== null) {
                message = (error as { message: string }).message;
            }
            setLoginErrorMessage(message);
        } finally {
            setIsLoading(false);
        }
    };
    // Function to clear LoginErrorMessages
    const clearMessages = () => {
        setLoginErrorMessage('');
    };
    return (
        <div className="flex flex-col justify-center items-center py-8 px-6 mx-auto md:h-screen">
            <Link className="flex justify-center items-center mb-8 text-3xl font-semibold lg:mb-10 dark:text-white" to={RoutePaths.Home}>
                <span className="ml-3">Patalytica</span>
            </Link>
            <div className="justify-center items-center w-full bg-white rounded-lg shadow lg:flex md:mt-0 lg:max-w-screen-sm xl:p-0 dark:bg-gray-800 my-shadow-sharp rounded-lg">
                <div className="p-6 w-full sm:p-8 lg:p-10">
                    {
                        loginErrorMessage &&
                        <ErrorAlert
                            errorMessage={loginErrorMessage}
                            clearMessages={clearMessages}
                        />
                    }

                    <div className={`flex justify-between p-4 mb-4 rounded-lg ${alertStyle}`} role="alert">
                        <div className="text-sm font-medium">
                            <dl>
                                <dt className="sr-only">{confirmationStatus === 'error' ? 'Error Message' : 'Success Message'}</dt>
                                <dd>{message}</dd>
                            </dl>
                        </div>
                    </div>

                    <h1 className="mb-3 text-2xl font-bold text-gray-900 lg:text-3xl dark:text-white">Sign into your account</h1>
                    <p className="mb-3 text-gray-500 dark:text-gray-400">Access hundreds of IP analytics tools and systems</p>

                    <form onSubmit={handleLogin} className="mt-8">
                        <div className="mb-6">
                            <CustomLabel htmlFor="email">
                                Your email
                            </CustomLabel>
                            <TextInput
                                id="email"
                                type="text"
                                value={email}
                                name={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="name@company.com"
                                required
                            />
                        </div>
                        <div className="mb-6">
                            <CustomLabel htmlFor="password">
                                Your password
                            </CustomLabel>
                            <TextInput
                                id="password"
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder=""
                                required
                            />
                        </div>

                        <div className="flex items-start mb-6">
                            <div className="flex items-center h-5">
                                <ToggleSwitch
                                    label="Remember me"
                                    color='purple'
                                    id="remember"
                                    aria-describedby="remember"
                                    name="remember"
                                    checked={rememberMe}
                                    onChange={(e) => setRememberMe(e.valueOf())}
                                />
                            </div>
                            <Link className="ml-auto text-sm text-purple-700 dark:text-purple-500 hover:underline" to={RoutePaths.ForgotPassword}>Forgot Password?</Link>
                        </div>
                        <Button color="purple" size="lg" type="submit" className="mb-6">
                            {isLoading && (
                                <span className="flex justify-center items-center">
                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                    </svg>
                                </span>
                            )}
                            <span className="flex justify-center items-center">
                                {isLoading ? 'Attempting to sign in...' : 'Sign in to account'}
                            </span>
                        </Button>
                        <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
                            Not registered?
                            <Link className="ml-1 text-purple-700 hover:underline dark:text-purple-500" to={RoutePaths.Register}>
                                Create an account.
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AccountConfirm;
