import { useEffect } from 'react';

const useDynamicBackgroundImage = (imageUrl: string | undefined, className: string = 'dynamicBackgroundImage') => {
  useEffect(() => {
    if (!imageUrl) {
      // Handle the absence of imageUrl (e.g., remove the style or do nothing)
      return;
    }

   const style = document.createElement('style');
    style.innerHTML = `.${className} { background-image: url(${imageUrl}); }`;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [imageUrl, className]);
};

export default useDynamicBackgroundImage;