import React, { useEffect } from 'react';
import RowItemComponent from './RowItemComponent';
import { SingleRow } from '../../models/FamilyTreeInterfaces';
import PublicationLineSVG from './PublicationLineSVG';

interface RowItemsProps {
  data: SingleRow[];
  rowHeight: number;
  getPositionFromDate: (dateString: Date, startDate: Date, fixedMarkerDistance: number) => number;
  startDate: Date;
  fixedMarkerDistance: number;
  containerRef: React.RefObject<HTMLDivElement>;
}

const RowItems: React.FC<RowItemsProps & { updateRowCount: (rowCount: number) => void }> = ({
  data,
  rowHeight,
  getPositionFromDate,
  startDate,
  fixedMarkerDistance,
  updateRowCount,
  containerRef
}) => {

  useEffect(() => {
    updateRowCount(data.length);
  }, [data.length, updateRowCount]);

  return (
    <>
      {data.map((singleRow, rowIndex) => {
        const applicationRef = singleRow.row.find(item => item.type === "Application")?.fullReference;

        return (
          <div className={`rowItems-transition relative`} key={singleRow.id}>
            <PublicationLineSVG singleRow={singleRow} rowHeight={rowHeight} />
            {singleRow.row.map((item) => (
              <React.Fragment key={item.id}>
                <div
                  className="absolute border-t border-dashed w-full dark:bg-neutral-900 border-purple-400 my-shadow-sharp mx-auto text-xs font-medium text-gray-900"
                  style={{ top: `${rowIndex * rowHeight}px` }}
                />
                <RowItemComponent
                  singleRow={singleRow}
                  item={item}
                  index={rowIndex * rowHeight}
                  getPositionFromDate={getPositionFromDate}
                  startDate={startDate}
                  fixedMarkerDistance={fixedMarkerDistance}
                  rowHeight={rowHeight}
                  containerRef={containerRef}
                  associatedApplicationRef={applicationRef ?? ""}
                />
              </React.Fragment>
            ))}
          </div>
        );
      })}
    </>
  );
};

export default RowItems;