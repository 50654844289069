// src/hooks/useFetchBlogPost.ts

import { useState, useEffect } from 'react';
import { blogService, SingleBlogPostDTO } from '../services/blogService';

const useFetchBlogPost = (slug: string | undefined) => {
  const [blogPost, setBlogPost] = useState<SingleBlogPostDTO | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPost = async () => {
    if (!slug) return;

    setIsLoading(true);
    setError(null);

    try {
      const fetchedPost = await blogService.fetchSingleBlogPostBySlug(slug);
      setBlogPost(fetchedPost);
    } catch (err) {
      setError('Error fetching post');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPost();
  }, [slug]);

  return { blogPost, isLoading, error, fetchPost };
};

export default useFetchBlogPost;
