//src/blog/components/LoadingHeader.tsx
import React from 'react';
import { Spinner } from 'flowbite-react';

interface LoadingHeaderProps {
  message?: string;
}

const LoadingHeader: React.FC<LoadingHeaderProps> = ({ message }) => {
  return (
    <main className="pb-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
      <header className="bg-[] w-full h-[460px] xl:h-[537px] bg-no-repeat bg-cover bg-center bg-blend-darken relative">
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50">
        </div>
        <div className="absolute top-20 left-1/2 px-4 mx-auto w-full max-w-screen-xl -translate-x-1/2 xl:top-1/2 xl:-translate-y-1/2 xl:px-0">
          <span className="block mb-4 text-gray-300 italic"><span> </span>
          </span>
          <h1 className="mb-4 max-w-4xl text-2xl font-extrabold leading-none text-white sm:text-3xl lg:text-4xl italic">
            {message || 'Fetching content...'}
          </h1>
          <p className="text-lg font-normal text-gray-300 italic">

          </p>
        </div>
      </header>
      <div className="flex relative z-20 justify-between p-6 -m-36 mx-4 max-w-screen-xl bg-white dark:bg-gray-800 rounded xl:-m-32 xl:p-9 xl:mx-auto">
        <article className="xl:w-[828px] w-full max-w-none format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
          <div className="justify-between lg:items-center justify-center py-16">
            <div className='w-full mt-40 mb-80 spy-16'>
              <div className='flex justify-center'>
                <Spinner
                  size='xl'
                  color='purple'
                />
              </div>
            </div>
          </div>
        </article>
      </div>
    </main>

  )
};

export default LoadingHeader;