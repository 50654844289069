// src/admin/navbar/AdminNavigation.tsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RoutePaths } from '../../routes/RoutePaths';


const AdminNavigation: React.FC
    = () => {

        const location = useLocation();
        // Function to determine if the link is active
        const isActive = (path: string) => location.pathname === path;

        return (
            <div className="border-t border-b border-gray-100 bg-gray-50 dark:border-gray-800 dark:bg-gray-800">
                <div className="w-full px-3 mx-auto max-w-8xl lg:px-3">
                    <ul className="flex flex-wrap -mb-px -ml-4">
                        <li className="mr-2">
                            <Link
                                className={`inline-flex rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b-2 group 
                                        ${isActive(RoutePaths.AdminDashboard) ? 'text-red-600 border-red-600 dark:text-red-500 dark:border-red-500' : 'text-gray-500 dark:text-gray-400 border-gray-50 dark:border-gray-800 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:hover:border-gray-400'}`}
                                to={RoutePaths.AdminDashboard}
                            >
                                <span className="mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z">
                                        </path>
                                    </svg>
                                </span>
                                Admin dashboard
                            </Link>
                        </li>
                        <li className="mr-2">
                            <Link
                                className={`inline-flex rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b-2 group 
                                        ${isActive(RoutePaths.AdminRoleUserAssignments) ? 'text-red-600 border-red-600 dark:text-red-500 dark:border-red-500' : 'text-gray-500 dark:text-gray-400 border-gray-50 dark:border-gray-800 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:hover:border-gray-400'}`}
                                to={RoutePaths.AdminRoleUserAssignments}
                            >
                                <span className="mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                Assign Roles to Users
                            </Link>
                        </li>
                        <li className="mr-2">
                            <Link
                                className={`inline-flex rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b-2 group 
                                        ${isActive(RoutePaths.AdminRoles) ? 'text-red-600 border-red-600 dark:text-red-500 dark:border-red-500' : 'text-gray-500 dark:text-gray-400 border-gray-50 dark:border-gray-800 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:hover:border-gray-400'}`}
                                to={RoutePaths.AdminRoles}
                            >
                                <span className="mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                create, read, update, delete roles
                            </Link>
                        </li>
                        <li className="mr-2">
                            <Link
                                className={`inline-flex rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b-2 group 
                                        ${isActive(RoutePaths.AdminSubscriptionTiers) ? 'text-red-600 border-red-600 dark:text-red-500 dark:border-red-500' : 'text-gray-500 dark:text-gray-400 border-gray-50 dark:border-gray-800 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:hover:border-gray-400'}`}
                                to={RoutePaths.AdminSubscriptionTiers}
                            >
                                <span className="mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                create, read, update, delete subscription tiers
                            </Link>
                        </li>

                    </ul>
                </div>
            </div>

        );
    }

export default AdminNavigation;