// src/components/CompleteOnboardingPage.tsx
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { authService } from '../services/authService';
import CustomLabel from '../themes/CustomLabel';
import { TextInput, Button } from 'flowbite-react';
import { Link } from 'react-router-dom';

interface Checklist {
  emailConfirmed: boolean;
  passwordSet: boolean;
  termsAccepted: boolean;
  actions: string[];
  firstName: string;
  email: string;
}

const CompleteOnboardingPage: React.FC = () => {
  const location = useLocation();
  const [checklist, setChecklist] = useState<Checklist | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getOnboardingToken = (): string | null => {
    const params = new URLSearchParams(location.search);
    return params.get("onboardingToken");
  };

  useEffect(() => {
    const fetchChecklist = async () => {
      const onboardingToken = getOnboardingToken();
      if (!onboardingToken) {
        setError("Onboarding token is missing from the URL.");
        return;
      }

      try {
        const { checklist, error } = await authService.getWelcomeChecklist(onboardingToken);
        if (error) {
          setError(error);
        } else {
          setChecklist(checklist ?? {
            emailConfirmed: false,
            passwordSet: false,
            termsAccepted: false,
            firstName: '',
            email: '',
            actions: []
          });
        }
      } catch (err) {
        setError("An unexpected error occurred while fetching the welcome checklist.");
      }
    };

    fetchChecklist();
  }, [location.search]);

  const handleSavePassword = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      setIsLoading(false);
      return;
    }

    const onboardingToken = getOnboardingToken();
    if (!onboardingToken) {
      setError("Onboarding token is missing from the URL.");
      setIsLoading(false);
      return;
    }

    try {
      const { error } = await authService.setPassword(onboardingToken, password, confirmPassword);
      if (error) {
        setError(error);
      } else {
        setError(null);
        setChecklist((prevChecklist) => prevChecklist ? { ...prevChecklist, passwordSet: true } : null);
        setPassword(''); // Clear password fields after saving
        setConfirmPassword('');
      }
    } catch (err) {
      setError("An unexpected error occurred while setting the password.");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="welcome-checklist-container flex flex-col justify-center items-center py-8 px-6 mx-auto md:h-screen dark:bg-black">
      <h1 className="flex justify-center items-center mb-8 text-3xl font-semibold lg:mb-10 dark:text-white">
        Welcome to Patalytica, {checklist?.firstName}!
      </h1>
      <h2 className="mb-8 text-xl font-semibold lg:mb-10 dark:text-white">
        Please complete the following checklist to get started:
      </h2>
      {error ? (
        <p className="text-red-500">{error}</p>
      ) : checklist ? (
        <>
          <p>Terms Accepted: {checklist.termsAccepted ? "Yes" : "No"}</p>
          <p>Email {checklist?.email} Confirmed: {checklist.emailConfirmed ? "Yes" : "No"}</p>
          <p>Password Set: {checklist.passwordSet ? "Yes" : "No"}</p>
          <form onSubmit={handleSavePassword} className="mt-8">
            <div className="mb-6">
              <CustomLabel htmlFor="password">Your password</CustomLabel>
              <TextInput
                id="password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError(null); // Clear error on change
                }}
                required
                autoComplete="new-password"
                placeholder={isLoading
                  ? 'Attempting to save password...'
                  : checklist.passwordSet
                    ? '********'
                    : 'create a password'}
              />
            </div>
            <div className="mb-6">
              <CustomLabel htmlFor="confirmPassword">Confirm password</CustomLabel>
              <TextInput
                id="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                autoComplete="new-password"
                placeholder={isLoading
                  ? 'Attempting to save password...'
                  : checklist.passwordSet
                    ? '********'
                    : 'confirm your password'}
              />
            </div>

            <Button color="purple" size="lg" type="submit" className="mb-6">
              <span className="flex justify-center items-center">
                {isLoading
                  ? 'Attempting to save password...'
                  : checklist.passwordSet
                    ? 'Update Password'
                    : 'Save Password'}
              </span>
            </Button>
          </form>
          <div>
            {isLoading ? (
              'Attempting to save password...'
            ) : checklist.passwordSet && checklist.emailConfirmed ? (
              <>
                <p>You are all set!</p>
                <Link to="/login" className="no-underline">
                  <Button
                    size="md"
                    className="my-shadow-sharp uppercase"
                    gradientDuoTone="purpleToBlue"
                  >
                    <span>Go to User Area</span>
                  </Button>
                </Link>
              </>
            ) : (
              'Actions Required:'
            )}
          </div>

          <ul className="actions-list">
            {checklist.actions.map((action, index) => (
              <li key={index} className="text-gray-700">{action}</li>
            ))}
          </ul>
        </>
      ) : (
        <p>Loading checklist...</p>
      )
      }
    </div >
  );
};

export default CompleteOnboardingPage;
