// src/admin/pages/SubscriptionTierEdit.tsx
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { SubscriptionTierDTO, UpdateSubscriptionTierDTO } from '../../services/subscriptionTierService';
import SubscriptionTierService from '../../services/subscriptionTierService';
import { Button, TextInput } from 'flowbite-react';

const SubscriptionTierEdit = () => {
    const navigate = useNavigate();
    const [SubscriptionTier, setSubscriptionTier] = useState<SubscriptionTierDTO | null>(null);
    const [originalSubscriptionTier] = useState<SubscriptionTierDTO | null>(null);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSubscriptionTier(prevState => prevState ? { ...prevState, [name]: value } : null);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage('');

        try {
            if (SubscriptionTier && originalSubscriptionTier) {
                const SubscriptionTierDataToUpdate: UpdateSubscriptionTierDTO = {
                    idToUpdate: originalSubscriptionTier.id, // Use the original SubscriptionTier id
                    name: SubscriptionTier.name || originalSubscriptionTier.name, // Use the updated or original SubscriptionTier name
                    pricePerMonth: SubscriptionTier.pricePerMonth,
                    pricePerYear: SubscriptionTier.pricePerYear
                };

                // Call the update method
                await SubscriptionTierService.updateSubscriptionTier(SubscriptionTierDataToUpdate);

                // Redirect to subscription tiers page after successful update
                navigate('/admin/SubscriptionTiers');
            } else {
                throw new Error('SubscriptionTier or originalSubscriptionTier is undefined.');
            }
        } catch (error: any) {
            // Check for known errors from the update function and others
            if (error instanceof Error) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage('An unexpected error occurred');
            }
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (errorMessage) {
        return <div>Error: {errorMessage}</div>;
    }

    return (
        <div>
            <section className="py-4 bg-white dark:bg-gray-900 lg:pt-12 lg:pb-16 dark:bg-black">
                <div className="px-4 mx-auto max-w-8xl lg:px-4 lg:text-center ">

                    <h1 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 lg:font-extrabold lg:text-6xl lg:leading-none dark:text-white lg:text-center xl:px-36 lg:mb-7">
                        Update SubscriptionTier
                    </h1>
                    <p className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 lg:text-center lg:text-xl xl:px-60">
                        Change the name and description of a SubscriptionTier.
                    </p>
                    <div className='w-full justify-center'>
                        <form onSubmit={handleSubmit} className="grid gap-6">
                            <TextInput
                                id="id"
                                type="text"
                                name="id"
                                value={SubscriptionTier?.id || ''}
                                readOnly
                                disabled
                            />
                            <TextInput
                                id="name"
                                type="text"
                                name="name"
                                value={SubscriptionTier?.name || ''}
                                onChange={handleInputChange}
                            />
                            <TextInput
                                id="pricePerMonth"
                                type="number"
                                name="Price Per Month"
                                value={SubscriptionTier?.pricePerMonth || ''}
                                onChange={handleInputChange}
                            />
                            <TextInput
                                id="pricePerYear"
                                type="number"
                                name="Price Per Year"
                                value={SubscriptionTier?.pricePerYear || ''}
                                onChange={handleInputChange}
                            />
                            <Button type="submit">
                                Update Subscription Tier
                            </Button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SubscriptionTierEdit;