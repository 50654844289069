import React from 'react';

interface RenewalFeeProps {
  renewalYear: string;
  renewalFeeDescription: string;
  paymentDate: string;
}

const RenewalFee: React.FC<RenewalFeeProps> = ({
  renewalYear,
  renewalFeeDescription,
  paymentDate
}) => {



  return (
    <>
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal">
          <p className="text-gray-700 dark:text-white font-normal">{renewalYear}</p>
        </th>
        <td className="pr-2 sm:pr-6">
          <p className="text-gray-700 dark:text-white font-normal">
            <a className="font-medium underline underline-offset-2 text-blue-600 dark:text-blue-500 hover:no-underline" href="https://plevanltd.com/en-us/renewals/ep">{renewalFeeDescription}</a>
          </p>
        </td>
        <td className="pr-2 sm:pr-6 flex justify-start items-center">
          <p className="text-gray-700 dark:text-white font-normal">{paymentDate}</p>
        </td>
      </tr>
    </>
  );
};

export default RenewalFee;