import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import paymentService from '../services/paymentService';

interface PaymentFormProps {
  priceLookupKey: string;
  onPaymentError: (errorMessage: string) => void;
  onSuccessfulPayment: (onboardingToken: string) => void; // Make sure this line is included
}

const PaymentForm: React.FC<PaymentFormProps> = ({ priceLookupKey, onPaymentError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [onboardingToken, setOnboardingToken] = useState<string | null>(null); // Store onboarding token here
  let isPaymentProcessing = false;

  const handlePaymentConfirmation = async (clientSecret: string): Promise<boolean> => {
    if (isPaymentProcessing) {
      console.warn('Duplicate payment attempt detected. Aborting this attempt.');
      return false;
    }

    if (!stripe) {
      console.error('Stripe is not loaded.');
      onPaymentError('Stripe is not available. Please try again later.');
      return false;
    }

    isPaymentProcessing = true;
    console.log('Initiating payment confirmation with clientSecret:', clientSecret);

    try {
      const paymentIntentResponse = await stripe.retrievePaymentIntent(clientSecret);
      const paymentIntent = paymentIntentResponse?.paymentIntent;

      if (!paymentIntent) {
        console.error('Failed to retrieve payment intent');
        onPaymentError('An error occurred while processing your payment.');
        return false;
      }

      if (paymentIntent.status === 'requires_confirmation' || paymentIntent.status === 'requires_action') {
        const paymentResult = await stripe.confirmCardPayment(clientSecret);

        if (paymentResult.error) {
          console.error('Payment error:', paymentResult.error);
          onPaymentError(paymentResult.error.message || 'An error occurred with your payment.');
          return false;
        } else if (paymentResult.paymentIntent?.status === 'succeeded' || paymentResult.paymentIntent?.status === 'requires_capture') {
          console.log('Payment successful with status:', paymentResult.paymentIntent.status);
          return true;
        }
      } else if (paymentIntent.status === 'succeeded') {
        console.log('Payment already succeeded.');
        return true;
      } else {
        console.warn('Unexpected PaymentIntent status:', paymentIntent.status);
        onPaymentError('Payment cannot be confirmed in the current state.');
        return false;
      }
    } catch (error) {
      console.error('Error during payment confirmation:', error);
      onPaymentError('A network or server error occurred during payment processing. Please try again.');
      return false;
    } finally {
      isPaymentProcessing = false;
      console.log('Payment confirmation complete, reset flag.');
    }
    return false;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage(null);
    if (!stripe || !elements) {
      setErrorMessage("Stripe has not loaded yet.");
      return;
    }

    setIsLoading(true);

    try {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        setErrorMessage("Card details not entered correctly.");
        setIsLoading(false);
        return;
      }

      const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { email, name: `${firstName} ${lastName}` },
      });

      if (paymentMethodError) {
        setErrorMessage(paymentMethodError.message || "Failed to create payment method.");
        setIsLoading(false);
        return;
      }

      const { clientSecret, onboardingToken, error } = await paymentService.createSubscription(
        email,
        priceLookupKey,
        paymentMethod!.id,
        firstName,
        lastName
      );

      if (error || !clientSecret || !onboardingToken) {
        setErrorMessage(error || "Failed to retrieve payment information.");
        setIsLoading(false);
        return;
      }

      const paymentConfirmed = await handlePaymentConfirmation(clientSecret);

      // Store the onboarding token if payment is confirmed successfully
      if (paymentConfirmed) {
        setOnboardingToken(onboardingToken); // Save the token for later use
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Handler for the "Complete Onboarding" button click
  const handleCompleteOnboarding = () => {
    if (onboardingToken) {
      // Redirect to the onboarding page with the onboarding token
      window.location.href = `/complete-onboarding?onboardingToken=${onboardingToken}`;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="flex flex-col space-y-4">
        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
          First Name
        </label>
        <input
          id="first-name"
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          className="p-2 border rounded-md w-full"
          placeholder="Enter your first name"
        />
      </div>

      <div className="flex flex-col space-y-4">
        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
          Last Name
        </label>
        <input
          id="last-name"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
          className="p-2 border rounded-md w-full"
          placeholder="Enter your last name"
        />
      </div>

      <div className="flex flex-col space-y-4">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email Address
        </label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="p-2 border rounded-md w-full"
          placeholder="Enter your email"
        />
      </div>

      <div className="mt-4">
        <CardElement className="p-2 border rounded-md" />
      </div>

      {errorMessage && <p className="text-red-600">{errorMessage}</p>}

      <button
        type="submit"
        disabled={!stripe || isLoading}
        className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
      >
        {isLoading ? 'Processing...' : 'Pay'}
      </button>

      {isLoading && <p className="text-center text-gray-600 dark:text-gray-400">Processing payment...</p>}

      {/* Render "Complete Onboarding" button after successful payment */}
      {onboardingToken && (
        <button
          onClick={handleCompleteOnboarding}
          className="mt-4 w-full py-2 px-4 bg-green-600 text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        >
          Complete Onboarding
        </button>
      )}
    </form>
  );
};

export default PaymentForm;