// src/admin/components/QueryCategoryAssignmentsTable.tsx
import React, { useState, useEffect } from 'react';
import { getAllCategoryAssignments, QueryCategoryAssignmentDTO, unassignQueryToCategory } from '../../../services/categoryService';
import { Button, Spinner } from 'flowbite-react';

const QueryCategoryAssignmentsTable: React.FC<{ refreshTrigger: boolean }> = ({ refreshTrigger }) => {
  const [queryCategoryAssignments, setQueryCategoryAssignments] = useState<QueryCategoryAssignmentDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [noEntries, setNoEntries] = useState(false); // New state to track no entries

  const fetchQueryCategoryAssignments = async () => {
    setIsLoading(true);
    try {
      const data = await getAllCategoryAssignments();
      if (data.length === 0) {
        setNoEntries(true); // Set no entries state if data is empty
      } else {
        setQueryCategoryAssignments(data);
        setNoEntries(false); // Reset no entries state
      }
    } catch (err) {
      if (err instanceof Error) {
        setError("Failed to fetch data. Please try again later."); // Set a user-friendly error message
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchQueryCategoryAssignments();
  }, [refreshTrigger]);

  const handleUnassignCategory = async (viewRequestLogId: string, categoryId: string) => {
    try {
      const response = await unassignQueryToCategory({ categoryId, viewRequestLogId });
      if (response) {
        setQueryCategoryAssignments(prevAssignments =>
          prevAssignments.map(assignment => {
            if (assignment.viewRequestLogId === viewRequestLogId) {
              return {
                ...assignment,
                categoryIds: assignment.categoryIds.filter(category => category !== categoryId),
                // Also remove the corresponding categoryName if necessary
                // categoryNames: assignment.categoryNames.filter((_, index) => assignment.categoryIds[index] !== categoryId),
              };
            }
            return assignment;
          })
        );
        // Re-fetch data to ensure UI is in sync with the server
        fetchQueryCategoryAssignments();
      }
    } catch (error) {
      console.error('Error unassigning category:', error);
      // Handle error (e.g., setting an error state or showing a message)
    }
  }


  if (isLoading) {
    <div className="overflow-x-auto my-shadow-sharp rounded-lg">
      <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
              <Spinner color="purple" />
            </th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
    </div>
  }

  if (error) {
    <div className="overflow-x-auto my-shadow-sharp rounded-lg">
      <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
              Error: {error}
            </th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
    </div>
  }

  if (noEntries) {
    return (

      <div className="overflow-x-auto my-shadow-sharp rounded-lg">
        <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
                No category assignments found. Start by assigning categories to queries.
              </th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>
    )

  }


  return (
    <div className="overflow-x-auto my-shadow-sharp rounded-lg">
      <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
              Query Name
            </th>
            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
              Categories assigned to query
            </th>
          </tr>
        </thead>
        <tbody>
          {queryCategoryAssignments.map((assignment, queryIndex) => (
            <tr key={queryIndex} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td className="px-5 py-5 text-sm">
                {assignment.applicationNumber}
              </td>
              <td className="px-5 py-5 text-sm">
                {assignment.categoryNames.map((categoryName, categoryIndex) => {
                  const categoryId = assignment.categoryIds[categoryIndex]; // Assuming the indexes match
                  return (
                    <div key={categoryIndex} className="flex items-center justify-between mb-1">
                      <strong> {categoryName}</strong>
                      <Button
                        type="button"
                        color="red"
                        onClick={() => handleUnassignCategory(assignment.viewRequestLogId, categoryId)} // Use categoryId here
                      >
                        Remove
                      </Button>
                    </div>
                  );
                })}
              </td>
            </tr>
          ))}
        </tbody>

      </table>
    </div>
  );
};

export default QueryCategoryAssignmentsTable;