import React, { useState, useEffect } from 'react';
import opsService, { PastEpRegisterQueriesDTO } from '../../../business/services/opsService';
import { Button } from 'flowbite-react';
import { Link } from 'react-router-dom';
import SuccessAlert from '../../../htmlBlocks/SuccessAlert';
import ErrorAlert from '../../../htmlBlocks/ErrorAlert';

const PastEpRegisterQueries: React.FC = () => {
    const [queries, setQueries] = useState<PastEpRegisterQueriesDTO[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [sortAppNr, setSortAppNr] = useState<'asc' | 'desc' | 'none'>('none');
    const [sortRequestTime, setSortRequestTime] = useState<'asc' | 'desc' | 'none'>('none');
    useEffect(() => {
        const fetchQueries = async () => {
            try {
                const fetchedQueries = await opsService.fetchPastEpRegisterQueries();
                setQueries(fetchedQueries);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message); // Now safely accessing message property
                } else {
                    setError('Failed to fetch user-role assignments'); // Fallback error message
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchQueries();
    }, []);
    const formatDateTime = (utcString: string) => {
        const date = new Date(utcString);
        return new Intl.DateTimeFormat('default', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).format(date);
    };
    const handleDelete = (queryId: string) => {
        setIsLoading(true);
        setErrorMessage('');
        setSuccessMessage('');
        opsService.deleteEpRegisterQuery(queryId)
            .then((responseMessage) => {
                // Set the success message
                setSuccessMessage(responseMessage);

                // Filter out the deleted query from the queries state
                setQueries(queries.filter(query => query.id !== queryId));
                setIsLoading(false);
            })
            .catch(err => {
                // Set the error message
                setErrorMessage(err.message);
                setIsLoading(false);
            });
    };

    if (error) {
        return <div>Error: {error}</div>;
    }
    const clearMessages = () => {
        setErrorMessage('');
        setSuccessMessage('');
    };

    const sortQueriesByAppNr = () => {
        const sortedQueries = [...queries];
        sortedQueries.sort((a, b) => {
            const appNrA = a.applicationNr?.toUpperCase() ?? ""; // Handle undefined
            const appNrB = b.applicationNr?.toUpperCase() ?? ""; // Handle undefined
            if (sortAppNr === 'asc' || sortAppNr === 'none') {
                return appNrA < appNrB ? -1 : appNrA > appNrB ? 1 : 0;
            } else {
                return appNrA > appNrB ? -1 : appNrA < appNrB ? 1 : 0;
            }
        });
        setQueries(sortedQueries);
        setSortAppNr(sortAppNr === 'asc' ? 'desc' : 'asc');
        setSortRequestTime('none');
    };

    const sortQueriesByRequestTime = () => {
        const sortedQueries = [...queries];
        sortedQueries.sort((a, b) => {
            const dateA = a.requestTime ? new Date(a.requestTime) : new Date(0); // Handle undefined
            const dateB = b.requestTime ? new Date(b.requestTime) : new Date(0); // Handle undefined
            if (sortRequestTime === 'asc' || sortRequestTime === 'none') {
                return dateA.getTime() - dateB.getTime();
            } else {
                return dateB.getTime() - dateA.getTime();
            }
        });
        setQueries(sortedQueries);
        setSortRequestTime(sortRequestTime === 'asc' ? 'desc' : 'asc');
        setSortAppNr('none');
    };

    const AscIcon = () => (
        <svg className="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
            <path d="M9.207 1A2 2 0 0 0 6.38 1L.793 6.586A2 2 0 0 0 2.207 10H13.38a2 2 0 0 0 1.414-3.414L9.207 1Z" />
        </svg>
    );

    const DescIcon = () => (
        <svg className="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
            <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z" />
        </svg>
    );


    return (
        <div>
            {
                successMessage &&
                <SuccessAlert
                    successMessage={successMessage}
                    clearMessages={clearMessages}
                />
            }
            {
                errorMessage &&
                <ErrorAlert
                    errorMessage={errorMessage}
                    clearMessages={clearMessages}
                />
            }
            <div className="overflow-x-auto my-shadow-sharp rounded-lg">

                <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider cursor-pointer"
                                onClick={sortQueriesByAppNr}>

                                Application Nr

                                {sortAppNr === 'asc' ? <AscIcon /> : sortAppNr === 'desc' ? <DescIcon /> : null}
                            </th>
                            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider cursor-pointer"
                                onClick={sortQueriesByRequestTime}>
                                Last Requested
                                {sortRequestTime === 'asc' ? <AscIcon /> : sortRequestTime === 'desc' ? <DescIcon /> : null}
                            </th>
                            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
                                queryRef
                            </th>
                            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
                                application count
                            </th>
                            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
                                total items
                            </th>
                            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
                                Categories
                            </th>
                            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
                                Re-Run
                            </th>
                            <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
                                Delete
                            </th>
                        </tr>
                    </thead>
                    {isLoading ?

                        <tbody>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td className="px-5 py-5 text-sm">
                                    <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                </td>
                                <td className="px-5 py-5 text-sm">
                                    <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                </td>
                                <td className="px-5 py-5 text-sm">
                                    <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                </td>
                                <td className="px-5 py-5 text-sm">
                                    <span className="sr-only">Deleting...</span>
                                </td>
                            </tr>
                        </tbody>
                        :
                        <tbody>
                            {queries.map((query, index) => (

                                <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <td className="px-5 py-5 text-sm">
                                        {query.applicationNr}
                                    </td>
                                    <td className="px-5 py-5 text-sm">
                                        {formatDateTime(query.requestTime || '')}
                                    </td>
                                    <td className="px-5 py-5 text-sm">
                                        {query.queryRef}
                                    </td>
                                    <td className="px-5 py-5 text-sm">
                                        {query.rowCount}
                                    </td>
                                    <td className="px-5 py-5 text-sm">
                                        {query.totalItemcount}
                                    </td>
                                    <td className="px-6 py-4">
                                        {query.categoryNames.map((categoryName, index) => (
                                            <span key={index} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                {categoryName}
                                            </span>
                                        ))}

                                    </td>
                                    <td className="px-6 py-4">
                                        <Button
                                            size="md"
                                            type="submit"
                                            gradientDuoTone="purpleToBlue"
                                        >
                                            <Link to={`/power-dashboard/${encodeURIComponent(query.applicationNr || "")}`}>
                                                <span className="whitespace-nowrap">
                                                    Family Tree
                                                </span>
                                            </Link>
                                        </Button>
                                        {query.applicationNr?.startsWith('EP') &&
                                            <>
                                                <div className='mt-2'></div>
                                                <Button
                                                    size="md"
                                                    type="submit"
                                                    gradientDuoTone="pinkToOrange"
                                                >
                                                    <Link to={`/display/${query.applicationNr}`}>
                                                        <span className="whitespace-nowrap">
                                                            EP Register
                                                        </span>
                                                    </Link>
                                                </Button>
                                            </>
                                        }
                                    </td>
                                    <td className="px-6 py-4">
                                        <Button color="failure" size="sm"
                                            gradient="failure"
                                            className="mr-2"
                                            onClick={() => handleDelete(query.id || '')}
                                        >
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    }
                </table>
            </div>
        </div>
    );
};

export default PastEpRegisterQueries;
