// src/App.tsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './Layout';
import ProtectedRoute from './routes/ProtectedRoute';
import routes from './routes/routes';
import NotFoundPage from './components/NotFoundPage';
import { VersionInfoProvider } from './contexts/VersionInfoContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe("pk_test_51NV4i5HmsybXojbN3Hlcavxfde7EHvC8tHM7nWCtyiAe5I9vsEEXWGyu72YxJk8j6PsEfcHYZbUSjk0405jlONdL00NklzVvrm");

const App: React.FC = () => {
    return (
        <VersionInfoProvider>
            <Elements stripe={stripePromise}>
                <AuthProvider>
                    <Routes>
                        {routes.map((route, index) => {
                            // Check if the route is for the login or register page
                            const isLoginOrRegisterRoute = route.path
                                === '/login'
                                || route.path === '/register'
                                || route.path === '/forgot-password'
                                || route.path === '/reset-password-email-sent'
                                || route.path === '/account/confirm'
                                || route.path === '/account/reset'
                                || route.path === '/registration-received'
                                || route.path === '/family-tree-search-iframe'
                                || route.path === '/epo-calendar-iframe';

                            // Render the route with or without the Layout based on the check
                            const RouteComponent = route.protected ? (
                                <ProtectedRoute>{React.createElement(route.component)}</ProtectedRoute>
                            ) : (
                                React.createElement(route.component)
                            );

                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={isLoginOrRegisterRoute ? RouteComponent : <Layout>{RouteComponent}</Layout>}
                                />
                            );
                        })}
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </AuthProvider>
            </Elements>
        </VersionInfoProvider>
    );
};

export default App;