import React, { useState, useMemo } from 'react';
import useRootLinesData from '../hooks/useRootLinesData';
import { SingleRow } from '../../models/FamilyTreeInterfaces';
import RootLineModal from './RootLineModal';
import { LineData } from '../../models/PowerDashboardInterfaces';

interface RootLinesSVGProps {
  data: SingleRow[];
}

const RootLinesSVG: React.FC<RootLinesSVGProps> = ({ data }) => {
  const linesToDraw = useRootLinesData(data); // data needs to be passed to this component
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLineData, setSelectedLineData] = useState<LineData | null>(null);
  const openModal = (lineData: LineData) => {
    setSelectedLineData(lineData);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);
  // Define the arrowhead marker
  const arrowheadMarkerRoot = useMemo(() => (
    <marker
      color='orange'
      id="arrowheadRoot"
      markerWidth="10"
      markerHeight="10"
      refX="-5"
      refY="3.5"
      orient="auto" // Adjust the orientation to reverse the arrow
      markerUnits="strokeWidth"
    >
      <polygon points="0 0, 10 3.5, 0 7" fill="orange" />
    </marker>
  ), []);

  return (
    <>
      <svg style={{ position: 'absolute', top: 20, left: 0, overflow: 'visible', color: 'orange' }}>
        {arrowheadMarkerRoot} Add the arrowhead marker to the SVG
        {linesToDraw.map((line, index) => {
          const lineData: LineData = {
            fromItem: {
              reference: line.fromItem.fullReference,
              fullReference: line.fromItem.reference,
              kind: line.fromItem.kind,
              originalReference: line.fromItem.originalReference,
              type: line.fromItem.type,
              id: line.fromItem.id,
              country: line.fromItem.country,
              isQuery: line.fromItem.isQuery,
              isRepresentative: line.fromItem.isRepresentative,
              date: line.fromItem.date,
              plainLanguageType: line.fromItem.plainLanguageType,
              countryName: line.fromItem.countryName
            },
            toItem: {
              reference: line.toItem.fullReference,
              fullReference: line.toItem.reference,
              kind: line.toItem.kind,
              originalReference: line.toItem.originalReference,
              type: line.toItem.type,
              id: line.toItem.id,
              country: line.toItem.country,
              isQuery: line.toItem.isQuery,
              isRepresentative: line.toItem.isRepresentative,
              date: line.toItem.date,
              plainLanguageType: line.toItem.plainLanguageType,
              countryName: line.toItem.countryName
            }
          };

          // Calculate control points for the Bézier curve
          const midX = (line.from.x + line.to.x) / 3;
          const controlPointX1 = (midX + line.from.x) / 3;
          const controlPointX2 = (midX + line.to.x) / 3;

          // Calculate text position (adjust these values as needed)
          const textX = line.from.x - 80;
          const textY = line.from.y + 12; // Adjust the vertical position of the text

          // Define the path for the Bézier curve with arrowhead marker
          const pathD = `M ${line.from.x} ${line.from.y} C ${controlPointX1} ${line.from.y}, ${controlPointX2} ${line.to.y}, ${line.to.x} ${line.to.y}`;

          return (
            <g key={index}
              className='opacity-30 hover:opacity-100 transition duration-300 ease-in-out cursor-pointer pointer-events-auto'

            >
              {/* Cursor-effective width */}
              <path
                onClick={() => openModal(lineData)}
                d={pathD}
                stroke="transparent" // Transparent color for cursor-effective area
                strokeWidth="20" // Adjust this value to increase the cursor-effective width
                fill="none"
                opacity="50"
              />
              {/* Visible line */}
              <path
                onClick={() => openModal(lineData)}
                d={pathD}
                stroke="orange" // Line color
                strokeWidth="2" // Visual thickness of the line
                fill="none"
                markerStart="url(#arrowheadRoot)"
              />
              <text x={textX} y={textY} textAnchor="middle" fill="orange" fontSize="12"
              >
                stems from
              </text>
            </g>
          );
        })}
      </svg>
      <RootLineModal
        isOpen={isModalOpen}
        onClose={closeModal}
        lineData={selectedLineData || undefined}
      />
    </>
  );
};

export default RootLinesSVG;