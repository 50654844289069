//src/blog/components/SideBar.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../routes/RoutePaths';
import LatestThreeForSideBar from './LatestThreeForSideBar';


const SideBar: React.FC = () => {

  return (
    <aside className="hidden md:block" aria-labelledby="sidebar-label">
      <div className="xl:w-[336px] sticky top-6">
        <h3 id="sidebar-label" className="sr-only">Sidebar</h3>
        <div className="mb-8">
          <h4 className="mb-2 text-sm font-bold text-gray-900 dark:text-white uppercase">Patalytica News morning headlines</h4>
          <p className="mb-4 text-sm text-gray-500 dark:text-gray-400">Get all the updates to our Patalytica Power Dashboard to your inbox</p>
          <button title="default" type="button" data-modal-target="newsletter-modal" data-modal-toggle="newsletter-modal" className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 text-center w-full">Subscribe</button>
        </div>
        <div className="mb-12">
          <h4 className="mb-4 text-sm font-bold text-gray-900 dark:text-white uppercase">Latest blogs</h4>
          <LatestThreeForSideBar />
        </div>
        <div>
          <Link title="default" to={RoutePaths.Home} className="flex justify-center items-center mb-3 w-full h-48 bg-gray-100 rounded-lg dark:bg-gray-700">
            <svg aria-hidden="true" className="w-8 h-8 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd"></path></svg>
          </Link>
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Students and Teachers, save up to 60% on their Patalytica subscription.</p>
          {/* <p className="text-xs text-gray-400 uppercase dark:text-gray-500">Ads placeholder</p> */}
        </div>
      </div>
    </aside>
  )
};

export default SideBar;