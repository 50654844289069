// src/components/navigation/NavDropdown.tsx
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Dropdown } from 'flowbite-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { RoutePaths } from '../../routes/RoutePaths';

const NavDropdown: React.FC = () => {
    const authContext = useAuthContext();
    if (!authContext) {
        alert('authContext is null');
        return null;
    }
    const { user, logout } = authContext;
    const isAdmin = user?.roles?.includes('admin') ?? false;
    const userInitials = user && user.firstName && user.lastName
        ? `${user.firstName[0]}${user.lastName[0]}`
        : undefined;
    useEffect(() => {
    }, [user]);
    return (

        user ? (
            <Dropdown
                className='z-30'
                arrowIcon={false}
                inline={true}
                label={
                    userInitials ? (
                        <Avatar
                            placeholderInitials={userInitials}
                            size="md"
                            rounded={true}
                            bordered={true}
                            status="online"
                            statusPosition="top-right"
                            img={user.userImageUrl}
                        />
                    ) : (
                        <div className="relative">
                            <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                                <svg className="absolute w-12 h-12 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd">
                                    </path >
                                </svg >
                            </div >
                            <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                        </div >
                    )
                }
            >
                <Dropdown.Header>
                    {user.firstName && `${user.firstName} `}
                    {user.lastName && user.lastName}
                    <span className="block font-medium truncate">{user.email}</span>
                </Dropdown.Header>
                <Dropdown.Item className='py-0 px-0'>
                    <Link to={RoutePaths.Dashboard} className='w-full text-start py-2 px-4'>Dashboard</Link>
                </Dropdown.Item>
                <Dropdown.Item className='py-0 px-0'>
                    <Link to={RoutePaths.Settings} className='w-full text-start py-2 px-4'>Settings</Link>
                </Dropdown.Item>
                <Dropdown.Item className='py-0 px-0'>
                    <Link to={RoutePaths.MyQueries} className='w-full text-start py-2 px-4'>My Queries</Link>
                </Dropdown.Item>
                <Dropdown.Item className='py-0 px-0'>
                    <Link to={RoutePaths.Categories} className='w-full text-start py-2 px-4'>Categories</Link>
                </Dropdown.Item>
                <Dropdown.Item className='py-0 px-0'>
                    <Link to={RoutePaths.QueryCategoryAssignments} className='w-full text-start py-2 px-4'>Assign Categories</Link>
                </Dropdown.Item>
                <Dropdown.Item className='py-0 px-0'>
                    <Link to={RoutePaths.Billing} className='w-full text-start py-2 px-4'>Billing</Link>
                </Dropdown.Item>
                <Dropdown.Item className='py-0 px-0'>
                    <Link to={RoutePaths.Support} className='w-full text-start py-2 px-4'>Support</Link>
                </Dropdown.Item>
                <Dropdown.Item className='py-0 px-0'>
                    <Link to={RoutePaths.Team} className='w-full text-start py-2 px-4'>Team</Link>
                </Dropdown.Item>
                {isAdmin && (
                    <Dropdown.Item className='py-0 px-0'>
                        <Link to={RoutePaths.Admin} className='w-full text-start py-2 px-4'>Admin</Link>
                    </Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
            </Dropdown >
        ) : (
            <Link to="/login" className="no-underline">
                <Button
                    size="md"
                    className="my-shadow-sharp uppercase"
                    gradientDuoTone="purpleToBlue"
                >

                    <span>go to user area</span>
                </Button>
            </Link>
        )

    );
}

export default NavDropdown;