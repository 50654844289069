// utils/imageUtils.js or a similar file

// Function to convert ArrayBuffer to Base64
 const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

// Function to convert Byte Array to Base64
  // Function to convert Byte Array to Base64
  const byteArrayToBase64 = (byteArray: number[]): string => {
    const uint8Array = new Uint8Array(byteArray);
    return arrayBufferToBase64(uint8Array.buffer);
  };


// Function to convert image data to base64 string
export const convertImageToBase64 = (imageData: string) => {
  if (typeof imageData === 'string') {
    return imageData.startsWith('data:image/') ? imageData : `data:image/jpeg;base64,${imageData}`;
  } else {
    return `data:image/jpeg;base64,${byteArrayToBase64(imageData)}`;
  }
};