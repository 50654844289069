// src/components/RegistrationReceived.tsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RoutePaths } from '../routes/RoutePaths';

const RegistrationSuccess: React.FC = () => {
    const location = useLocation();
    const message = location.state?.message as string | undefined;
    const email = location.state?.email as string | undefined;

    return (
        <div className="flex flex-col justify-center items-center py-8 px-6 mx-auto md:h-screen">
            <Link to={RoutePaths.Home} className="flex justify-center items-center mb-8 text-3xl font-semibold lg:mb-10 dark:text-white">
                <span className="ml-3">Patalytica</span>
            </Link>
            <div className="justify-center items-center w-full bg-white rounded-lg shadow lg:flex md:mt-0 lg:max-w-screen-sm xl:p-0 dark:bg-gray-800 my-shadow-sharp rounded-lg">
                <div className="p-6 w-full sm:p-8 lg:p-10">
                    <h1 className="mb-3 text-2xl font-bold text-gray-900 lg:text-3xl dark:text-white">
                        Registration received
                    </h1>
                    {
                        message && <p>{message}</p>}
                    {email &&
                        <p className="mb-3 text-gray-500 dark:text-gray-400">
                            Thank you for your registration. An email has been sent to <span className="font-bold text-purple-700 dark:text-purple-500">{email}</span>. Please confirm your email to complete your registration.
                        </p>
                    }
                </div>
            </div>
        </div>
    );
};

export default RegistrationSuccess;
