import React from 'react';

interface PublicationReferenceProps {
  referenceCountry: string;
  referenceDoc: string;
  referenceDateFormatted?: string;
  referenceDate?: string;
  referenceChangeGazetteNumber?: string;
  referenceKind?: string;
  referenceExplanation?: string;
  referenceLanguage?: string;
}

const PublicationReference: React.FC<PublicationReferenceProps> = ({
  referenceCountry,
  referenceDoc,
  referenceDateFormatted,
  referenceDate,
  referenceChangeGazetteNumber,
  referenceKind,
  referenceExplanation,
  referenceLanguage
}) => {
  const epoPdfUrl = `https://data.epo.org/publication-server/pdf-document?pn=${referenceDoc}&ki=${referenceKind}&cc=${referenceCountry}&pd=${referenceDate?.toString()}`;
  let flagSrc = '';
  let flagTitle = referenceCountry; // Set the title to the reference country

  if (referenceCountry === "EP") {
    flagSrc = "https://plevanltd.com/images/light/EPO.webp"; // EP flag
  } else if (referenceCountry === "WO") {
    flagSrc = "https://plevanltd.com/images/light/uno-wipo.webp"; // WO flag
  } else {
    flagSrc = `https://flagcdn.com/w40/${referenceCountry.toLowerCase()}.webp`; // Other country flag
  }

  return (
    <div className="relative mx-auto max-w-screen-sm overflow-x-auto rounded shadow-md shadow-gray-500/50 dark:shadow-none mb-8">
      <table className="w-full text-xs lg:text-base text-left ">
        <thead className="text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-white">
          <tr>
            <th scope="col" className="px-2 sm:px-6 py-3 w-1/3">
              {referenceCountry}
              {referenceDoc}
              {referenceKind}
            </th>
            <th scope="col" className="pr-2 sm:pr-6 py-3 w-2/3">
              <div className="flex items-center justify-start">
                <img id="flagDisplay" className="h-4" src={flagSrc} alt={flagTitle} title={flagTitle} />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {referenceCountry === "EP" &&
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
                <p className="text-gray-700 dark:text-white font-normal">PDF from EPO server:</p>
              </th>
              <td className="pr-2 sm:pr-6 w-2/3">
                <p className="dark:text-white font-normal">
                  <a className="font-medium underline underline-offset-2 text-blue-600 dark:text-blue-500 hover:no-underline"
                    href={epoPdfUrl}>{referenceCountry}{referenceDoc}{referenceKind}
                  </a>
                </p>
              </td>
            </tr>
          }
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
              <p className="text-gray-700 dark:text-white font-normal">Content:</p>
            </th>
            <td className="pr-2 sm:pr-6 w-2/3">
              <p className="dark:text-white font-normal">
                {referenceExplanation}
              </p>
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
              <p className="text-gray-700 dark:text-white font-normal">Country:</p>
            </th>
            <td className="pr-2 sm:pr-6 w-2/3">
              <p className="dark:text-white font-normal">{referenceCountry}</p>
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
              <p className="text-gray-700 dark:text-white font-normal">Publication No:</p>
            </th>
            <td className="pr-2 sm:pr-6 w-2/3">
              <p className="dark:text-white font-normal">{referenceDoc}</p>
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
              <p className="text-gray-700 dark:text-white font-normal">Type:</p>
            </th>
            <td className="pr-2 sm:pr-6 w-2/3">
              <p className="dark:text-white font-normal">{referenceKind}</p>
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
              <p className="text-gray-700 dark:text-white font-normal">Publication date:</p>
            </th>
            <td className="pr-2 sm:pr-6 w-2/3">
              <p className="dark:text-white font-normal">{referenceDateFormatted}</p>
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
              <p className="text-gray-700 dark:text-white font-normal">Publication language:</p>
            </th>
            <td className="pr-2 sm:pr-6 w-2/3">
              <p className="dark:text-white font-normal">{referenceLanguage?.toUpperCase()}</p>
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
              <p className="text-gray-700 dark:text-white font-normal">Gazette:</p>
            </th>
            <td className="pr-2 sm:pr-6 w-2/3">
              <p className="dark:text-white font-normal">{referenceChangeGazetteNumber}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PublicationReference;