import React, { useState } from 'react';

interface FlagProps {
  flagSrc: string;
  flagTitle: string;
}

const Flag: React.FC<FlagProps> = ({ flagSrc, flagTitle }) => {
  const [imgError, setImgError] = useState(false);

  return (
    <div className='w-8'>
      {flagSrc && !imgError ? (
        <img
          className="h-4 my-shadow-sharp"
          src={flagSrc}
          alt={flagTitle}
          title={flagTitle}
          onError={() => setImgError(true)}
        />
      ) : (
        <svg className="w-4 h-4 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1v18M1 3.652v9c5.6-5.223 8.4 2.49 14-.08v-9c-5.6 2.57-8.4-5.143-14 .08Z" />
        </svg>
      )}
      {imgError && <span className="sr-only">{flagTitle}</span>}
    </div>
  );
};

export default Flag;
