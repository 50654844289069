// src/services/userService.ts
import { axiosWithInterceptor} from './axiosConfig';
import axios from 'axios';
import { ContextUser } from '../types/types';
import config from '../config/config';
import { authService } from './authService'; // Import authService

const { SERVER_BASE_URL, SERVER_API_USER, SERVER_API_CONTACT } = config;

interface UpdatePasswordData {
    currentPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
}

interface DeleteAccountData {
    password: string;  
}

export interface UserForRoleAssignmentData {
    userId: string;
    userName: string;
}

export interface FetchUserData {
    userId: string;
}

const fetchUserData = async (fetchUserData: FetchUserData): Promise<ContextUser> => {
    try {
        const response = await axiosWithInterceptor.get<ContextUser>(`${SERVER_BASE_URL}${SERVER_API_USER}details`, {
            params: { userId: fetchUserData.userId }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Failed to fetch user data');
        }
        throw error;
    }
};


const uploadUserImage = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axiosWithInterceptor.post<string>(`${SERVER_BASE_URL}${SERVER_API_USER}uploadUserImage`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            // Extract error message from server response
            const serverMessage = error.response?.data?.message || error.response?.data;
            throw new Error(serverMessage || 'Failed to upload image');
        }
        throw error;
    }
};

const deleteUserImage = async (): Promise<string> => {
    try {
        const response = await axiosWithInterceptor.delete<string>(`${SERVER_BASE_URL}${SERVER_API_USER}deleteUserImage`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            // Extract error message from server response
            const serverMessage = error.response?.data?.message || error.response?.data;
            throw new Error(serverMessage || 'Failed to delete image');
        }
        throw error;
    }
};


const fetchAllUsers = async () => {
    try {
        const response = await axiosWithInterceptor.get(`${SERVER_BASE_URL}${SERVER_API_USER}allUsers`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Fetching all users failed');
        }
        throw error;
    }
};

// Method to update the current user's password
const updatePassword = async (updatePasswordData: UpdatePasswordData) => {
    try {
        const response = await axiosWithInterceptor.put(`${SERVER_BASE_URL}${SERVER_API_USER}updatePassword`, updatePasswordData);        
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Updating password failed');
        }
        throw error;
    }
};


// Method to delete the current user's account
const deleteAccount = async (deleteAccountData: DeleteAccountData) => {
    try {
        const response = await axiosWithInterceptor({
            method: 'delete',
            url: `${SERVER_BASE_URL}${SERVER_API_USER}deleteUser`,
            data: deleteAccountData
        });
        authService.logout();
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Account deletion failed');
        }
        throw error;
    }
};

export interface UserProfileDto {
    firstName: string;
    lastName: string;
    userCountry: string;
    userOrganization: string;
    userRoleInUserOrganization: string;
}

const getUserProfile = async (): Promise<UserProfileDto> => {
    try {
        const response = await axiosWithInterceptor.get(`${SERVER_BASE_URL}${SERVER_API_USER}getUserProfile`);
        return response.data; // Returns user profile data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Fetching profile failed');
        }
        throw error;
    }
};

export default getUserProfile;

const updateProfile = async (updateProfileData: UserProfileDto): Promise<UserProfileDto> => {
    try {
        const response = await axiosWithInterceptor.put(`${SERVER_BASE_URL}${SERVER_API_USER}updateProfile`, updateProfileData);
        return response.data; // Returns updated user data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Updating profile failed');
        }
        throw error;
    }
};

export interface EmailNotificationsDto {
    wantNewsletter: boolean;
    wantProductUpdates: boolean;
}

const updateEmailNotifications = async (updateEmailNotificationsData: EmailNotificationsDto): Promise<EmailNotificationsDto> => {
    try {
        const response = await axiosWithInterceptor.put(`${SERVER_BASE_URL}${SERVER_API_USER}updateEmailNotifications`, updateEmailNotificationsData);
        return response.data; // Returns updated user data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Updating email notifications failed');
        }
        throw error;
    }
};

const getEmailNotifications = async (): Promise<EmailNotificationsDto> => {
    try {
        const response = await axiosWithInterceptor.get(`${SERVER_BASE_URL}${SERVER_API_USER}getEmailNotifications`);
        return response.data; // Returns email notifications data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Fetching email notifications failed');
        }
        throw error;
    }
};


const getUserImageUrl = async (): Promise<string> => {
    try {
        const response = await axiosWithInterceptor.get(`${SERVER_BASE_URL}${SERVER_API_USER}getUserImageUrl`);
        return response.data; // Returns user image url
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Fetching user image url failed');
        }
        throw error;
    }
}

export { getEmailNotifications };

export interface ContactUsUserDto {
    firstName: string;
    lastName: string;
    email: string;
    subject: string;
    message: string;
}

const contactUs = async (contactUsData: ContactUsUserDto): Promise<ContactUsUserDto> => {
    try {
        const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_CONTACT}contactus`, contactUsData);
        return response.data; 
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Contact us failed');
        }
        throw error;
    }
};

export const userService = {
    fetchUserData,
    deleteAccount,
    updatePassword,
    fetchAllUsers,
    getUserProfile,
    updateProfile,
    updateEmailNotifications,
    getEmailNotifications,
    contactUs,
    uploadUserImage,
    deleteUserImage,
    getUserImageUrl
};