import React from 'react';
import PastEpRegisterQueries from './PastEpRegisterQueries';
import EpRegisterSearchMini from '../../../business/components/EpRegisterSearchMini';
import FamilyTreeSearchMini from '../../../business/components/FamilyTreeSearchMini';

const MyQueries: React.FC = () => {
    return (
        <div className="px-4 my-10 mx-auto max-w-8xl lg:px-4 dark:bg-black">
            <div className="mb-8">
                <h1 className="inline-block text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                    My queries
                </h1>
                <p className="mt-1 text-lg text-gray-500 dark:text-gray-400">
                    See, re-run, and delete your past queries
                </p>
                <div>
                    <h2 className="mt-8 mb-4 text-xl font-bold text-gray-900 dark:text-white">
                        Past Register queries
                    </h2>
                    <PastEpRegisterQueries />
                    <div className="pt-8 grid gap-6 lg:grid-cols-2">
                        <div>
                            <EpRegisterSearchMini />
                            <br />
                            <FamilyTreeSearchMini />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyQueries;
