// src/components/Register.tsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';
import { Checkbox, TextInput, Button } from 'flowbite-react';
import ErrorAlert from '../htmlBlocks/ErrorAlert';
import CustomLabel from '../themes/CustomLabel';
import { RoutePaths } from '../routes/RoutePaths';

const Register: React.FC = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const clearMessages = () => {
        setErrorMessage('');
    };
    const handleRegister = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        setErrorMessage('');

        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }

        try {
            const response = await authService.register({ firstName, lastName, email, password, confirmPassword, acceptTerms });
            navigate('/registration-received', { state: { message: response.message, email: email } });
        } catch (error) {
            let message = 'Failed to register';
            if (typeof error === 'object' && error !== null) {
                message = (error as { message: string }).message;
            }
            setErrorMessage(message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col justify-center items-center py-8 px-6 mx-auto md:h-screen dark:bg-black">
            <Link to={RoutePaths.Home} className="flex justify-center items-center mb-8 text-3xl font-semibold lg:mb-10 dark:text-white">
                <span className="ml-3">Patalytica</span>
            </Link>
            <div className="justify-center items-center w-full bg-white rounded-lg shadow lg:flex md:mt-0 lg:max-w-screen-sm xl:p-0 dark:bg-gray-800  my-shadow-sharp rounded-lg">
                <div className="p-6 w-full sm:p-8 lg:p-10">
                    {
                        errorMessage &&
                        <ErrorAlert
                            errorMessage={errorMessage}
                            clearMessages={clearMessages}
                        />
                    }
                    <h1 className="mb-3 text-2xl font-bold text-gray-900 lg:text-3xl dark:text-white">
                        Create an account
                    </h1>
                    <p className="mb-3 text-gray-500 dark:text-gray-400">
                        Access hundreds of IP analytics tools and systems
                    </p>
                    <form onSubmit={handleRegister} className="mt-8">
                        <div className="flex flex-col mb-6 space-y-6 md:flex-row md:space-y-0 md:space-x-6">
                            <div className="w-full">
                                <CustomLabel htmlFor="firstName">
                                    First name
                                </CustomLabel>
                                <TextInput
                                    required
                                    placeholder="John"
                                    value={firstName}
                                    name={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    type="text"
                                    autoComplete='given-name'
                                />
                            </div>
                            <div className="w-full">
                                <CustomLabel htmlFor="lastName">
                                    Last name
                                </CustomLabel>
                                <TextInput
                                    required
                                    placeholder="Doe"
                                    value={lastName}
                                    name={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    type="text"
                                    autoComplete='family-name'
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <CustomLabel htmlFor="email">
                                Your email
                            </CustomLabel>
                            <TextInput
                                id="email"
                                type="text"
                                value={email}
                                name={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="name@company.com"
                                required
                                autoComplete='email'
                            />
                        </div>
                        <div className="mb-6">
                            <CustomLabel htmlFor="password">
                                Your password
                            </CustomLabel>
                            <TextInput
                                id="password"
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder=""
                                required
                                autoComplete='new-password'
                            />
                        </div>
                        <div className="mb-6">
                            <CustomLabel htmlFor="confirmPassword">
                                Confirm password
                            </CustomLabel>
                            <TextInput
                                id="confirmPassword"
                                type="password"
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder=""
                                required
                                autoComplete='new-password'
                            />
                        </div>
                        <div className="flex items-start mb-6">
                            <div className="flex items-center h-5">
                                <Checkbox
                                    required
                                    id="acceptTerms"
                                    aria-describedby="acceptTerms"
                                    name="acceptTerms"
                                    checked={acceptTerms}
                                    onChange={(e) => setAcceptTerms(e.target.checked)}
                                    className='checked:bg-purple-600 checked:border-transparent dark:checked:bg-purple-500 focus:ring-purple-500 dark:focus:ring-purple-500'
                                />
                            </div>
                            <div className="ml-3 text-sm">
                                <CustomLabel htmlFor="acceptTerms">
                                    I accept the<span> </span>
                                    <Link className="ml-auto text-sm text-purple-700 dark:text-purple-500 hover:underline" to={RoutePaths.Home}>
                                        Terms and Conditions
                                    </Link>
                                </CustomLabel>
                            </div>
                        </div>
                        <Button color="purple" size="lg" type="submit" className="mb-6">
                            <span className="flex justify-center items-center">
                                {isLoading ? 'Attempting to create account...' : 'Create account'}
                            </span>
                        </Button>
                        <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
                            Already have an account?
                            <Link to={RoutePaths.Login} className="ml-1 text-purple-700 hover:underline dark:text-purple-500">
                                Login here.
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Register;
