import React from 'react';
interface RepresentativeProps {
  index: number;
  totalRepresentatives: number;
  representativeName: string;
  representativeAddress1: string;
  representativeAddress2?: string;
  representativeAddress3?: string;
  representativeAddress4?: string;
  representativeAddress5?: string;
  representativeCountry: string;
}

const Representative: React.FC<RepresentativeProps> = ({
  index,
  totalRepresentatives,
  representativeName,
  representativeAddress1,
  representativeAddress2,
  representativeAddress3,
  representativeAddress4,
  representativeAddress5,
  representativeCountry,
}) => {

  let flagSrc = '';
  let flagTitle = representativeCountry; // Set the title to the reference country

  if (representativeCountry === "EP") {
    flagSrc = "https://plevanltd.com/images/light/EPO.webp"; // EP flag
  } else if (representativeCountry === "WO") {
    flagSrc = "https://plevanltd.com/images/light/uno-wipo.webp"; // WO flag
  } else {
    flagSrc = `https://flagcdn.com/w40/${representativeCountry.toLowerCase()}.webp`; // Other country flag
  }

  return (
    <div>
      <div className="relative mx-auto max-w-screen-sm overflow-x-auto rounded shadow-md shadow-gray-500/50 dark:shadow-none mb-8">
        <table className="w-full text-xs lg:text-base text-left ">
          <thead className="text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-white">
            <tr>
              <th scope="col" className="px-2 sm:px-6 py-3 w-1/3">
                Representative {index}/{totalRepresentatives}
              </th>
              <th scope="col" className="pr-2 sm:pr-6 py-3 w-2/3">
                <div className="flex items-center justify-start">
                  <img id="flagDisplay" className="h-4" src={flagSrc} alt={flagTitle} title={flagTitle} />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
                <p className="text-gray-700 dark:text-white font-normal">Name:</p>
              </th>
              <td className="pr-2 sm:pr-6 w-2/3">
                <p className="dark:text-white font-normal">{representativeName}</p>
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
                <p className="text-gray-700 dark:text-white font-normal">Address:</p>
              </th>
              <td className="pr-2 sm:pr-6 w-2/3">
                <p className="dark:text-white font-normal">{representativeAddress1}</p>
                {representativeAddress2 &&
                  <p className="dark:text-white font-normal">{representativeAddress2}</p>
                }
                {representativeAddress3 &&
                  <p className="dark:text-white font-normal">{representativeAddress3}</p>
                }
                {representativeAddress4 &&
                  <p className="dark:text-white font-normal">{representativeAddress4}</p>
                }
                {representativeAddress5 !== representativeAddress4 &&
                  <p className="dark:text-white font-normal">{representativeAddress5}</p>
                }
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal">
                <p className="text-gray-700 dark:text-white font-normal">Country:</p>
              </th>
              <td className="pr-2 sm:pr-6">
                <p className="dark:text-white font-normal">{representativeCountry}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Representative;