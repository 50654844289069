//src/blog/components/RelatedBlogPosts.tsx
import React, { useEffect, useState } from 'react';
import { blogService, BlogPostPreviewDTO } from '../../services/blogService';
import config from '../../config/config';
import { Link } from 'react-router-dom';
import './../../../src/relatedBlogPosts.css';
import { RoutePaths } from '../../routes/RoutePaths';
import LoadingRelatedBlogPosts from './LoadingRelatedBlogPosts';

interface RelatedBlogPostsProps {
  categoryNames: string[];
}

const RelatedBlogPosts: React.FC<RelatedBlogPostsProps> = ({ categoryNames }) => {
  const [blogPosts, setBlogPosts] = useState<BlogPostPreviewDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { CLIENT_BASE_URL, CLIENT_BLOG_URL } = config;

  useEffect(() => {
    const loadBlogPosts = async () => {
      try {
        setLoading(true);
        const fetchedBlogPosts = await blogService.fetchRelevantBlogPosts(categoryNames);
        setBlogPosts(fetchedBlogPosts);
      } catch (err) {
        setError('Failed to load related BlogPosts');
      } finally {
        setLoading(false);
      }
    };

    loadBlogPosts();
  }, [categoryNames]); // Run effect when categoryNames changes

  if (loading) {
    return <LoadingRelatedBlogPosts />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const truncateString = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  return (
    <div>
      <aside aria-label="Related BlogPosts" className="z-20 pt-16 py-8 lg:py-24 bg-white dark:bg-gray-900 relative border-t dark:border-purple-600">
        <div className="px-4 mx-auto max-w-screen-xl">
          <h2 className="mb-6 lg:mb-8 text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">Related BlogPosts</h2>
          <div className="grid gap-12 sm:grid-cols-2">
            {blogPosts.map(blogPost => (
              <article className="flex flex-col xl:flex-row" key={blogPost.id}>
                <Link to={CLIENT_BASE_URL + CLIENT_BLOG_URL + blogPost.slug}>
                  <img src={blogPost.featuredMediaUrl}
                    className="sm:mr-5 max-w-sm my-shadow relatedBlogPostImg mb-6"
                    alt="Image 1"
                    loading="lazy"
                  />
                </Link>
                <div className="flex flex-col justify-top">
                  <h3 className="max-w-screen-xs sm:mb-4 text-lg md:text-lg font-bold leading-tight text-gray-900 dark:text-white">
                    <Link to={CLIENT_BASE_URL + CLIENT_BLOG_URL + blogPost.slug}>
                      {truncateString(blogPost.title, 55)}
                    </Link>
                  </h3>
                  <h4 className="mb-2 mt-2 sm:mb-4 font-medium text-lg font-normal dark:text-gray-300 leading-relaxed text-gray-500 dark:text-gray-400 max-w-sm ">
                    {truncateString(blogPost.excerpt, 30)}
                  </h4>
                  <p className="text-lg font-normal dark:text-gray-300 leading-relaxed text-gray-500 dark:text-gray-400 max-w-sm ">
                    {blogPost.categoryNames.map((categoryName, index) => (
                      <Link key={index} to={RoutePaths.ListOfSameCategoryBlogPosts.replace(':categoryName', categoryName)}>
                        <span className="ml-2 bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                          <svg className="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z">
                            </path>
                          </svg>
                          {categoryName}
                        </span>
                      </Link>
                    ))}
                  </p>
                </div>
              </article>
            ))}
          </div>
        </div>
      </aside>
    </div>
  )
};

export default RelatedBlogPosts;