//src/business/pages/components/DashboardHeaderSwitch.tsx
import React from 'react';
import { ToggleSwitch } from 'flowbite-react';

interface DashboardHeaderSwitchProps {
  checked: boolean;
  onChange: () => void;
}

const DashboardHeaderSwitch: React.FC<DashboardHeaderSwitchProps> = ({ checked, onChange }) => {
  return (
    <div className="flex items-center justify-center">
      <ToggleSwitch
        label="Query header"
        color="gray"
        id="DashboardHeaderSwitch"
        aria-describedby="DashboardHeaderSwitch"
        name="DashboardHeaderSwitch"
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
}

export default DashboardHeaderSwitch;
