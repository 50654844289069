// src/components/navigation/Newsletter.tsx
import React from 'react';
import { RoutePaths } from '../../routes/RoutePaths';
import { Link } from 'react-router-dom';

const Newsletter: React.FC
    = () => {
        return (
            <aside className="py-10 sm:py-16 bg-gray-50 lg:py-20 dark:bg-gray-800 border-t border-b border-gray-100 dark:border-gray-700">
                <div className="px-4 mx-auto max-w-8xl">
                    <h2 className="mb-4 text-2xl md:text-4xl font-bold tracking-tight text-gray-900 md:font-extrabold lg:leading-none dark:text-white md:text-center lg:mb-7">Sign up for our newsletter</h2>
                    <p className="mb-4 text-base text-gray-500 md:mb-6 dark:text-gray-400 md:text-center md:text-xl lg:px-20 xl:px-56">Do you want to get notified when a new tool is added to Patalytica? Sign up for our newsletter and you will be among the first to find out about new features, components, versions, and tools.</p>
                    <div className="mb-4">
                        <form className="seva-form formkit-form flex max-w-xl md:mx-auto" method="post" data-format="inline" data-version="5" min-width="400 500">
                            <div className="w-full" data-style="clean">
                                <label htmlFor="member_email" className="hidden">
                                    Email address
                                </label>
                                <div className="relative h-full">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                                            <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z">
                                            </path>
                                            <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z">
                                            </path>
                                        </svg>
                                    </div>
                                    <input type="email" id="member_email" name="email_address" className="block w-full px-3 py-4 pl-11 text-base text-gray-900 bg-white border border-gray-200 rounded-l-xl formkit-input focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your email" required />
                                </div>
                            </div>
                            <div>
                                <input type="submit" data-element="submit" id="member_submit" className="formkit-submit formkit-submit w-full px-4 py-4 text-base font-medium text-center text-white bg-blue-700 dark:bg-blue-600 dark:border-blue-600 border border-blue-700 cursor-pointer rounded-r-xl formkit-submit hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-blue-600 dark:hover:border-blue-700 dark:hover:bg-blue-700 dark:focus:ring-blue-800" value="Subscribe" />
                            </div>
                        </form>
                    </div>
                    <p className="text-sm text-gray-500 md:text-center dark:text-gray-400 font-normal">By subscribing, you agree with Patalytica's
                        <Link rel="nofollow noopener noreferrer" className="text-blue-600 dark-text-blue-400 hover:underline" to={RoutePaths.Home}> Terms of Service</Link> and<span> </span>
                        <Link className="text-blue-600 dark-text-blue-400 hover:underline" rel="nofollow noopener noreferrer" to="/">
                            Privacy Policy
                        </Link>.
                    </p>
                </div>
            </aside>

        );
    }

export default Newsletter;