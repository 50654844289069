// src/admin/components/CategoryList.tsx
import React, { useState, useEffect } from 'react';
import CategoryService, {
    CategoryDTO,
    getAllCategoryAssignmentsPerApplicationNumber,
    AllCategoryAssignmentsPerApplicationNumberDTO,
    categoryUnassignment,
    categoryAssignment,
    CategoryAssignmentByAppDTO
}
    from '../../../services/categoryService';
import SuccessAlert from '../../../htmlBlocks/SuccessAlert';
import ErrorAlert from '../../../htmlBlocks/ErrorAlert';
import { Button, Spinner } from 'flowbite-react';

interface CategoryListProps {
    applicationNumber: string;
}

const CategoryList: React.FC<CategoryListProps> = ({ applicationNumber }) => {
    const [categories, setCategories] = useState<CategoryDTO[]>([]);
    const [categoryAssignments, setCategoryAssignments] = useState<AllCategoryAssignmentsPerApplicationNumberDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true); // Type is inferred
    const [error, setError] = useState<string | null>(null); // Add an error state
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            setIsLoading(true);
            try {
                const categories = await CategoryService.getCategories();
                setCategories(categories);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message); // Now safely accessing message property
                } else {
                    setError('An unknown error occurred'); // Fallback error message
                }
            }
            setIsLoading(false);
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchAllCategoryAssignmentsPerApplicationNumber = async () => {
            setIsLoading(true);
            try {
                const categoryAssignments = await getAllCategoryAssignmentsPerApplicationNumber(applicationNumber);
                setCategoryAssignments(categoryAssignments);
            } catch (err) {
                if (err instanceof Error) {
                    // If no assignments exist, set an empty array instead of throwing an error
                    if (err.message.includes("404")) { // Check if the error is a 404
                        setCategoryAssignments([]); // Set categoryAssignments to an empty array
                    } else {
                        setError(err.message); // Handle other types of errors normally
                    }
                } else {
                    setError('An unknown error occurred');
                }
            }
            setIsLoading(false);
        };
        fetchAllCategoryAssignmentsPerApplicationNumber();
    }, [applicationNumber]);

    const handleButtonClick = async (categoryId: string) => {
        const isAssigned = categoryAssignments.some(assignment => assignment.categoryIds.includes(categoryId));

        if (isAssigned) {
            // Prepare DTO for unassignment
            const unassignmentData: CategoryAssignmentByAppDTO = {
                categoryId: categoryId,
                applicationNumber: applicationNumber
            };

            try {
                await categoryUnassignment(unassignmentData);
                setSuccessMessage('Category unassigned successfully');
                // Update the categoryAssignments state to reflect the unassignment
                const updatedAssignments = categoryAssignments.map(assignment => {
                    if (assignment.applicationNumber === applicationNumber) {
                        return {
                            ...assignment,
                            categoryIds: assignment.categoryIds.filter(id => id !== categoryId)
                        };
                    }
                    return assignment;
                });
                setCategoryAssignments(updatedAssignments);
            } catch (err: any) {
                setErrorMessage(err.message || 'An error occurred while unassigning the category');
            }
        } else {
            // Prepare DTO for assignment
            const assignmentData: CategoryAssignmentByAppDTO = {
                categoryId: categoryId,
                applicationNumber: applicationNumber
            };

            try {
                await categoryAssignment(assignmentData);
                setSuccessMessage('Category assigned successfully');

                // Update the categoryAssignments state to reflect the new assignment
                const existingAssignmentIndex = categoryAssignments.findIndex(assignment => assignment.applicationNumber === applicationNumber);
                if (existingAssignmentIndex !== -1) {
                    const updatedAssignment = { ...categoryAssignments[existingAssignmentIndex] };
                    updatedAssignment.categoryIds.push(categoryId);
                    setCategoryAssignments([
                        ...categoryAssignments.slice(0, existingAssignmentIndex),
                        updatedAssignment,
                        ...categoryAssignments.slice(existingAssignmentIndex + 1)
                    ]);
                } else {
                    setCategoryAssignments([...categoryAssignments, { applicationNumber: applicationNumber, categoryIds: [categoryId] }]);
                }
            } catch (err: any) {
                setErrorMessage(err.message || 'An error occurred while assigning the category');
            }
        }
    };

    if (error) {
        return <p>Error: {error}</p>;
    }
    // Function to clear messages
    const clearMessages = () => {
        setErrorMessage('');
        setSuccessMessage('');
    };
    return (
        <div className="justify-center items-center w-full bg-white rounded-lg lg:flex md:mt-0 xl:p-0 dark:bg-gray-800">
            <div className="p-6 w-full sm:p-8 lg:p-10">
                {
                    successMessage &&
                    <SuccessAlert
                        successMessage={successMessage}
                        clearMessages={clearMessages}
                    />
                }
                {
                    errorMessage &&
                    <ErrorAlert
                        errorMessage={errorMessage}
                        clearMessages={clearMessages}
                    />
                }

                {isLoading ?
                    <div role="status" className="flex justify-center">
                        <Spinner
                            color="purple"
                            size="large"
                        />
                        <span className="sr-only">Loading...</span>
                    </div>
                    :
                    <div className='justify-center flex'>
                        <div id="category-buttons" className="my-4 sm:flex mx-auto">
                            {categories.map((category) => {
                                const isAssigned = categoryAssignments.some(assignment => assignment.categoryIds.includes(category.id));
                                return (
                                    <Button
                                        size="xs"
                                        label={category.name}
                                        color="primary"
                                        key={category.id}
                                        onClick={() => handleButtonClick(category.id)}
                                        className={`text-white font-bold py-1.5 px-2 rounded-full m-1 whitespace-nowrap ${isAssigned ? "bg-blue-500 hover:bg-blue-700" : "bg-gray-500 hover:bg-gray-700"}`}
                                    >
                                        {category.name}
                                    </Button>
                                );
                            })}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CategoryList;
