// graphData.ts

import { SimulationNodeDatum } from 'd3-force';

export interface GraphNode extends SimulationNodeDatum {
  id: string;
  name: string;
  subname: string;
  fill: string;
}

export interface GraphLink {
  source: GraphNode | string;
  target: GraphNode | string;
}

export const graphNodes: GraphNode[] = [
  { id: "1", name: "NODE NAME 1", subname: "CODE N1", fill: "orange" },
  { id: "2.1", name: "NODE NAME 2.1", subname: "CODE N1", fill: "blue" },
  { id: "2.2", name: "NODE NAME 2.2", subname: "CODE N1", fill: "blue" },
  { id: "2.3", name: "NODE NAME 2.3", subname: "CODE N1", fill: "blue" },
  { id: "3.3", name: "NODE NAME 3.3", subname: "CODE N1", fill: "blue" },
  { id: "3.4", name: "NODE NAME 3.4", subname: "CODE N1", fill: "blue" },
  { id: "4.1", name: "NODE NAME 4.1", subname: "CODE N1", fill: "#d281d2" },
  { id: "4.2", name: "NODE NAME 4.2", subname: "CODE N1", fill: "#d281d2" }
];

export const graphLinks: GraphLink[] = [
  { source: "1", target: "2.1" },
  { source: "1", target: "2.2" },
  { source: "1", target: "2.3" },
  { source: "2.3", target: "3.3" },
  { source: "2.3", target: "3.4" },
  { source: "3.3", target: "4.1" },
  { source: "3.4", target: "4.2" },
  { source: "1", target: "3.4" }  // Additional relationship
];
