// src/components/dashboard/accountsettings/Settings.tsx
import React from 'react';
import UpdatePassword from './UpdatePassword';
import DeleteAccount from './DeleteAccount';
import UpdateUserProfile from './UpdateUserProfile';
import EmailNotifications from './EmailNotifications';
import UploadUserImage from './UploadUserImage';
import DeleteCurrentUserImage from './DeleteCurrentUserImage';

const Settings: React.FC = () => {
    return (
        <div className="px-4 my-10 mx-auto max-w-8xl lg:px-4">
            <div className="mb-8">
                <h1 className="inline-block text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                    Account settings
                </h1>
                <p className="mt-1 text-lg text-gray-500 dark:text-gray-400">
                    View and update details for your account.
                </p>
            </div>
            <div className="grid gap-6 lg:grid-cols-2">
                <UpdateUserProfile />
                <EmailNotifications />
                <UploadUserImage />
                <UpdatePassword />

            </div>
            <div className="mb-8 mt-16">
                <h1 className="inline-block text-3xl font-extrabold tracking-tight text-red-900 dark:text-red">
                    Danger zone
                </h1>
                <p className="mt-1 text-lg text-gray-500 dark:text-gray-400">
                    All user data will be permanently deleted. Including all comments, usage, images, etc.
                </p>
            </div>
            <div className="grid gap-6 lg:grid-cols-2">
                <DeleteCurrentUserImage />
                <DeleteAccount />
            </div>
        </div>

    );
};

export default Settings;