// src/admin/pages/Roles.tsx
import React, { useState } from 'react';
import AllRoles from '../components/AllRoles';
import CreateRole from '../components/CreateRole';

const Roles: React.FC = () => {
    const [roleCreated, setRoleCreated] = useState(false);

    const handleRoleCreation = () => {
        setRoleCreated(true);
    };
    return (
        <div>
            <section className="py-4 bg-white dark:bg-gray-900 lg:pt-12 lg:pb-16 dark:bg-black">
                <div className="px-4 mx-auto max-w-8xl lg:px-4 lg:text-center">

                    <h1 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 lg:font-extrabold lg:text-6xl lg:leading-none dark:text-white lg:text-center xl:px-36 lg:mb-7">
                        Danger-zone. Proceed with caution!
                    </h1>
                    <h2 className="mt-16 mb-4 text-2xl font-bold tracking-tight text-gray-900 lg:font-extrabold lg:text-4xl lg:leading-none dark:text-white lg:text-center xl:px-36 lg:mb-7">
                        Changes to roles will affect all users!
                    </h2>
                    <p className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 lg:text-center lg:text-xl xl:px-60">
                        Create, edit, and delete roles. This will affect all users. Deleting a role and creating it again will not restore the assignments of the deleted role, because assignments are stored on a role-id + user-id basis. Be careful!
                    </p>
                    <AllRoles roleCreated={roleCreated} setRoleCreated={setRoleCreated} />
                    <CreateRole onRoleCreate={handleRoleCreation} />
                </div>
            </section>
        </div>
    );
};

export default Roles;
