//src/services/commentService.ts

import { axiosBase, axiosWithInterceptor} from './axiosConfig';
import config from '../config/config';
import axios from 'axios';


const { 
  SERVER_BASE_URL, 
  SERVER_API_ALL_COMMENTS_PER_BLOG_POST_ID,
  SERVER_API_ONE_WP_COMMENT 
} = config;

export interface BlogPostCommentDTO {
  id: number;
  userName: string;
  parent: number | null;
  authorImageUrl: string;
  userId: string;
  date: Date;
  content: string;
}

export interface FrontEndBlogPostCommentDTO {
  blogPostId: number;
  parent: number;
  userId: string;
  content: string;
}


const fetchBlogPostComments = async (id: number) => {
  try {
    const response = await axiosBase.get<BlogPostCommentDTO[]>(`${SERVER_BASE_URL}${SERVER_API_ALL_COMMENTS_PER_BLOG_POST_ID}${id}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Fetching blog post comments failed');
    }
    throw error;
  }
};

const postBlogPostComment = async (frontEndComment: FrontEndBlogPostCommentDTO) => {
  try {
    const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_ONE_WP_COMMENT}`, frontEndComment);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Posting blog post comment failed');
    }
    throw error;
  }
};

const deleteBlogPostComment = async (id: number) => {
  try {
    const response = await axiosWithInterceptor.delete(`${SERVER_BASE_URL}${SERVER_API_ONE_WP_COMMENT}${id}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Deleting blog post comment failed');
    }
    throw error;
  }
}

export const commentService = {
  fetchBlogPostComments,
  postBlogPostComment,
  deleteBlogPostComment
};