import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import '../../FamilyTree.css';
import { type TreeNode, treeData } from '../models/treeData';

interface ExtendedHierarchyPointNode extends d3.HierarchyPointNode<TreeNode> {
  _children?: ExtendedHierarchyPointNode[];
  x0: number;
  y0: number;
}

const FamilyTree: React.FC = () => {
    const svgRef = useRef<SVGSVGElement | null>(null);
  
    useEffect(() => {
      const svgElement = svgRef.current;
      if (!svgElement) return;
  
      const margin = { top: 20, right: 90, bottom: 30, left: 90 },
        width = 960 - margin.left - margin.right,
        height = 500 - margin.top - margin.bottom;
  
      const svg = d3.select(svgElement)
        .attr("width", width + margin.right + margin.left)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
  
      const duration = 750;
      let root: ExtendedHierarchyPointNode;
  
      const treemap = d3.tree<TreeNode>().size([height, width]);
  
      root = d3.hierarchy(treeData) as ExtendedHierarchyPointNode;
      root.x0 = height / 2;
      root.y0 = 0;
  
      update(root);
  
      function update(source: ExtendedHierarchyPointNode) {
        const treeData = treemap(root);
  
        const nodes = treeData.descendants() as ExtendedHierarchyPointNode[],
          links = treeData.descendants().slice(1);
  
        nodes.forEach(d => { d.y = d.depth * 180 });
  
        const node = svg.selectAll<SVGGElement, ExtendedHierarchyPointNode>('g.node')
          .data(nodes, d => d.data.name + (d.depth || 0));
  
        const nodeEnter = node.enter().append('g')
          .attr('class', 'node')
          .attr("transform", () => `translate(${source.y0},${source.x0})`)
          .on('click', click);
  
        const rectHeight = 60, rectWidth = 120;
  
        nodeEnter.append('rect')
          .attr('class', 'node')
          .attr("width", rectWidth)
          .attr("height", rectHeight)
          .attr("x", 0)
          .attr("y", (rectHeight / 2) * -1)
          .attr("rx", "5")
          .style("fill", d => d.data.fill);
  
        nodeEnter.append('text')
          .attr("dy", "-.35em")
          .attr("x", 13)
          .attr("text-anchor", "start")
          .text(d => d.data.name)
          .append("tspan")
          .attr("dy", "1.75em")
          .attr("x", 13)
          .text(d => d.data.subname);
  
        const nodeUpdate = nodeEnter.merge(node);
  
        nodeUpdate.transition()
          .duration(duration)
          .attr("transform", d => `translate(${d.y},${d.x})`);
  
        node.exit().transition()
          .duration(duration)
          .attr("transform", _d => `translate(${source.y},${source.x})`)
          .remove();
  
        node.exit().select('text')
          .style('fill-opacity', 1e-6);
  
        const link = svg.selectAll<SVGPathElement, ExtendedHierarchyPointNode>('path.link')
          .data(links, d => d.data.name + (d.depth || 0));
  
        const linkEnter = link.enter().insert('path', "g")
          .attr("class", "link")
          .attr('d', () => {
            const o = { x: source.x0, y: source.y0 };
            return diagonal(o, o);
          });
  
        linkEnter.merge(link)
          .transition()
          .duration(duration)
          .attr('d', d => diagonal(d, d.parent as ExtendedHierarchyPointNode));
  
        link.exit().transition()
          .duration(duration)
          .attr('d', () => {
            const o = { x: source.x, y: source.y };
            return diagonal(o, o);
          })
          .remove();
  
        nodes.forEach(d => {
          d.x0 = d.x;
          d.y0 = d.y;
        });
  
        function diagonal(s: { x: number; y: number }, d: { x: number; y: number }) {
          const path = `M ${s.y} ${s.x}
                C ${(s.y + d.y) / 2} ${s.x},
                  ${(s.y + d.y) / 2} ${d.x},
                  ${d.y} ${d.x}`;
          return path;
        }
  
        function click(_event: any, d: ExtendedHierarchyPointNode) {
          if (d.children) {
            d._children = d.children;
            d.children = undefined;
          } else {
            d.children = d._children;
            d._children = undefined;
          }
          update(d);
        }
      }
    }, []);
  
    return (
      <svg ref={svgRef}></svg>
    );
  }
  
  export default FamilyTree;