//src/business/pages/components/RowItemComponent.tsx
import React, { useRef, useEffect, useState } from 'react';
import { usePositions } from '../contexts/PositionsContext';
import { RowItem, SingleRow } from "../../models/FamilyTreeInterfaces";
import { formatDate } from "../utils/formatUtils";
import useFlagLogic from "../hooks/useFlagLogic";
import RowItemModal from "./RowItemModal";
import Flag from "./Flag";

interface RowItemComponentProps {
  singleRow: SingleRow;
  item: RowItem;
  index: number;
  getPositionFromDate: (date: Date, startDate: Date, fixedMarkerDistance: number) => number;
  startDate: Date;
  fixedMarkerDistance: number;
  rowHeight: number;
  containerRef: React.RefObject<HTMLDivElement>;
  associatedApplicationRef: string;
}


const RowItemComponent: React.FC<RowItemComponentProps> = ({
  item,
  index,
  getPositionFromDate,
  startDate,
  fixedMarkerDistance,
  singleRow,
  rowHeight,
  containerRef,
  associatedApplicationRef
}) => {

  const ref = useRef<HTMLDivElement>(null);
  const { flagSrc, flagTitle } = useFlagLogic(item);
  const { handlePositionChange } = usePositions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const leftPosition = getPositionFromDate(new Date(item.date), startDate, fixedMarkerDistance);
  const maxLength = "United States".length;
  const maxTypeLength = "United States_____".length;

  function getItemClass(item: RowItem) {
    if (item.isQuery) {
      return "bg-purple-700 dark:bg-purple-900";
    }
    if (item.kind === "W") {
      return "bg-gray-700 dark:bg-gray-800";
    }
    if (item.type === "Application") {
      return "bg-gray-500 dark:bg-gray-700";
    }
    if (item.type === "Publication") {
      if (item.plainLanguageType.includes("Application") || item.plainLanguageType.includes("Patent application")) {
        return "bg-sky-500 dark:bg-sky-700";
      }
      if (item.plainLanguageType.includes("Patent") && !item.plainLanguageType.includes("Application")) {
        return "bg-orange-500 dark:bg-orange-700";
      }
    }
    return "bg-sky-500 dark:bg-sky-700"; // Default case
  }

  function getItemTextClass(item: RowItem) {
    if (item.isQuery) {
      return "text-white";
    }
    if (item.kind === "W") {
      return "text-black dark:text-gray-500";
    }
    if (item.type === "Application") {
      return "text-gray-800 dark:text-gray-400";
    }
    if (item.type === "Publication") {
      if (item.plainLanguageType.includes("Application") || item.plainLanguageType.includes("Patent application")) {
        return "text-sky-800 dark:text-sky-400";
      }
      if (item.plainLanguageType.includes("Patent") && !item.plainLanguageType.includes("Application")) {
        return "text-orange-800 dark:text-orange-400";
      }
    }
    return "text-sky-800 dark:text-sky-400"; // Default case
  }

  function getIcon(item: RowItem) {
    const hasPatent = typeof item.plainLanguageType === 'string' && item.plainLanguageType.includes("Patent");

    // Return SVG for publications that are not patents (Sky Blue colored)
    if (item.type === "Publication" && !hasPatent) {
      return (
        //trumpet
        <svg className="w-4 h-4 text-yellow-100 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
          <path d="M15 1.943v12.114a1 1 0 0 1-1.581.814L8 11V5l5.419-3.871A1 1 0 0 1 15 1.943ZM7 4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2v5a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V4ZM4 17v-5h1v5H4ZM16 5.183v5.634a2.984 2.984 0 0 0 0-5.634Z" />
        </svg>
      );
    } else if (item.plainLanguageType.includes("Patent") && !item.plainLanguageType.includes("Application")) {
      // ribbon
      return (
        <svg className="w-4 h-4 text-yellow-100 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m6.072 10.072 2 2 6-4m3.586 4.314.9-.9a2 2 0 0 0 0-2.828l-.9-.9a2 2 0 0 1-.586-1.414V5.072a2 2 0 0 0-2-2H13.8a2 2 0 0 1-1.414-.586l-.9-.9a2 2 0 0 0-2.828 0l-.9.9a2 2 0 0 1-1.414.586H5.072a2 2 0 0 0-2 2v1.272a2 2 0 0 1-.586 1.414l-.9.9a2 2 0 0 0 0 2.828l.9.9a2 2 0 0 1 .586 1.414v1.272a2 2 0 0 0 2 2h1.272a2 2 0 0 1 1.414.586l.9.9a2 2 0 0 0 2.828 0l.9-.9a2 2 0 0 1 1.414-.586h1.272a2 2 0 0 0 2-2V13.8a2 2 0 0 1 .586-1.414Z" />
        </svg>
      );
    } else {
      // Return trumpet SVG
      return (
        <svg className="w-4 h-4 text-yellow-100 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
          <path d="M15 1.943v12.114a1 1 0 0 1-1.581.814L8 11V5l5.419-3.871A1 1 0 0 1 15 1.943ZM7 4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2v5a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V4ZM4 17v-5h1v5H4ZM16 5.183v5.634a2.984 2.984 0 0 0 0-5.634Z" />
        </svg>
      );
    }
  }
  useEffect(() => {
    const handlePositionChangeMemoized = () => {
      if (ref.current && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const rect = ref.current.getBoundingClientRect();

        // Adjust the position relative to the container
        const relativeX = rect.x - containerRect.x + window.scrollX;
        const relativeY = rect.y - containerRect.y + window.scrollY;

        handlePositionChange(item.reference, { x: relativeX, y: relativeY });
      }
    };

    handlePositionChangeMemoized();
  }, [ref, fixedMarkerDistance, item.reference, rowHeight, containerRef]);

  return (
    <>
      <div
        ref={ref}
        onClick={openModal}
        className="absolute cursor-pointer hover:opacity-75 transition-opacity duration-300 z-20"
        style={{
          left: `${leftPosition}px`,
          top: `${index}px`
        }}
      >
        <span className="sr-only">{item.id}</span>
        <div
          className={`${getItemClass(item)} text-white text-xs p-2 rounded-r-lg rounded-bl-lg my-shadow-sharp`}
          style={{
            minWidth: `${fixedMarkerDistance / 2}px`
          }}
        >
          <div className="flex justify-between">
            <div
              className={`${getItemTextClass(item)} flex`}
            >
              <Flag flagSrc={flagSrc} flagTitle={flagTitle} />
              <span className="sr-only">{flagTitle}</span>
              <div className="flex mr-1">
              </div>
              {item.isQuery === true ?
                <div className="text-xs w-full text-start"> (Your Query)</div>
                :
                item.countryName.length > maxLength
                  ? item.countryName.substring(0, maxLength) + "..."
                  : item.countryName
              }
            </div>
            <button
              aria-label="Open modal"
              type="button"
              onClick={openModal}
              color="white"
            >
              {item.plainLanguageType && <span>
                {getIcon(item)}             </span>
                ||
                <svg className="w-4 h-4 text-green-200 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.994 19a8.999 8.999 0 1 1 3.53-17.281M5.995 9l4 4 7-8m-1 8v5m-2.5-2.5h5" />
                </svg>
              }
            </button>
          </div>
          <span>{item.type === "Application" ? item.originalReference : item.fullReference}</span>
          <p className='whitespace-nowrap'>
            {formatDate(item.date)}
          </p>
          <p className='font-semibold'>
            {(function () {
              const content = item.kind.includes("P") ? "US Provisional"
                : item.type && item.kind.includes("W") ? "Int. Application"
                  : item.type === "Publication" ? item.plainLanguageType
                    : item.type;

              return content.length > maxTypeLength
                ? content.substring(0, maxTypeLength) + "..."
                : content;
            })()
            }
          </p>
        </div>

      </div>
      <RowItemModal
        isOpen={isModalOpen}
        onClose={closeModal}
        item={item}
        singleRow={singleRow}
        flagSrc={flagSrc}
        flagTitle={flagTitle}
        associatedApplicationRef={associatedApplicationRef}
      />
    </>
  );
};

export default RowItemComponent;