import React, { useMemo } from 'react';
import HorizontalLine from './HorizontalLine';
import { SingleRow } from '../../models/FamilyTreeInterfaces';
import { usePositions } from '../contexts/PositionsContext';

interface PublicationLineSVGProps {
  singleRow: SingleRow;
  rowHeight: number;
}

const PublicationLineSVG: React.FC<PublicationLineSVGProps> = ({ singleRow, rowHeight }) => {
  let additionalOffset = 180;
  const { positions } = usePositions();
  const firstItemPosition = positions[singleRow.row[0]?.reference];

  const arrowheadMarkerPublication = useMemo(() => (

    <marker
      id="arrowheadHorizontal"
      markerWidth="10"
      markerHeight="7"
      refX="0"
      refY="3.5"
      orient="auto"
    >
      <polygon points="0 0, 10 3.5, 0 7" fill="magenta" />
    </marker>
  ), []);

  return (
    <svg className="absolute top-0 left-0 w-full" style={{ height: `${rowHeight}px`, overflow: 'visible' }}>
      <defs>
        {arrowheadMarkerPublication}
      </defs>
      {singleRow.row.slice(1).map((item, index) => {
        const endPos = positions[item.reference];

        if (firstItemPosition && endPos) {
          const line = (
            <HorizontalLine
              key={index}
              startPos={firstItemPosition}
              endPos={endPos}
              text="published as"
              rowHeight={rowHeight}
              verticalOffset={additionalOffset}
            />
          );
          additionalOffset += 5;
          return line;
        }
        return null;
      })}
    </svg>
  );
}

export default PublicationLineSVG;
