//src/business/pages/DisplayData.tsx
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import opsService from '../services/opsService';
import { EpRegisterDTO } from '../models/EpRegisterInterfaces';
// import useImageBase64 from '../../hooks/useImageBase64';

import { convertImageToBase64 } from '../utils/imageUtils';
import GeneralInfo from './components/GeneralInfo';
import DisplayLoader from './components/DisplayLoader';
const DisplayData = () => {
  const { applicationNr } = useParams<{ applicationNr: string }>();
  const applicationNumber = applicationNr || ''; // Providing a default empty string
  const [data, setData] = useState<EpRegisterDTO | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      if (applicationNr) {
        setIsLoading(true);
        try {
          const response = await opsService.fetchEpRegisterDataByApplicationNr(applicationNr);
          setData(response);
        } catch (err) {
          setError('Error fetching data');
          // You can handle the error more specifically if you want
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [applicationNr]);

  if (isLoading) {
    return <DisplayLoader applicationNr={applicationNumber} />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!data) {
    return <p>No data found</p>;
  }

  return (
    <div>
      <GeneralInfo
        data={data}
        base64Image={data.imageData ? convertImageToBase64(data.imageData) : ''}
        firstApplicationReferenceCountry={data.applicationReferences?.[0]?.documentId?.country || 'No country available'}
        firstApplicationReferenceDocNumber={data.applicationReferences?.[0]?.documentId?.docNumber || 'No document number available'}
        patentStatus={data.patentStatus || 'No status available'}
        latestPatentStatusChangeDate={data.latestPatentStatusChangeDate || 'No date available'}
        languageOfFiling={data.languageOfFiling || 'No filing language information available'}
        inventorChangeDate={data.inventorsDTO?.formattedDate || 'No change date available'}
        inventorChangeGazetteNumber={data.inventorsDTO?.changeGazetteNum || 'No change gazette number available'}
        applicantChangeDate={data.applicantsDTO?.formattedDate || 'No change date available'}
        applicantChangeGazetteNumber={data.applicantsDTO?.changeGazetteNum || 'No change gazette number available'}
        representativeChangeDate={data.agentsDTO?.formattedDate || 'No change date available'}
        representativeChangeGazetteNumber={data.agentsDTO?.changeGazetteNum || 'No change gazette number available'}
      />
    </div>
  );
};

export default DisplayData;