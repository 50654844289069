//src/blog/components/LatestThreeForSideBar.tsx
import React, { useEffect, useState } from 'react';
import { blogService, BlogPostPreviewDTO } from '../../services/blogService';
import config from '../../config/config';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../routes/RoutePaths';
import LoadingLatestThreeForSideBar from './LoadingLatestThreeForSideBar';

const LatestThreeForSideBar: React.FC = () => {
  const [blogPosts, setBlogPosts] = useState<BlogPostPreviewDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { CLIENT_BASE_URL, CLIENT_BLOG_URL } = config;

  useEffect(() => {
    const loadBlogPosts = async () => {
      try {
        setLoading(true);
        const fetchedBlogPosts = await blogService.fetchLatestThreeBlogPosts();
        setBlogPosts(fetchedBlogPosts);
      } catch (err) {
        setError('Failed to load related BlogPosts');
      } finally {
        setLoading(false);
      }
    };

    loadBlogPosts();
  }, []);

  if (loading) {
    return <LoadingLatestThreeForSideBar />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const truncateString = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  return (
    <div>
      {blogPosts.map(blogPost => (
        <div key={blogPost.id}
          className="mb-6 flex items-center"
        >
          <Link
            to={CLIENT_BASE_URL + CLIENT_BLOG_URL + blogPost.slug}
            className="shrink-0"
          >
            <div className="mr-4 w-24 h-24 overflow-hidden my-shadow">
              <img
                src={blogPost.featuredMediaUrl}
                className="min-w-full min-h-full object-cover object-center"
                alt={blogPost.altText}
              />
            </div>
          </Link>
          <div>
            <h5 className="mb-2 text-lg font-bold leading-tight dark:text-white text-gray-900">
              {truncateString(blogPost.title, 20)}
            </h5>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              {truncateString(blogPost.excerpt, 20)}
            </p>
            <Link
              to={RoutePaths.ListOfSameCategoryBlogPosts.replace(':categoryName', blogPost.categoryNames[0])}
              className="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 dark:text-primary-500 hover:no-underline">
              {blogPost.categoryNames[0]}
            </Link>
          </div>
        </div>
      ))
      }
    </div>
  )
};

export default LatestThreeForSideBar;