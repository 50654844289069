// Note: Dashboard Header component
import React, { useEffect } from 'react';
import CategoryList from "../../../components/dashboard/querycategoryassignments/CategoryList";
import { formatDateAndTime } from "../utils/formatUtils";
import { useAuthContext } from "../../../contexts/AuthContext";
import { Button } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../../routes/RoutePaths';

interface DashboardHeaderProps {
  metaTitle: string;
  metaQuery: string;
  totalNumberOfRowItems: number;
  totalNumberOfRows: number;
  applicants: string[];
  requestTime: Date;
  className?: string;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  metaTitle,
  metaQuery,
  totalNumberOfRowItems,
  totalNumberOfRows,
  applicants,
  requestTime
}) => {
  const authContext = useAuthContext();
  if (!authContext) {
    alert('authContext is null');
    return null;
  }
  const { user } = authContext;
  useEffect(() => {
  }, [user]);
  return (
    <>
      {user &&
        <CategoryList
          applicationNumber={metaQuery}
        />
        ||
        <div className='justify-center flex'>
          <div id="category-buttons" className="my-4 sm:flex mx-auto">
            <Link to={RoutePaths.Login}>
              <Button
                size="xs"
                label="favorites"
                color="primary"
                className="text-white font-bold py-1.5 px-2 rounded-full m-1 bg-gray-500 hover:bg-gray-700 whitespace-nowrap"
              >
                favorites
              </Button>
            </Link>
            <Link to={RoutePaths.Login}>
              <Button
                size="xs"
                label="my patents"
                color="primary"
                className="text-white font-bold py-1.5 px-2 rounded-full m-1 bg-gray-500 hover:bg-gray-700 whitespace-nowrap"
              >
                My Patents
              </Button>
            </Link>
            <Link to={RoutePaths.Login}>
              <Button
                size="xs"
                label="competitor"
                color="primary"
                className="text-white font-bold py-1.5 px-2 rounded-full m-1 bg-gray-500 hover:bg-gray-700 whitespace-nowrap"
              >
                Competitor
              </Button>
            </Link>
            <Link to={RoutePaths.Login}>
              <Button
                size="xs"
                label="state of the art"
                color="primary"
                className="text-white font-bold py-1.5 px-2 rounded-full m-1 bg-gray-500 hover:bg-gray-700 whitespace-nowrap"
              >
                State of the Art
              </Button>
            </Link>
            <Link to={RoutePaths.Login}>
              <Button
                size="xs"
                label="custom"
                color="primary"
                className="text-white font-bold py-1.5 px-2 rounded-full m-1 bg-gray-500 hover:bg-gray-700 whitespace-nowrap"
              >
                Custom category
              </Button>
            </Link>
          </div>
        </div>

      }
      <div className="flex flex-col items-center bg-white dark:bg-gray-800 px-4 py-5 text-center">

        <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 dark:text-white text-center max-w-xl">
          Patent Family: {metaQuery}
        </h1>
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold text-gray-700 dark:text-gray-300 mt-2 text-center">
          {metaTitle}
        </h2>
        <h3 className="text-lg md:text-xl font-medium text-gray-600 dark:text-gray-400 mt-1 text-center max-w-6xl">
          <span className="font-bold"> {applicants.length === 1 ? "Applicant: " : "Applicants: "}</span>
          {applicants.join(" ")}
        </h3>
        <p className="text-sm text-gray-500 dark:text-gray-500 mt-1 text-center">
          {totalNumberOfRows} members, {totalNumberOfRowItems} documents.
          Patent data retrieved on {formatDateAndTime(requestTime)}
        </p>
      </div>
    </>
  );
};
export default DashboardHeader;