import React from "react";
interface ClassificationProps {
  classificationDateFormatted?: string;
  classificationChangeGazetteNumber?: string;
  classificationText?: string;
}


const Classification: React.FC<ClassificationProps> = ({
  classificationDateFormatted,
  classificationChangeGazetteNumber,
  classificationText

}) => {


  return (
    <div>

      <div>
        <div className="flex justify-center items-center relative mx-auto max-w-screen-sm">
          <p className="font-normal text-gray-900 dark:text-white text-xs">
            Change date: {classificationDateFormatted}
          </p>
        </div>
        <div className="mb-8 flex justify-center items-center relative mx-auto max-w-screen-sm">
          <p className="font-normal text-gray-900 dark:text-white text-xs">
            Gazette: {classificationChangeGazetteNumber}
          </p>
        </div>
      </div>
      <div className="relative mx-auto max-w-screen-sm overflow-x-auto rounded shadow-md shadow-gray-500/50 dark:shadow-none mb-8">
        <table className="w-full text-xs lg:text-base text-left ">
          <thead className="text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-white">
            <tr>
              <th scope="col" className="px-2 sm:px-6 py-3 w-1/3 ">
                Classification
              </th>
              <th scope="col" className="pr-2 sm:pr-6 py-3 w-2/3">
                <div className="flex items-center justify-start">
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
                <p className="text-gray-700 dark:text-white font-normal">
                  IPC codes:
                </p>
              </th>
              <td className="pr-2 sm:pr-6 w-2/3">
                <div className="flex justify-start font-normal dark:text-white">
                  <p className="flex items-center space-x-3">
                    {classificationText}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Classification;