//src/business/pages/components/PrioLineSwitch.tsx
import React from 'react';
import { ToggleSwitch } from 'flowbite-react';

interface PrioLineSwitchProps {
  checked: boolean;
  onChange: () => void;
}

const PrioLineSwitch: React.FC<PrioLineSwitchProps> = ({ checked, onChange }) => {
  return (
    <div className="flex items-center justify-center">
      <ToggleSwitch
        label="Prio lines"
        color="pink"
        id="Prioline"
        aria-describedby="Prioline"
        name="prioline"
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
}

export default PrioLineSwitch;
