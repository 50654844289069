import React from 'react';
import { Modal } from 'flowbite-react';
import { LineData } from '../../models/PowerDashboardInterfaces';
import useFlagLogic from "../hooks/useFlagLogic";
import { formatDate } from "../utils/formatUtils";
import Flag from './Flag';
interface PriorityLineModalProps {
  isOpen: boolean;
  onClose: () => void;
  lineData?: LineData; // Optional prop for line data
}

const PriorityLineModal: React.FC<PriorityLineModalProps> = ({ isOpen, onClose, lineData }) => {
  if (!lineData) return null; // or some fallback UI
  const fromItemFlag = useFlagLogic(lineData.fromItem);
  const toItemFlag = useFlagLogic(lineData.toItem);
  const date1 = new Date(lineData.fromItem.date);
  const date2 = new Date(lineData.toItem.date);

  // Get the difference in time (milliseconds)
  const diffTime = Math.abs(date2.getTime() - date1.getTime());

  // Convert time difference to days
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return (
    <Modal show={isOpen} onClose={onClose} title="This is a priority claim" className="bg-white">
      <div className="bg-white dark:bg-neutral-900 p-8 rounded-lg my-shadow-sharp dark:text-white">
        <div className="flex justify-end">
          <button
            aria-label="Close modal"
            onClick={onClose}
          >
            <svg className="w-4 h-4 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
          </button>
        </div>
        <div className='flex justify-center text-xl'>
          Priority claim
        </div>
        <div className='flex justify-center text-md py-4'>

          {lineData.fromItem.originalReference} (child) claims priority of {lineData.toItem.originalReference} (parent)
        </div>

        <div className='pt-8 flex justify-between'>
          <div className="bg-gray-700 text-white text-xs p-2 rounded-r-lg rounded-bl-lg my-shadow-sharp"
            style={{
              minWidth: `${200}px`
            }}
          >
            <div className="-mt-8 mb-4">(Parent)</div>
            <div className="flex justify-between">
              <Flag flagSrc={toItemFlag.flagSrc} flagTitle={toItemFlag.flagTitle} />
            </div>
            <span>{lineData.toItem.originalReference}</span>
            <p className='whitespace-nowrap'>
              {formatDate(lineData.toItem.date)}
            </p>
            <p>{lineData.toItem.type}</p>
          </div>
          <div className='px-4 text-xs'>
            <p className="pt-2 font-bold"> Days passed: {diffDays}</p>

          </div>
          <div className="bg-gray-700 text-white text-xs p-2 rounded-r-lg rounded-bl-lg my-shadow-sharp"
            style={{
              minWidth: `${200}px`
            }}
          >
            <div className="-mt-8 mb-4">(Child)</div>
            <div className="flex justify-between">
              <Flag flagSrc={fromItemFlag.flagSrc} flagTitle={fromItemFlag.flagTitle} />
            </div>
            <span>{lineData.fromItem.originalReference}</span>
            <p className='whitespace-nowrap'>
              {formatDate(lineData.fromItem.date)}
            </p>
            <p>{lineData.fromItem.type}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PriorityLineModal;
