// src/hooks/useFetchAllSameCatBlogPosts.ts

import { useState, useEffect } from 'react';
import { blogService, BlogPostPreviewDTO } from '../services/blogService';

const useFetchAllSameCatBlogPosts = (categoryName: string | undefined) => {
  const [BlogPosts, setBlogPosts] = useState<BlogPostPreviewDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPosts = async () => {
      if (!categoryName) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const blogPosts = await blogService.fetchSameCategoryBlogPosts(categoryName);
        setBlogPosts(blogPosts);
      } catch (err) {
        setError('Error fetching blog posts');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [categoryName]);

  return { BlogPosts, isLoading, error };
};

export default useFetchAllSameCatBlogPosts;
