//src/business/pages/components/RootLineSwitch.tsx
import React from 'react';
import { ToggleSwitch } from 'flowbite-react';

interface RootLineSwitchProps {
  checked: boolean;
  onChange: () => void;
}

const RootLineSwitch: React.FC<RootLineSwitchProps> = ({ checked, onChange }) => {
  return (
    <div className="flex items-center justify-center">
      <ToggleSwitch
        label="Root lines"
        color="yellow"
        id="Rootline"
        aria-describedby="Rootline"
        name="prioline"
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
}

export default RootLineSwitch;
