// src/admin/pages/Role.tsx
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchRoleDetails, AspRoleDTO } from '../../services/roleService';
import { Button } from 'flowbite-react';
import { Link } from 'react-router-dom';

const Role = () => {
    const { id } = useParams();
    const [role, setRole] = useState<AspRoleDTO | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        if (id) {
            fetchRoleDetails(id)
                .then(data => {
                    setRole(data);
                    setLoading(false);
                })
                .catch(err => {
                    setError('Error fetching role details: ' + err.message);
                    setLoading(false);
                });
        }
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <section className="py-4 bg-white dark:bg-gray-900 lg:pt-12 lg:pb-16 dark:bg-black">
                <div className="px-4 mx-auto max-w-8xl lg:px-4 lg:text-center">
                    <h1 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 lg:font-extrabold lg:text-6xl lg:leading-none dark:text-white lg:text-center xl:px-36 lg:mb-7">
                        Role details
                    </h1>
                    <p className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 lg:text-center lg:text-xl xl:px-60">
                        Inspect all the details of a role.
                    </p>
                    <div className="flex justify-center">
                        <div className="w-full max-w-2xl">
                            {role ? (
                                <table className="table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400 whitespace-no-wrap">
                                    <tbody className="text-center">
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="px-4 py-2 border"><strong>ID:</strong></td>
                                            <td className="px-4 py-2 border">{role.id}</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-2 border"><strong>Name:</strong></td>
                                            <td className="px-4 py-2 border">{role.name}</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-2 border"><strong>Description:</strong></td>
                                            <td className="px-4 py-2 border">{role.description}</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-2 border"><strong>Created at:</strong></td>
                                            <td className="px-4 py-2 border">{role.createdAt}</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-2 border"><strong>Last updated:</strong></td>
                                            <td className="px-4 py-2 border">{role.lastEditedAt}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-center">Role not found.</p>
                            )}
                        </div>

                    </div>
                </div>
                <div className='flex justify-center mt-8'>
                    <Button color="cyan" size="sm" className="mr-2">
                        <Link to={`/admin/role/${id}/edit`}>
                            Update
                        </Link>
                    </Button>
                </div>
            </section>
        </div>
    );
};

export default Role;
