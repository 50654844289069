// src/admin/pages/Dashboard.tsx
import React from 'react';


const Dashboard: React.FC = () => {
    return (
        <div>
            <section className="py-4 bg-white dark:bg-gray-900 lg:pt-12 lg:pb-16 dark:bg-black">
                <div className="px-4 mx-auto max-w-8xl lg:px-4 lg:text-center">

                    <h1 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 lg:font-extrabold lg:text-6xl lg:leading-none dark:text-white lg:text-center xl:px-36 lg:mb-7">
                        Welcome to the admin dashboard
                    </h1>
                    <p className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 lg:text-center lg:text-xl xl:px-60">
                        Create, edit, and delete users and roles.
                    </p>
                    <div className="flex flex-col mb-8 md:flex-row lg:justify-center">
                        Create, edit, and delete subscription tiers.
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Dashboard;
