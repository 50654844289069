//src/business/pages/DynamicDashboard.tsx
import { useParams } from 'react-router-dom';
import useDashboard from './hooks/useDashboard';
import DThreeDashboard from './components/DThreeDashboard';

const DynamicDashboard = () => {
  const { applicationNumber } = useParams<{ applicationNumber: string }>();
  const dashboardData = useDashboard(applicationNumber || '');
  return <DThreeDashboard {...(dashboardData)} />;
}

export default DynamicDashboard;