// src/dashboard/components/AllCategories.tsx
import React, { useState, useEffect } from 'react';
import CategoryService, { CategoryDTO } from '../../../services/categoryService'; // Import CategoryService and Category interface
import { Button } from 'flowbite-react';
import { Link } from 'react-router-dom';
import SuccessAlert from '../../../htmlBlocks/SuccessAlert';
import ErrorAlert from '../../../htmlBlocks/ErrorAlert';

interface AllCategoriesProps {
  categoryCreated: boolean;
  setCategoryCreated: (created: boolean) => void;
}

const AllCategories: React.FC<AllCategoriesProps> = ({ categoryCreated, setCategoryCreated }) => {
  const [categories, setCategories] = useState<CategoryDTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null); // Add an error state
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      try {
        const categories = await CategoryService.getCategories();
        setCategories(categories);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message); // Now safely accessing message property
        } else {
          setError('An unknown error occurred'); // Fallback error message
        }
      }
      setIsLoading(false);
    };

    fetchCategories();
  }, [categoryCreated]);



  useEffect(() => {
    if (categoryCreated) {
      setCategoryCreated(false); // Reset the categoryCreated flag
    }
  }, [categoryCreated, setCategoryCreated]);

  const handleDelete = (categoryId: string) => {
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    CategoryService.deleteCategory(categoryId)
      .then(response => {
        // Assuming the response contains a message field with the success message
        setSuccessMessage(response.Message || "Category deleted successfully");

        // Filter out the deleted category from the categories state
        setCategories(categories.filter(category => category.id !== categoryId));
      })
      .catch(err => {
        // Assuming the error message is descriptive enough for the user
        setErrorMessage(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (error) {
    return <p>Error: {error}</p>;
  }
  // Function to clear messages
  const clearMessages = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };
  return (
    <div className="overflow-x-auto my-shadow-sharp rounded-lg">
      {
        successMessage &&
        <SuccessAlert
          successMessage={successMessage}
          clearMessages={clearMessages}
        />
      }
      {
        errorMessage &&
        <ErrorAlert
          errorMessage={errorMessage}
          clearMessages={clearMessages}
        />
      }

      {isLoading ?

        <div role="status" className="flex justify-center">
          <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
        :
        <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Name</th>
              <th scope="col" className="px-6 py-3">Description</th>
              <th scope="col" className="px-6 py-3">Created At</th>
              <th scope="col" className="px-6 py-3">Last Edited At</th>
              <th scope="col" className="px-6 py-3">Delete</th>
              <th scope="col" className="px-6 py-3">Details</th>
              <th scope="col" className="px-6 py-3">Update</th>
            </tr>
          </thead>
          <tbody>
            {categories.map(category => (
              <tr key={category.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4">{category.name}</td>
                <td className="px-6 py-4">{category.description}</td>
                <td className="px-6 py-4">
                  {category.createdAt ? new Date(category.createdAt).toLocaleDateString() : 'N/A'}
                </td>
                <td className="px-6 py-4">
                  {category.lastEditedAt ? new Date(category.lastEditedAt).toLocaleDateString() : 'N/A'}
                </td>
                <td className="px-6 py-4">
                  <Button color="failure" size="sm" className="mr-2"
                    onClick={() => handleDelete(category.id)}
                  >
                    Delete
                  </Button>
                </td>
                <td className="px-6 py-4">
                  <Button color="cyan" size="sm" className="mr-2">
                    <Link to={`/dashboard/category/${category.id}`}>
                      Details
                    </Link>
                  </Button>
                </td>
                <td className="px-6 py-4">
                  <Button color="lime" size="sm" className="mr-2">
                    <Link to={`/dashboard/category/${category.id}/edit`}>
                      Update
                    </Link>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      }
    </div>
  );
};

export default AllCategories;

