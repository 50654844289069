// src/blog/components/CommentForm.tsx

import React, { useState } from 'react';
import { FrontEndBlogPostCommentDTO, commentService } from '../../services/commentService';
import { useAuthContext } from '../../contexts/AuthContext';

interface CommentFormProps {
  postId: number;
  parentCommentId?: number;
  onCommentPosted: () => void;
}

const CommentForm: React.FC<CommentFormProps> = ({ postId, parentCommentId, onCommentPosted }) => {
  const [commentContent, setCommentContent] = useState<string>('');
  const authContext = useAuthContext();
  if (!authContext) {
    return null;
  }

  const { user } = authContext;
  const userId = user?.userId || 'anonymous';

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentContent(e.target.value);
  };

  const handleCommentSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const frontEndComment: FrontEndBlogPostCommentDTO = {
      blogPostId: postId,
      userId: userId,
      content: commentContent,
      parent: parentCommentId || 0
    };

    try {
      await commentService.postBlogPostComment(frontEndComment);
      setCommentContent('');
      onCommentPosted(); // Invoke callback to refresh comments
    } catch (err) {
      console.error('Error posting comment:', err);
    }
  };

  return (
    <form className="mb-6 comment-form" onSubmit={handleCommentSubmit}>
      <div
        className="mb-4 w-full bg-gray-50 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 my-shadow-sharp">
        <div className="py-2 px-4 bg-gray-50 rounded-t-lg dark:bg-gray-800">
          <label htmlFor="comment" className="sr-only">Your comment</label>
          <textarea
            id="comment"
            value={commentContent}
            onChange={handleCommentChange}
            placeholder="Write your comment here..."
            rows={4}
            className="px-0 w-full text-sm text-gray-900 bg-gray-50 border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
            required
          >
          </textarea>
        </div>
        <div className="flex justify-between items-center py-2 px-3 border-t dark:border-gray-600">
          <button title="default" type="submit"
            className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">
            Post comment
          </button>
        </div>
      </div>
    </form>
  );
};

export default CommentForm;