// src/admin/pages/RoleEdit.tsx
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchRoleDetails, updateRole, AspRoleDTO, UpdateAspRoleDTO } from '../../services/roleService';
import { Button, TextInput } from 'flowbite-react';

const RoleEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [role, setRole] = useState<AspRoleDTO | null>(null);
    const [originalRole, setOriginalRole] = useState<AspRoleDTO | null>(null); // State for original role data
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (id) {
            fetchRoleDetails(id)
                .then(data => {
                    setRole(data);
                    setOriginalRole(data); // Set original role data
                    setLoading(false);
                })
                .catch(err => {
                    setErrorMessage('Error fetching role details: ' + err.message);
                    setLoading(false);
                });
        }
    }, [id]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRole(prevState => prevState ? { ...prevState, [name]: value } : null);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage('');

        try {
            if (role && originalRole) {
                const roleDataToUpdate: UpdateAspRoleDTO = {
                    oldRoleName: originalRole.name, // Use the original role name
                    newRoleName: role.name,
                    description: role.description
                };

                await updateRole(roleDataToUpdate);
                navigate('/admin/roles');
            }
        } catch (error: any) {
            setErrorMessage(error.message || 'Unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (errorMessage) {
        return <div>Error: {errorMessage}</div>;
    }

    return (
        <div>
            <section className="py-4 bg-white dark:bg-gray-900 lg:pt-12 lg:pb-16 dark:bg-black">
                <div className="px-4 mx-auto max-w-8xl lg:px-4 lg:text-center ">

                    <h1 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 lg:font-extrabold lg:text-6xl lg:leading-none dark:text-white lg:text-center xl:px-36 lg:mb-7">
                        Update role
                    </h1>
                    <p className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 lg:text-center lg:text-xl xl:px-60">
                        Change the name and description of a role.
                    </p>
                    <div className='w-full justify-center'>
                        <form onSubmit={handleSubmit} className="grid gap-6">
                            <TextInput
                                id="id"
                                type="text"
                                name="id"
                                value={role?.id || ''}
                                readOnly
                                disabled
                            />
                            <TextInput
                                id="name"
                                type="text"
                                name="name"
                                value={role?.name || ''}
                                onChange={handleInputChange}
                            />
                            <TextInput
                                id="description"
                                type="text"
                                name="description"
                                value={role?.description || ''}
                                onChange={handleInputChange}
                            />
                            <Button type="submit">
                                Update Role
                            </Button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default RoleEdit;