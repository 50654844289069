// src/blog/hooks/useOrganizedComments.ts

import { useState, useEffect } from 'react';
import { BlogPostCommentDTO } from '../../services/commentService';

export interface BlogPostCommentWithRepliesDTO extends BlogPostCommentDTO {
  replies: BlogPostCommentWithRepliesDTO[];
}

const useOrganizedComments = (flatComments: BlogPostCommentDTO[]): BlogPostCommentWithRepliesDTO[] => {
  const [organizedComments, setOrganizedComments] = useState<BlogPostCommentWithRepliesDTO[]>([]);

  useEffect(() => {
    const organizeComments = (comments: BlogPostCommentDTO[]): BlogPostCommentWithRepliesDTO[] => {
      const commentMap = new Map<number, BlogPostCommentWithRepliesDTO>();

      comments.forEach(comment => {
        commentMap.set(comment.id, { ...comment, replies: [] });
      });

      const topLevelComments: BlogPostCommentWithRepliesDTO[] = [];

      comments.forEach(comment => {
        const extendedComment = commentMap.get(comment.id);
        if (comment.parent && extendedComment) {
          const parent = commentMap.get(comment.parent);
          if (parent) {
            parent.replies.push(extendedComment);
          }
        } else if (extendedComment) {
          topLevelComments.push(extendedComment);
        }
      });

      return topLevelComments;
    };

    setOrganizedComments(organizeComments(flatComments));
  }, [flatComments]);

  return organizedComments;
};


export default useOrganizedComments;
