// src/htmlBlocks/SuccessAlert.tsx
import React from "react";

interface SuccessAlertProps {
  successMessage: string;
  clearMessages: () => void;
}

const SuccessAlert: React.FC<SuccessAlertProps> = ({ successMessage, clearMessages }) => {
  return (
    <div className="flex justify-between p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
      <div className="text-sm font-medium text-green-700 dark:text-green-800">
        <dl>
          <dt className="sr-only">Success Message</dt>
          <dd className="success">
            {successMessage}
          </dd>
        </dl>
      </div>
      <button
        aria-label="close success message"
        type="button"
        onClick={clearMessages}
        className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd">
          </path>
        </svg>
      </button>
    </div>
  );
};

export default SuccessAlert;