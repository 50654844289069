import React, { useState, useEffect } from 'react';
import RowItems from '../components/RowItems';
import Timeline from '../components/Timeline';
import PrioLinesSVG from '../components/PrioLinesSVG';
import RootLinesSVG from '../components/RootLinesSVG';
import OptimalPrioLinesSVG from './OptimalPrioLinesSVG';
import ZoomControls from '../components/ZoomControls';
import DragControls from '../components/DragControl';
import { PositionsProvider } from '../contexts/PositionsContext';
import { SingleRow, DependencyInfo } from '../../models/FamilyTreeInterfaces';
import DashboardLoader from './DashboardLoader';
import DashboardHeader from './DashboardHeader';
import '../../../index.css';
// import ReferenceLinesSVG from './ReferenceLinesSVG';
export interface DashboardProps {
  containerRef: React.RefObject<HTMLDivElement>;
  rowHeight: number;
  fixedMarkerDistance: number;
  handleHorizontalZoomChange: (newDistance: number) => void;
  handleVerticalZoomChange: (newRowHeight: number) => void;
  showPrioLines: boolean;
  togglePrioLines: () => void;
  showRootLines: boolean;
  toggleRootLines: () => void;
  showOptimalPrioLines: boolean;
  toggleOptimalPrioLines: () => void;
  maxHeight: number;
  handleMaxHeightChange: (e: number) => void;
  data: SingleRow[];
  dependencies?: { [key: string]: DependencyInfo };
  totalHeight: number;
  totalWidth: number;
  firstDayofYear: Date;
  latestDate2: Date;
  getPositionFromDate: (date: Date, startDate: Date, fixedMarkerDistance: number) => number;
  applicationNumber: string;
  isLoading: boolean;
  metaTitle: string;
  metaQuery: string;
  totalNumberOfRowItems: number;
  totalNumberOfRows: number;
  applicants: string[];
  requestTime: Date;
}

const Dashboard: React.FC<DashboardProps> = ({
  containerRef,
  rowHeight,
  fixedMarkerDistance,
  handleHorizontalZoomChange,
  handleVerticalZoomChange,
  showPrioLines,
  togglePrioLines,
  showRootLines,
  toggleRootLines,
  showOptimalPrioLines,
  toggleOptimalPrioLines,
  maxHeight,
  handleMaxHeightChange,
  data,
  // dependencies,
  totalHeight,
  totalWidth,
  firstDayofYear,
  latestDate2,
  getPositionFromDate,
  applicationNumber,
  isLoading,
  metaTitle,
  metaQuery,
  totalNumberOfRowItems,
  totalNumberOfRows,
  applicants,
  requestTime
}) => {
  const [numberOfRows, setNumberOfRows] = useState(0);
  const [dynamicMaxHeight, setDynamicMaxHeight] = useState(maxHeight);
  const [isDragging, setIsDragging] = useState(false);
  const [minimumLoadingTimePassed, setMinimumLoadingTimePassed] = useState(false);
  const [containerHeight, setContainerHeight] = useState(maxHeight);
  const [hasBeenDragged, setHasBeenDragged] = useState(false);
  const [showDashboardHeader, setShowDashboardHeader] = useState(true);
  const [headerAnimation, setHeaderAnimation] = useState("slide-in");
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsNavbarVisible(false);
    }, 1000); // Adjust time as needed

    return () => clearTimeout(timer);
  }, []);
  const handleDragStart = () => setIsDragging(true);

  const handleDragEnd = () => {
    setIsDragging(false);
    setHasBeenDragged(true); // Set flag that dragging has occurred
  };

  const updateNumberOfRows = (rowCount: number) => {
    setNumberOfRows(rowCount);
  };

  // Rename the local handler to avoid conflict
  const handleLocalMaxHeightChange = (newHeight: number) => {
    setContainerHeight(newHeight);
    // Call the prop function if it's provided
    if (handleMaxHeightChange) {
      handleMaxHeightChange(newHeight);
    }
  };
  const overflowStyle = isDragging || hasBeenDragged ? 'scroll' : 'hidden';

  // Calculate and update dynamicMaxHeight based on numberOfRows
  useEffect(() => {
    const newMaxHeight = numberOfRows * rowHeight + rowHeight;
    setDynamicMaxHeight(newMaxHeight);
  }, [numberOfRows, rowHeight]);

  // Optionally update container height when dynamicMaxHeight changes
  useEffect(() => {
    if (dynamicMaxHeight !== containerHeight) {
      handleMaxHeightChange(dynamicMaxHeight);
    }
  }, [dynamicMaxHeight]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setMinimumLoadingTimePassed(true);
    }, 2000); // Set to true after 2 seconds

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  const toggleDashboardHeader = () => {
    if (showDashboardHeader) {
      setHeaderAnimation("slide-out");
    } else {
      setHeaderAnimation("slide-in");
    }
    setTimeout(() => {
      setShowDashboardHeader(prevShow => !prevShow);
    }, 100); // 300ms is the duration of the animation
  };

  if (isLoading || !minimumLoadingTimePassed) {
    return <DashboardLoader applicationNumber={applicationNumber} />;
  }

  return (
    <PositionsProvider>
      {isNavbarVisible && (
        <ZoomControls
          fixedMarkerDistance={fixedMarkerDistance}
          rowHeight={rowHeight}
          onHorizontalZoomChange={handleHorizontalZoomChange}
          onVerticalZoomChange={handleVerticalZoomChange}
          showPrioLines={showPrioLines}
          onTogglePrioLines={togglePrioLines}
          showRootLines={showRootLines}
          onToggleRootLines={toggleRootLines}
          showOptimalPrioLines={showOptimalPrioLines}
          onToggleOptimalPrioLines={toggleOptimalPrioLines}
          onToggleDashboardHeader={toggleDashboardHeader}
          showDashboardHeader={showDashboardHeader}
        />
      )}
      <div className="flex mx-auto justify-center bg-gray-50 dark:bg-neutral-800 text-purple-700">
        <button
          type="button"
          aria-label="Toggle Navbar"
          onClick={() => setIsNavbarVisible(prev => !prev)}
          className="hover:opacity-50 transition-opacity duration-300"
        >

          {isNavbarVisible &&
            <div className="flex items-center">
              <svg className="w-4 h-4 text-purple-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7" />
              </svg><div className="ml-4">hide settings</div>
            </div>
            ||
            <div className="flex items-center">
              <svg className="w-4 h-4 text-purple-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
              </svg><div className="ml-4">show settings</div>
            </div>
          }
        </button>
      </div>
      <div className="py-16 bg-white dark:bg-gray-800">
        {showDashboardHeader && (
          <DashboardHeader
            className={headerAnimation}
            metaTitle={metaTitle}
            metaQuery={metaQuery}
            totalNumberOfRowItems={totalNumberOfRowItems}
            totalNumberOfRows={totalNumberOfRows}
            applicants={applicants}
            requestTime={requestTime}
          />
        )}
      </div>
      <div className="bg-white dark:bg-gray-900 flex justify-center pb-60">
        <div ref={containerRef} className="overflow-y-hidden overflow-y-hidden bg-gray-50 dark:bg-black h-full border-gray-300 dark:border-purple-500 rounded-lg border-2 my-shadow" style={{ maxHeight: `${maxHeight}px`, overflowY: overflowStyle }}>
          <div className="relative " style={{
            minHeight: `${totalHeight}px`,
            maxHeight: `${dynamicMaxHeight}px`,
            width: `${totalWidth}px`
          }}>
            <div className="sticky top-0 ">
              <Timeline
                startDate={firstDayofYear}
                endDate={latestDate2}
                fixedMarkerDistance={fixedMarkerDistance}
                maxHeight={totalHeight}
              />
            </div>
            <RowItems
              data={data}
              rowHeight={rowHeight}
              getPositionFromDate={getPositionFromDate}
              startDate={firstDayofYear}
              fixedMarkerDistance={fixedMarkerDistance}
              updateRowCount={updateNumberOfRows}
              containerRef={containerRef}
            />
            {showPrioLines && <PrioLinesSVG data={data} />}
            {showRootLines && <RootLinesSVG data={data} />}
            {showOptimalPrioLines && <OptimalPrioLinesSVG data={data} />}
            {/* {dependencies && <ReferenceLinesSVG
              data={data}
              dependencies={dependencies}
            />
            } */}
          </div>
        </div>
      </div>
      <DragControls
        onMaxHeightChange={handleLocalMaxHeightChange}
        containerRef={containerRef}
        initialHeight={maxHeight}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      />
    </PositionsProvider>
  );
}

export default Dashboard;