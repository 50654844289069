import React from 'react';
import { RangeSlider } from 'flowbite-react';
import CustomLabel from '../../../themes/CustomLabel';
import PrioLineSwitch from '../components/PrioLineSwitch';
import RootLineSwitch from '../components/RootLineSwitch';
import DashboardHeaderSwitch from './DashboardHeaderSwitch';
import FamilyTreeSearchMini from '../../components/FamilyTreeSearchMini';
// import OptimalPrioLineSwitch from './OptimalPrioLineSwitch';

interface ZoomControlsProps {
  fixedMarkerDistance: number;
  rowHeight: number;
  onHorizontalZoomChange: (value: number) => void;
  onVerticalZoomChange: (value: number) => void;
  showPrioLines: boolean;
  onTogglePrioLines: () => void;
  showRootLines: boolean;
  onToggleRootLines: () => void;
  showOptimalPrioLines: boolean;
  onToggleOptimalPrioLines: () => void;
  onToggleDashboardHeader: () => void;
  showDashboardHeader: boolean;
}

const ZoomControls: React.FC<ZoomControlsProps> = ({
  fixedMarkerDistance,
  rowHeight,
  onHorizontalZoomChange,
  onVerticalZoomChange,
  showPrioLines,
  onTogglePrioLines,
  showRootLines,
  onToggleRootLines,
  showDashboardHeader,
  onToggleDashboardHeader
}) => {
  return (
    <>
      <div className='pb-4 bg-gray-50 dark:bg-neutral-900'>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 flex justify-center">
          <div className='flex justify-center items-center mx-auto'>
            <div className='py-16'>
              <FamilyTreeSearchMini />
            </div>
          </div>
          <div className='flex justify-center sm:justify-start items-center pb-16'>
            <div className="grid sm:grid-cols-2 grid-cols-1 xl:grid-cols-3 gap-4 justify-between">
              <div className='pt-4'>
                <DashboardHeaderSwitch
                  checked={showDashboardHeader}
                  onChange={onToggleDashboardHeader}
                />
              </div>
              <div className='pt-4 justify-start'>
                <RootLineSwitch
                  checked={showRootLines}
                  onChange={onToggleRootLines}
                />
              </div>
              <div className='pt-4 justify-start'>
                <PrioLineSwitch
                  checked={showPrioLines}
                  onChange={onTogglePrioLines}
                />
              </div>
              <div>
                <CustomLabel>
                  Column width
                </CustomLabel>
                <RangeSlider
                  aria-label='Zoom slider'
                  min={100}
                  max={1000}
                  value={fixedMarkerDistance}
                  onChange={(e) => onHorizontalZoomChange(Number(e.target.value))}
                  className="zoom-slider"
                />
              </div>

              <div>
                <CustomLabel>
                  Row height
                </CustomLabel>
                <RangeSlider
                  min={30}
                  max={200}
                  value={rowHeight}
                  onChange={(e) => onVerticalZoomChange(Number(e.target.value))}
                  className="zoom-slider"
                />
              </div>
            </div>
          </div>
        </div>

        <div className='max-w-md flex justify-center mx-auto pb-4'>
          <div className='py-2 px-3.5 z-10 border border-2 border-gray-500 dark:border-gray-500 rounded-full mx-auto bg-gray-200 dark:bg-gray-700 text-xs font-medium text-gray-900 dark:text-gray-100'>
            Hold SHIFT to scroll horizontally
          </div>
        </div>
      </div>
    </>
  );
};

export default ZoomControls;