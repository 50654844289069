import { useState, useEffect } from 'react';
import opsService from '../../../business/services/opsService';
import { SingleRow, DashboardResponse, DependencyInfo } from '../../../business/models/FamilyTreeInterfaces'; // Import DependencyInfo

const useFetchGanttChartData = (applicationNumber: string) => {
  const [data, setData] = useState<SingleRow[]>([]);
  const [dependencies, setDependencies] = useState<{ [key: string]: DependencyInfo }>({}); // Updated type for dependencies
  const [metaTitle, setMetaTitle] = useState<string>('');
  const [metaQuery, setMetaQuery] = useState<string>('');
  const [totalNumberOfRows, setTotalNumberOfRows] = useState<number>(0);
  const [totalNumberOfRowItems, setTotalNumberOfRowItems] = useState<number>(0);
  const [applicants, setApplicants] = useState<string[]>([]);
  const [requestTime, setRequestTime] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      if (!applicationNumber) {
        return;
      }

      setIsLoading(true);
      setError('');

      try {
        const response: DashboardResponse = await opsService.fetchGanttChartByApplicationNr(applicationNumber);
        
        if (!isCancelled) {
          setData(response.data || []);
          setDependencies(response.dependencies || {});
          setMetaTitle(response.metaInfo?.metaTitle || '');
          setMetaQuery(response.metaInfo?.metaQuery || '');
          setTotalNumberOfRows(response.metaInfo?.totalNumberOfRows || 0);
          setTotalNumberOfRowItems(response.metaInfo?.totalNumberOfRowItems || 0);
          setApplicants(response.metaInfo?.applicants || []);
          setRequestTime(new Date(response.metaInfo?.requestTime || Date.now()));
        }
      } catch (err) {
        if (!isCancelled) {
          setError(err instanceof Error ? err.message : 'Error fetching data');
        }
      } finally {
        if (!isCancelled) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [applicationNumber]);

  return { data, dependencies, metaTitle, metaQuery, totalNumberOfRowItems, totalNumberOfRows, isLoading, applicants, requestTime, error };
};

export default useFetchGanttChartData;
