import React from 'react';

interface HorizontalLineProps {
  startPos: { x: number, y: number };
  endPos: { x: number, y: number };
  text: string;
  rowHeight: number;
  verticalOffset: number;
}

const HorizontalLine: React.FC<HorizontalLineProps> = ({ startPos, endPos, text, verticalOffset }) => {
  const midY = startPos.y - 200 + verticalOffset;
  const pathD = `M ${startPos.x} ${midY} L ${endPos.x - 20} ${midY}`;

  return (
    <g className='opacity-30 hover:opacity-100 transition duration-300 ease-in-out cursor-pointer pointer-events-auto'>
      {/* Transparent wider line for easier cursor interaction */}
      <path
        d={pathD}
        stroke="transparent"
        strokeWidth="20"
        fill="none"
      />
      {/* Visible line */}
      <path
        d={pathD}
        stroke="magenta"
        strokeWidth="2"
        fill="none"
        markerEnd="url(#arrowheadHorizontal)"
      />
      {/* Text label */}
      <text
        x={endPos.x - 80}
        y={midY + 12}
        textAnchor="middle"
        fill="magenta"
        fontSize="12"
      >
        {text}
      </text>
    </g>
  );
};

export default HorizontalLine;
