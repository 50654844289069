import React from 'react';

interface TitleProps {
  titleLanguage: string;
  titleText: string;
  titleChangeDate: string;
  changeGazetteNumber: string;
}

const Titles: React.FC<TitleProps> = ({ titleLanguage, titleText, titleChangeDate, changeGazetteNumber }) => {
  return (
    <div className="relative mx-auto max-w-screen-sm overflow-x-auto rounded shadow-md shadow-gray-500/50 dark:shadow-none mb-8">
      <table className="w-full text-xs lg:text-base text-left ">
        <thead className="text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-white">
          <tr>
            <th scope="col" className="px-2 sm:px-6 py-3 w-1/3">
              Title {titleLanguage}
            </th>
            <th scope="col" className="pr-2 sm:pr-6 py-3 w-2/3">
              <div className="flex items-center justify-start">

              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white">
              <p className="text-gray-700 dark:text-white font-normal">Text:</p>
            </th>
            <td className="pr-2 sm:pr-6">
              <div className="flex justify-start font-normal dark:text-white">
                <p className="flex items-center space-x-3">
                  {titleText}
                </p>
              </div>
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white">
              <p className="text-gray-700 dark:text-white font-normal">Language:</p>
            </th>
            <td className="pr-2 sm:pr-6">
              <div className="flex justify-start font-normal dark:text-white">
                <p className="flex items-center space-x-3">
                  {titleLanguage.toUpperCase()}
                </p>
              </div>
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white">
              <p className="text-gray-700 dark:text-white font-normal">Date:</p>
            </th>
            <td className="pr-2 sm:pr-6">
              <div className="flex justify-start font-normal dark:text-white">
                <p className="flex items-center space-x-3">
                  {titleChangeDate}
                </p>
              </div>
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white">
              <p className="text-gray-700 dark:text-white font-normal">Gazette:</p>
            </th>
            <td className="pr-2 sm:pr-6">
              <div className="flex justify-start font-normal dark:text-white">
                <p className="flex items-center space-x-3">
                  {changeGazetteNumber}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Titles;