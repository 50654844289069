//src/blog/components/LoadingLatestThreeForSideBar.tsx
import React from 'react';
import { Spinner } from 'flowbite-react';

interface LoadingLatestThreeForSideBarProps {
  message?: string;
}

const LoadingLatestThreeForSideBar: React.FC<LoadingLatestThreeForSideBarProps> = ({ message }) => {
  return (
    <div>
      <div className="mb-6 flex items-center">
        <span className="shrink-0">
          <span className="mr-4 max-w-full w-24 h-24 rounded-lg">
            <Spinner
              size='xl'
              color='purple'
            />
          </span>
        </span>
        <div>
          <h5 className="mb-2 text-lg font-bold leading-tight dark:text-white text-gray-900"></h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">{message || 'Fetching content...'}</p>
        </div>
      </div>
      <div className="mb-6 flex items-center">
        <span className="shrink-0">
          <span className="mr-4 max-w-full w-24 h-24 rounded-lg">
            <Spinner
              size='xl'
              color='purple'
            />
          </span>
        </span>
        <div>
          <h5 className="mb-2 text-lg font-bold leading-tight dark:text-white text-gray-900"></h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">{message || 'Fetching content...'}</p>

        </div>
      </div>
      <div className="mb-6 flex items-center">
        <span className="shrink-0">
          <span className="mr-4 max-w-full w-24 h-24 rounded-lg">
            <Spinner
              size='xl'
              color='purple'
            />
          </span>
        </span>
        <div>
          <h5 className="mb-2 text-lg font-bold leading-tight dark:text-white text-gray-900"></h5>
          <p className="mb-2 text-gray-500 dark:text-gray-400">{message || 'Fetching content...'}</p>
        </div>
      </div>
    </div>
  )
};

export default LoadingLatestThreeForSideBar;