import { useMemo } from 'react';
import { SingleRow } from '../../models/FamilyTreeInterfaces';

const useDateBounds = (data: SingleRow[] | undefined) => {
  return useMemo(() => {
    let minDate = Infinity;
    let maxDate = -Infinity;

    // Ensure data is defined and is an array
    if (data && Array.isArray(data)) {
      data.forEach(row => {
        row.row.forEach(item => {
          const itemDate = new Date(item.date).getTime();
          // Check if itemDate is a valid date
          if (!isNaN(itemDate)) {
            minDate = Math.min(minDate, itemDate);
            maxDate = Math.max(maxDate, itemDate);
          }
        });
      });
    }

    // Check if minDate and maxDate are valid before creating Date objects
    const startDate = isFinite(minDate) ? new Date(minDate) : new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    const endDate = isFinite(maxDate) ? new Date(maxDate) : new Date();
    endDate.setFullYear(endDate.getFullYear() + 1);

    return { startDate, endDate };
  }, [data]);
};

export default useDateBounds;
