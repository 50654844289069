import React from 'react';
interface ApplicantProps {
    index: number;
    totalApplicants: number;
    applicantName: string;
    applicantAddress1: string;
    applicantAddress2?: string;
    applicantAddress3?: string;
    applicantAddress4?: string;
    applicantAddress5?: string;
    applicantCountry: string;
    applicantDesignatedStates: string;
}

const Applicant: React.FC<ApplicantProps> = ({
    index,
    totalApplicants,
    applicantName,
    applicantAddress1,
    applicantAddress2,
    applicantAddress3,
    applicantAddress4,
    applicantAddress5,
    applicantCountry,
    applicantDesignatedStates,
}) => {

    let flagSrc = '';
    let flagTitle = applicantCountry; // Set the title to the reference country

    if (applicantCountry === "EP") {
        flagSrc = "https://plevanltd.com/images/light/EPO.webp"; // EP flag
    } else if (applicantCountry === "WO") {
        flagSrc = "https://plevanltd.com/images/light/uno-wipo.webp"; // WO flag
    } else {
        flagSrc = `https://flagcdn.com/w40/${applicantCountry.toLowerCase()}.webp`; // Other country flag
    }

    return (
        <div>
            <div className="relative mx-auto max-w-screen-sm overflow-x-auto rounded shadow-md shadow-gray-500/50 dark:shadow-none mb-8">
                <table className="w-full text-xs lg:text-base text-left ">
                    <thead className="text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-white">
                        <tr>
                            <th scope="col" className="px-2 sm:px-6 py-3 w-1/3">
                                Applicant {index}/{totalApplicants}
                            </th>
                            <th scope="col" className="pr-2 sm:pr-6 py-3 w-2/3">
                                <div className="flex items-center justify-start">
                                    <img id="flagDisplay" className="h-4" src={flagSrc} alt={flagTitle} title={flagTitle} />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
                                <p className="text-gray-700 dark:text-white font-normal">Name:</p>
                            </th>
                            <td className="pr-2 sm:pr-6 w-2/3">
                                <p className="dark:text-white font-normal">{applicantName}</p>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal w-1/3">
                                <p className="text-gray-700 dark:text-white font-normal">Address:</p>
                            </th>
                            <td className="pr-2 sm:pr-6 w-2/3">
                                <p className="dark:text-white font-normal">{applicantAddress1}</p>
                                {applicantAddress2 &&
                                    <p className="dark:text-white font-normal">{applicantAddress2}</p>
                                }
                                {applicantAddress3 &&
                                    <p className="dark:text-white font-normal">{applicantAddress3}</p>
                                }
                                <p className="dark:text-white font-normal">{applicantAddress4}</p>
                                {applicantAddress4 &&
                                    <p className="dark:text-white font-normal">{applicantAddress4}</p>
                                }
                                {applicantAddress5 &&
                                    <p className="dark:text-white font-normal">{applicantAddress5}</p>
                                }
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal">
                                <p className="text-gray-700 dark:text-white font-normal">Country:</p>
                            </th>
                            <td className="pr-2 sm:pr-6">
                                <p className="dark:text-white font-normal">{applicantCountry}</p>
                            </td>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white font-normal">
                                <p className="text-gray-700 dark:text-white font-normal">Designated states:</p>
                            </th>
                            <td className="pr-2 sm:pr-6">
                                {applicantDesignatedStates === 'all' &&
                                    <p className="dark:text-white font-normal">for all designated states</p>
                                }
                                {applicantDesignatedStates !== 'all' &&

                                    <p className="dark:text-white font-normal">{applicantDesignatedStates}</p>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Applicant;