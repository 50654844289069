// src/services/billingService.ts
import axios from 'axios';
import config from '../config/config';
import { axiosBase } from './axiosConfig';

const { SERVER_BASE_URL, SERVER_API_BILLING } = config;
 
const fetchSubscriptionsByEmail = async (email: string) => {
    try {
        const response = await axiosBase.get(`${SERVER_BASE_URL}${SERVER_API_BILLING}subscriptions-by-email`, {
            params: { email }
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching subscription price for ${email}:`, error);
        
        if (axios.isAxiosError(error)) {
            // Axios-specific error handling
            return error.response ? error.response.data : 'An unknown error occurred.';
        } else {
            // Generic error
            return 'An unexpected error occurred.';
        }
    }
};

// Method to cancel a subscription
const cancelSubscription = async (subscriptionId: string) => {
    try {
        const response = await axiosBase.post(
            `${SERVER_BASE_URL}${SERVER_API_BILLING}cancel-subscription`, 
            subscriptionId, 
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );

        // Return the updated subscription data from the response
        return {
            message: response.data.message,
            subscription: response.data.subscription
        };
    } catch (error) {
        console.error(`Error canceling subscription ${subscriptionId}:`, error);

        if (axios.isAxiosError(error)) {
            return error.response ? error.response.data : { message: 'An unknown error occurred.' };
        } else {
            return { message: 'An unexpected error occurred.' };
        }
    }
};


export default {
    fetchSubscriptionsByEmail,
    cancelSubscription // Export the new method
};