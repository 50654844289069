import { Spinner } from 'flowbite-react';
import { useEffect } from 'react';

interface LoaderProps {
  applicationNumber: string;
}

const DashboardLoader: React.FC<LoaderProps> = ({ applicationNumber }) => {
  // useEffect to scroll to the top on every render
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  
  return (
    <div className='bg-white dark:bg-gray-900'>
      <div className="flex justify-center">
        <div className="mt-60 max-w-xl">
          <Spinner size='xl' color='purple' />
        </div>
      </div>
      <div className="flex justify-center">
        <div className="max-w-xl mb-60 text-center">
          <h2 className="mt-10 mb-8 tracking-tight font-semibold text-gray-900 dark:text-white text-2xl md:text-4xl lg:text-5xl">
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-400 from-purple-700">
              Building patent family tree for {applicationNumber}...
            </span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default DashboardLoader;
