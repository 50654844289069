// src/components/dashboard/accountsettings/DeleteAccount.tsx
import React, { useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext'; // Import the custom hook if you want to use auth data
import { userService } from '../../../services/userService';
import { Label, TextInput, Button } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import SuccessAlert from '../../../htmlBlocks/SuccessAlert';
import ErrorAlert from '../../../htmlBlocks/ErrorAlert';

interface DeleteAccountProps {
}

const DeleteAccount: React.FC<DeleteAccountProps> = () => {
    // Safely use useAuthContext
    const authContext = useAuthContext();
    if (!authContext) {
        // Handle the case where authContext is null (e.g., render nothing or show a message)
        return <div>Authentication context not available.</div>;
    }

    // Now safe to destructure logout
    const { logout } = authContext;
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleDeleteAccount = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            const response = await userService.deleteAccount({ password });
            setSuccessMessage(response);
            logout();
            navigate('/'); // Redirect to home page or a specific route after account deletion
        } catch (error: unknown) {
            let message = 'Unknown error occurred';
            if (error instanceof Error) {
                message = error.message;
            }
            setErrorMessage(message);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to clear messages
    const clearMessages = () => {
        setErrorMessage('');
        setSuccessMessage('');
    };

    return (
        <div>
            <form onSubmit={handleDeleteAccount} className="p-4 bg-white border border-gray-200 sm:p-6 xl:p-8 dark:bg-gray-800 dark:border-gray-700 my-shadow-sharp rounded-lg">
                {
                    successMessage &&
                    <SuccessAlert
                        successMessage={successMessage}
                        clearMessages={clearMessages}
                    />
                }
                {
                    errorMessage &&
                    <ErrorAlert
                        errorMessage={errorMessage}
                        clearMessages={clearMessages}
                    />
                }
                <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
                    Delete user account
                </h2>
                <div className="col-span-6 mb-6 sm:col-span-3">
                    <Label htmlFor={password} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Your password
                    </Label>
                    <TextInput
                        id="Password"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder=""
                        required
                        autoComplete='password'
                    />
                </div>
                <div className="col-span-6 sm:col-full">
                    <Button color="failure" size="lg" type="submit" disabled={password.length === 0 || isLoading}
                    >
                        {isLoading && (
                            <span className="flex justify-center items-center">
                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                            </span>
                        )}
                        <span className="flex justify-center items-center">
                            {isLoading ? 'Attempting to delete user...' : 'Delete user account'}
                        </span>
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default DeleteAccount;
