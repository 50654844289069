import React from 'react';

interface ApplicationReferenceProps {
  referenceCountry: string;
  referenceDoc: string;
  referenceDate?: string;
  referenceChangeGazetteNumber?: string;
}

const ApplicationReference: React.FC<ApplicationReferenceProps> = ({ referenceCountry, referenceDoc, referenceDate, referenceChangeGazetteNumber }) => {

  let flagSrc = '';
  let flagTitle = '';
  let isRoDisplayed = false;

  if (referenceCountry !== "WO") {
    if (referenceCountry === "EP") {
      flagSrc = "https://plevanltd.com/images/light/EPO.webp";
    } else {
      flagSrc = `https://flagcdn.com/w40/${referenceCountry.toLowerCase()}.webp`;
    }
    flagTitle = referenceCountry;
  } else {
    const extractedCharacters = referenceDoc.length >= 8 ? referenceDoc.substring(6, 8) : '';
    flagTitle = extractedCharacters;

    if (extractedCharacters === "EP") {
      flagSrc = "https://plevanltd.com/images/light/EPO.webp";
    } else if (extractedCharacters === "IB") {
      flagSrc = "https://plevanltd.com/images/light/uno-wipo.webp";
    } else {
      flagSrc = `https://flagcdn.com/w40/${extractedCharacters.toLowerCase()}.webp`;
    }

    isRoDisplayed = true;
  }
  const epoRegisterUrl = `https://register.epo.org/application?number=EP${referenceDoc}`;


  return (
    <div className="relative mx-auto max-w-screen-sm overflow-x-auto rounded shadow-md shadow-gray-500/50 dark:shadow-none mb-8">
      <table className="w-full text-xs lg:text-base text-left ">
        <thead className="text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-white">
          <tr>
            <th scope="col" className="px-2 sm:px-6 py-3 w-1/3">
              {referenceCountry !== "WO" ? `${referenceCountry} ${referenceDoc}` : referenceDoc}
            </th>
            <th scope="col" className="pr-2 sm:pr-6 py-3 w-1/3">
              <div className="flex items-center justify-start">
                <img id="flagDisplay" className="h-4" src={flagSrc} alt={flagTitle} title={flagTitle} />
                {isRoDisplayed && <span>(RO)</span>}
              </div>
            </th>
            <th scope="col" className="py-3 w-1/3 pr-2 sm:pr-6">
              <div className="flex justify-start items-center">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white">
              <p className="text-gray-700 dark:text-white font-normal">
                EPO register link:
              </p>
            </th>
            <td className="pr-2 sm:pr-6">
              <div className="flex justify-start font-normal dark:text-white">
                <p className="flex items-center space-x-3">
                  <a className="font-medium underline underline-offset-2 text-blue-600 dark:text-blue-500 hover:no-underline"
                    href={epoRegisterUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    {referenceCountry}{referenceDoc}
                  </a>
                </p>
              </div>
            </td>
            <td className="pr-2 sm:pr-6">
              <div className="flex justify-start items-center">
              </div>
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white">
              <p className="text-gray-700 dark:text-white font-normal">
                Country:
              </p>
            </th>
            <td className="pr-2 sm:pr-6">
              <div className="flex justify-start font-normal dark:text-white">
                <p className="flex items-center space-x-3">
                  {referenceCountry}
                </p>
              </div>
            </td>
            <td className="pr-2 sm:pr-6">
              <div className="flex justify-start items-center">
              </div>
            </td>
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white">
              <p className="text-gray-700 dark:text-white font-normal">Application No:</p>
            </th>
            <td className="pr-2 sm:pr-6">
              <div className="flex justify-start font-normal dark:text-white">
                <p className="flex items-center space-x-3">
                  {referenceDoc}
                </p>
              </div>
            </td>
            <td className="pr-2 sm:pr-6">
              <div className="flex justify-start items-center">
              </div>
            </td>
          </tr>
          {referenceDate &&
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white w-1/3">
                <p className="text-gray-700 dark:text-white font-normal">Filing date:</p>
              </th>
              <td className="pr-2 sm:pr-6 w-1/3">
                <div className="flex justify-start font-normal dark:text-white">
                  <p className="flex items-center space-x-3">{referenceDate}</p>
                </div>
              </td>
              <td className="pr-2 sm:pr-6 w-1/3">
                <div className="flex justify-start items-center">
                </div>
              </td>
            </tr>
          }
          {referenceChangeGazetteNumber &&
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th scope="row" className="flex items-center pl-2 sm:pl-6 py-2 text-gray-900 whitespace-nowrap dark:text-white w-1/3">
                <p className="text-gray-700 dark:text-white font-normal">Gazette:</p>
              </th>
              <td className="pr-2 sm:pr-6 w-1/3">
                <div className="flex justify-start font-normal dark:text-white">
                  <p className="flex items-center space-x-3">{referenceChangeGazetteNumber}</p>
                </div>
              </td>
              <td className="pr-2 sm:pr-6 w-1/3">
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
};

export default ApplicationReference;