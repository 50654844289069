// src/components/navigation/DashboardNavigation.tsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RoutePaths } from '../../routes/RoutePaths';


const DashboardNavigation: React.FC
    = () => {

        const location = useLocation();
        // Function to determine if the link is active
        const isActive = (path: string) => location.pathname === path;

        return (
            <div className="border-t border-b border-gray-100 bg-gray-50 dark:border-gray-800 dark:bg-gray-800">
                <div className="w-full px-3 mx-auto max-w-8xl lg:px-3">
                    <ul className="flex flex-wrap -mb-px -ml-4">
                        <li className="mr-2">
                            <Link
                                className={`inline-flex rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b-2 group 
                                        ${isActive(RoutePaths.Dashboard) ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'text-gray-500 dark:text-gray-400 border-gray-50 dark:border-gray-800 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:hover:border-gray-400'}`}
                                to={RoutePaths.Dashboard}
                            >
                                <span className="mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z">
                                        </path>
                                    </svg>
                                </span>
                                Dashboard
                            </Link>
                        </li>
                        <li className="mr-2">
                            <Link
                                className={`inline-flex rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b-2 group 
                                        ${isActive(RoutePaths.MyQueries) ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'text-gray-500 dark:text-gray-400 border-gray-50 dark:border-gray-800 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:hover:border-gray-400'}`}
                                to={RoutePaths.MyQueries}
                            >
                                <span className="mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 10-2 0v1.586l-.293-.293a1 1 0 10-1.414 1.414l2 2 .002.002a.997.997 0 001.41 0l.002-.002 2-2a1 1 0 00-1.414-1.414l-.293.293V9z">
                                        </path>
                                    </svg>
                                </span>
                                My queries
                            </Link>
                        </li>

                        <li className="mr-2">
                            <Link
                                className={`inline-flex rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b-2 group 
                                        ${isActive(RoutePaths.Categories) ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'text-gray-500 dark:text-gray-400 border-gray-50 dark:border-gray-800 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:hover:border-gray-400'}`}
                                to={RoutePaths.Categories}
                            >                                <span className="mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10ZM17 13h-2v-2a1 1 0 0 0-2 0v2h-2a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2Z" />
                                    </svg>
                                </span>
                                Categories
                            </Link>
                        </li>
                        <li className="mr-2">
                            <Link
                                className={`inline-flex rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b-2 group 
                                        ${isActive(RoutePaths.QueryCategoryAssignments) ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'text-gray-500 dark:text-gray-400 border-gray-50 dark:border-gray-800 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:hover:border-gray-400'}`}
                                to={RoutePaths.QueryCategoryAssignments}
                            >                                <span className="mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10ZM17 13h-2v-2a1 1 0 0 0-2 0v2h-2a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2Z" />
                                    </svg>
                                </span>
                                Assign Categories
                            </Link>
                        </li>
                        <li className="mr-2">
                            <Link
                                className={`inline-flex rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b-2 group 
                                        ${isActive(RoutePaths.Settings) ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'text-gray-500 dark:text-gray-400 border-gray-50 dark:border-gray-800 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:hover:border-gray-400'}`}
                                to={RoutePaths.Settings}
                            >
                                <span className="mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                Account settings
                            </Link>
                        </li>
                        <li className="mr-2">
                            <Link
                                className={`inline-flex rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b-2 group 
                                        ${isActive(RoutePaths.Billing) ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'text-gray-500 dark:text-gray-400 border-gray-50 dark:border-gray-800 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:hover:border-gray-400'}`}
                                to={RoutePaths.Billing}
                            >                                   <span className="mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z">
                                        </path>
                                        <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                Billing
                            </Link>
                        </li>
                        <li className="mr-2">
                            <Link
                                className={`inline-flex rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b-2 group 
                                        ${isActive(RoutePaths.Support) ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'text-gray-500 dark:text-gray-400 border-gray-50 dark:border-gray-800 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:hover:border-gray-400'}`}
                                to={RoutePaths.Support}
                            >                                  <span className="mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                Support
                            </Link>
                        </li>
                        <li className="mr-2">
                            <Link
                                className={`inline-flex rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b-2 group 
                                        ${isActive(RoutePaths.Team) ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'text-gray-500 dark:text-gray-400 border-gray-50 dark:border-gray-800 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:hover:border-gray-400'}`}
                                to={RoutePaths.Team}
                            >                                  <span className="mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clipRule="evenodd">
                                        </path>
                                        <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z">
                                        </path>
                                    </svg>
                                </span>
                                Team
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

        );
    }

export default DashboardNavigation;