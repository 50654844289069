//src/blog/components/LoadingRelatedBlogPosts.tsx
import React from 'react';
import { Spinner } from 'flowbite-react';

interface LoadingRelatedBlogPostsProps {
  message?: string;
}

const LoadingRelatedBlogPosts: React.FC<LoadingRelatedBlogPostsProps> = ({ message }) => {
  return (
    <aside aria-label="Related BlogPosts" className="z-20 pt-16 py-8 lg:py-24 bg-white dark:bg-gray-900 relative border-t dark:border-purple-600">
      <div className="px-4 mx-auto max-w-screen-xl">
        <h2 className="mb-6 lg:mb-8 text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">Related BlogPosts</h2>
        <div className="grid gap-12 sm:grid-cols-2">
          <article className="flex flex-col xl:flex-row">
            <div
              className="sm:mr-5 max-w-sm my-shadow relatedBlogPostImg mb-6 item-center justify-center flex"
            >
              <Spinner
                size='xl'
                color='purple'
              />
            </div>
            <div className="flex flex-col justify-top">
              <h3 className="max-w-screen-xs sm:mb-4 text-lg md:text-lg font-bold leading-tight text-gray-900 dark:text-white">
                <span>
                </span>
              </h3>
              <h4 className="mb-2 mt-2 sm:mb-4 font-medium text-lg font-normal dark:text-gray-300 leading-relaxed text-gray-500 dark:text-gray-400 max-w-sm ">
                {message || 'Fetching content...'}
              </h4>
              <p className="text-lg font-normal dark:text-gray-300 leading-relaxed text-gray-500 dark:text-gray-400 max-w-sm ">
              </p>
            </div>
          </article>
          <article className="flex flex-col xl:flex-row">
            <div
              className="sm:mr-5 max-w-sm my-shadow relatedBlogPostImg mb-6 item-center justify-center flex"
            >
              <Spinner
                size='xl'
                color='purple'
              />
            </div>
            <div className="flex flex-col justify-top">
              <h3 className="max-w-screen-xs sm:mb-4 text-lg md:text-lg font-bold leading-tight text-gray-900 dark:text-white">
                <span>
                </span>
              </h3>
              <h4 className="mb-2 mt-2 sm:mb-4 font-medium text-lg font-normal dark:text-gray-300 leading-relaxed text-gray-500 dark:text-gray-400 max-w-sm ">
                {message || 'Fetching content...'}
              </h4>
              <p className="text-lg font-normal dark:text-gray-300 leading-relaxed text-gray-500 dark:text-gray-400 max-w-sm ">
              </p>
            </div>
          </article>
        </div>
      </div>
    </aside>
  )
};

export default LoadingRelatedBlogPosts;