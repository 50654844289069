// src/components/Dashboard.tsx
import React from 'react';
import { useAuthContext } from '../contexts/AuthContext'; // Import the custom hook if you want to use auth data

const Dashboard: React.FC = () => {
    const authContext = useAuthContext();
    if (!authContext) {
        // Handle the case where authContext is null (e.g., render nothing or show a message)
        return null;
    }
    const { user } = authContext;

    return (
        <div>
            <section className="py-4 bg-white dark:bg-gray-900 lg:pt-12 lg:pb-16 dark:bg-black">
                <div className="px-4 mx-auto max-w-8xl lg:px-4 lg:text-center">
                    {user &&
                        <h2 className="mt-16 mb-4 text-2xl font-bold tracking-tight text-gray-900 lg:font-extrabold lg:text-4xl lg:leading-none dark:text-white lg:text-center xl:px-36 lg:mb-7">
                            Welcome to your dashboard, {user.firstName} {user.lastName}
                        </h2>}
                    <p className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 lg:text-center lg:text-xl xl:px-60">
                        The place where you can manage your account, profile, and more.
                    </p>


                    <div className="flex flex-col mb-8 md:flex-row lg:justify-center">
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Dashboard;
