//src/blog/components/LoadingComments.tsx
import React from 'react';
import { Spinner } from 'flowbite-react';

interface LoadingCommentsProps {
  message?: string;
}

const LoadingComments: React.FC<LoadingCommentsProps> = ({ message }) => {
  return (
    <article className="p-6 mb-6 text-base bg-gray-50 rounded-lg dark:bg-gray-700">
      <footer className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          <p className="inline-flex items-center mr-3 font-semibold text-sm text-gray-900 dark:text-white">
            <Spinner
              size='md'
              color='purple'
            />
          </p>
          <p className="text-sm text-gray-600 dark:text-gray-400"><time dateTime="2022-02-08"
            title="February 8th, 2022"></time></p>
        </div>
      </footer>
      <p className="text-gray-500 dark:text-gray-400"> {message || 'Fetching comments...'}
      </p>
      <div className="flex items-center mt-4 space-x-4">
        <button title="default" type="button"
          className="flex items-center font-medium text-sm text-gray-500 hover:underline dark:text-gray-400">
          <svg className="mr-1 w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
            <path d="M17.947 2.053a5.209 5.209 0 0 0-3.793-1.53A6.414 6.414 0 0 0 10 2.311 6.482 6.482 0 0 0 5.824.5a5.2 5.2 0 0 0-3.8 1.521c-1.915 1.916-2.315 5.392.625 8.333l7 7a.5.5 0 0 0 .708 0l7-7a6.6 6.6 0 0 0 2.123-4.508 5.179 5.179 0 0 0-1.533-3.793Z" />
          </svg>
          11 Likes
        </button>
        <button title="default" type="button"
          className="flex items-center font-medium text-sm text-gray-500 hover:underline dark:text-gray-400">
          <svg className="mr-1.5 w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
            <path d="M18 0H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h2v4a1 1 0 0 0 1.707.707L10.414 13H18a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5 4h2a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2ZM5 4h5a1 1 0 1 1 0 2H5a1 1 0 0 1 0-2Zm2 5H5a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Zm9 0h-6a1 1 0 0 1 0-2h6a1 1 0 1 1 0 2Z" />
          </svg>
          Reply
        </button>
      </div>
    </article>

  )
};

export default LoadingComments;

