import React, { useState, useEffect } from 'react';
import { Modal } from 'flowbite-react';
import { RowItem, SingleRow } from "../../models/FamilyTreeInterfaces";
import { formatDate } from "../utils/formatUtils";
import opsService, { PublicationDataDto } from '../../services/opsService';
import { PublishedDataDTO } from '../../models/EpRegisterInterfaces';
import { Spinner } from 'flowbite-react';
import { Button } from 'flowbite-react';
import { Link } from 'react-router-dom';
import Flag from './Flag';

interface RowItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  item: RowItem;
  singleRow: SingleRow;
  flagSrc: string;
  flagTitle: string;
  associatedApplicationRef: string;
}

const RowItemModal: React.FC<RowItemModalProps> = ({
  isOpen,
  onClose,
  item,
  singleRow,
  flagSrc,
  flagTitle,
  associatedApplicationRef
}) => {
  const [data, setData] = useState<PublishedDataDTO | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const newReference = item.fullReference.startsWith(item.country) ? item.fullReference.replace(item.country, "") : item.fullReference;
  const newReference2 = newReference.endsWith(item.kind) ? newReference.replace(item.kind, "") : newReference;
  const epoPdfUrl = `https://data.epo.org/publication-server/pdf-document?pn=${newReference2}&ki=${item.kind}&cc=${item.country}&pd=${item.date?.toString()}`;
  const epoRegisterUrl = `https://register.epo.org/application?number=EP${newReference2}`;
  const displayLinkSlug = associatedApplicationRef.endsWith("A") ? associatedApplicationRef.replace("A", "") : associatedApplicationRef;
  const woSlug = item.fullReference.replace(item.kind, "");

  const publicationDataDto: PublicationDataDto = {
    applicationRef: associatedApplicationRef,
    kind: item.kind
  };

  const handleFetchPublicationData = async () => {
    if (item.fullReference) {
      setIsLoading(true);
      try {
        const response = await opsService.fetchGlobalDataByPublicationNr(publicationDataDto);
        setData(response);
      } catch (err) {
        setError('currently unavailable');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleFetchApplicationData = async () => {
    if (item.fullReference) {
      setIsLoading(true);
      try {
        const response = await opsService.fetchGlobalDataByApplicationNr(item.fullReference);
        setData(response);
      } catch (err) {
        setError('currently unavailable');
        // Handle the error more specifically if you want
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen && item.kind !== "P") {
      const fetchData = item.type === "Application" ? handleFetchApplicationData : handleFetchPublicationData;
      fetchData();
    }
  }, [isOpen, item.type, item.fullReference]);


  return (
    <Modal
      show={isOpen}
      onClose={onClose}
      title={data?.title || item.fullReference}
      size="6xl"

    >
      <div className="bg-white dark:bg-neutral-900 p-8 rounded-lg my-shadow-sharp bg-gray-50 dark:text-white">
        <div className="flex justify-between">
          <div className='pr-4 min-w-20'>
            <Flag flagSrc={flagSrc} flagTitle={flagTitle} />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 flex justify-between">
            <div className='border-0 md:border-l border-neutral-900 dark:border-white md:px-4 font-bold justify-start'>
              {
                item.countryName
              }
            </div>
            <div className='font-bold md:border-l pl-4 border-neutral-900 dark:border-white'>{
            }
              <p> {item.type === "Application" ? item.originalReference : item.fullReference}</p>

              {
                item.plainLanguageType && item.plainLanguageType !== "Unknown Country or Kind"
                  ? item.plainLanguageType
                  : !item.plainLanguageType || item.plainLanguageType === "Unknown Country or Kind"
                    ? item.kind.includes("P") ? "US Provisional "
                      : item.type && item.kind.includes("W") ? "International Application "
                        : item.type && item.kind.includes("B") ? "Granted Patent "
                          : item.type && item.kind.includes("C") ? "Granted Patent "
                            : item.type && item.kind.includes("T") ? "Translation "
                              : "Application "
                    : null
              }
            </div>
            <div className='border-0 md:border-l border-neutral-900 dark:border-white'>
              <div className='md:ml-4 font-bold'>
                {item.type === "Application" ? "Filing date: " : "Publication date: "}{formatDate(item.date)}
              </div>
            </div>
          </div>
          <div className='items-start justify-end'>
            <button
              type='button'
              aria-label="Close modal"
              onClick={onClose}
            >
              <svg className="w-4 h-4 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
            </button>
          </div>
        </div>
        <div className='py-8'>
          {item.kind !== "P" && (
            isLoading ? (
              <div className="flex items-center justify-center">
                <span className='mr-2'><span className='font-bold'>Title:</span></span><Spinner size='xs' color='purple' /><span className='ml-2 italic'>loading...</span>
              </div>
            ) : error ? (
              <div className="flex items-center justify-center">
                <p className='mb-4 text-lg font-normal'><span className='font-bold'>Title:</span> {error}</p>
              </div>
            ) : (
              <div className="flex items-center justify-center">
                <p className='mb-4 text-lg font-normal'><span className='font-bold'>Title:</span> {data?.title}</p>
              </div>

            )
          )}
          {item.kind !== "P" && (
            isLoading ? (
              <div className="flex items-center justify-center">
                <span className='mr-2'><span className='font-bold'>{data?.applicants.length || 0 > 1 ? "Applicants:" : "Applicant: "} </span></span><Spinner size='xs' color='purple' /><span className='ml-2 italic'>loading...</span>
              </div>
            ) : error ? (
              <div className="flex items-center justify-center">

                <p className='mb-4 text-lg font-normal'>{data?.applicants.length || 0 > 1 ? "Applicants: " : "Applicant: "} {error}</p>
              </div>
            ) : (
              <div className="flex items-center justify-center">

                <p className='mb-4 text-lg font-normal'><span className='font-bold'>{data?.applicants.length || 0 > 1 ? "Applicants:" : "Applicant: "} </span>{data?.applicants}</p>
              </div>
            )
          )}
          {item.kind !== "P" && (
            isLoading ? (
              <div className="flex items-center justify-start">
                <span className='mr-2'><span className='font-bold'>Abstract:</span></span><Spinner size='xs' color='purple' /><span className='ml-2 italic'>loading...</span>
              </div>
            ) : error ? (
              <p><span className='font-bold text-lg'>Abstract:</span> {error}</p>
            ) : (
              <>
                <div className="my-4">
                  <hr className='border-neutral-900 dark:border-white' />
                </div>
                <p className='font-bold text-lg'>Abstract:</p><p> {data?.abstract}</p>
                <div className="my-4">
                  <hr className='border-neutral-900 dark:border-white' />
                </div>
              </>
            )

          )}
        </div>

        {item.type === "Application" && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 flex justify-between">
            <div className='border-b md:border-b-0 md:border-l pl-4 pb-4 border-neutral-900 dark:border-white'>
              {
                item.prioRefs ? (
                  <>
                    <p className='mb-4 text-lg font-bold'>
                      {`${item.prioRefs.length} priority ${item.prioRefs.length > 1 ? "claims" : "claim"}`}
                    </p>
                    <ul>
                      {item.prioRefs.map((prioRef, prioIndex) => (
                        <li key={prioIndex}>{prioRef.originalReference}</li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <p className='mb-4 text-lg font-bold'>no priority claims</p>
                )
              }
            </div>
            <div className='border-b md:border-b-0 md:border-l pl-4 border-neutral-900 dark:border-white md:border-0 pb-4'>
              <p className='mb-4 text-lg font-bold'>
                {singleRow.row.filter(siblingItem => siblingItem.type === "Publication").length || "no publications"}
                {singleRow.row.filter(siblingItem => siblingItem.type === "Publication").length > 1 && " publications"
                  || singleRow.row.filter(siblingItem => siblingItem.type === "Publication").length === 1 && " publication"
                }</p>
              <ul>
                {singleRow.row.map((siblingItem, index) => (
                  <li key={index}>
                    {siblingItem.type === "Publication" && (
                      <div>
                        {siblingItem.fullReference}
                        {' - '}
                        {siblingItem.date ? new Date(siblingItem.date).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        }) : "No date"}
                      </div>)}
                    {siblingItem.country === "WO" &&
                      <div className='flex justify-center py-4'>
                        <Button
                          size="md"
                          type="submit"
                          gradientDuoTone="pinkToOrange"
                        >
                          <Link to={`https://patentscope.wipo.int/search/en/detail.jsf?docId=${siblingItem.fullReference.replace(siblingItem.kind, "")}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Go to WIPO register {siblingItem.fullReference}
                          </Link>
                        </Button>
                      </div>}

                  </li>
                ))}
              </ul>
            </div>
            <div className='border-b md:border-0 pb-4 md:border-l pl-4 border-neutral-900 dark:border-white'>
              {item.rootRefs && item.rootRefs.length > 0 && (
                <>
                  <p className='mb-4 text-lg font-bold'>{item.rootRefs.length} {item.rootRefs.length > 1 && "root applications" || "root application"}</p>
                  <ul>
                    {item.rootRefs.map((rootRef, rootIndex) => (
                      <li key={rootIndex}>
                        {rootRef.originalReference}:
                        <strong>
                          <span className='sr-only'>{rootRef.PriorityActiveIndicator}{rootRef.priorityLinkageType}</span>
                          {rootRef.priorityLinkageType === "1" && <span> Prior application claimed for continuation</span>}
                          {rootRef.priorityLinkageType === "0" && <span> Prior application claimed for an addition</span>}
                          {rootRef.priorityLinkageType === "4" && <span> Prior application claimed for a division of a division</span>}
                          {rootRef.priorityLinkageType === "5" && <span> Claimed application is a supplementary disclosure </span>}
                          {rootRef.priorityLinkageType === "I" && <span> Patent application claimed for utility model</span>}
                          {rootRef.priorityLinkageType === "2" && <span> Continuation-in-Part (CIP)</span>}
                          {rootRef.priorityLinkageType === "W" && <span> International (PCT)</span>}
                          {rootRef.priorityLinkageType === "3" && <span> Prior application claimed for a division</span>}
                          {rootRef.priorityLinkageType === "C" && <span> Cabandoned application claimed for a continuation in part </span>}
                          {rootRef.priorityLinkageType === "R" && <span> Request for reexamination number</span>}
                          {rootRef.priorityLinkageType === "B" && <span> Abandoned application claimed for a continuation</span>}
                        </strong>
                      </li>
                    ))}
                  </ul>
                </>
              ) || (<p className='mb-4 text-lg font-bold'>
                no further root applications</p>)
              }

            </div>
          </div>

        )}
        <div className='pt-8'>
          {item.country === "EP" &&
            <div className='flex justify-center py-4'>
              <Button
                size="md"
                type="submit"
                gradientDuoTone="pinkToOrange"
              >
                <Link to={`/display/${displayLinkSlug}`}>
                  More about application {displayLinkSlug}
                </Link>
              </Button>
            </div>}
          {item.country === "WO" &&
            <div className='flex justify-center py-4'>
              <Button
                size="md"
                type="submit"
                gradientDuoTone="pinkToOrange"
              >
                <Link to={`https://patentscope.wipo.int/search/en/detail.jsf?docId=${woSlug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to WIPO register {item.fullReference}
                </Link>
              </Button>
            </div>}
          {item.country === "EP" &&
            <table className="w-full lg:text-base text-left">
              <tbody>
                {item.type === "Publication" &&
                  <tr className="bg-white dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-600">
                    <th scope="row" className="flex items-end pl-2 sm:pl-6 py-2 text-gray-900 sm:whitespace-nowrap dark:text-white font-normal w-1/3">
                      <p className="text-gray-700 dark:text-white font-normal mr-2">Download <strong>{item.kind} publication </strong> from EPO server: </p>
                    </th>
                    <td className="pr-2 sm:pr-6 w-2/3">
                      <p className="dark:text-white font-normal">
                        <a className="font-medium underline underline-offset-2 text-blue-600 dark:text-blue-500 hover:no-underline"
                          href={epoPdfUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >{item.country}{newReference2}{item.kind}
                        </a>
                      </p>
                    </td>
                  </tr>
                }
                {item.type === "Application" &&
                  <tr className="bg-white dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-600">
                    <th scope="row" className="flex items-end pl-2 sm:pl-6 py-2 text-gray-900 sm:whitespace-nowrap dark:text-white font-normal w-1/3">
                      <p className="text-gray-700 dark:text-white font-normal mr-2">EPO register link: </p>
                    </th>
                    <td className="pr-2 sm:pr-6 w-2/3">
                      <p className="dark:text-white font-normal">
                        <a className="font-medium underline underline-offset-2 text-blue-600 dark:text-blue-500 hover:no-underline"
                          href={epoRegisterUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >{item.country}{newReference2}
                        </a>
                      </p>
                    </td>
                  </tr>
                }

              </tbody>
            </table>
          }
        </div>
      </div>
    </Modal >
  );
};

export default RowItemModal;
