// src/components/dashboard/billing/Billing.tsx
import React, { useEffect, useState } from 'react';
import billingService from '../../../services/billingService';
import { useAuthContext } from '../../../contexts/AuthContext';
import { Label, TextInput, Button, Spinner, Alert } from 'flowbite-react';

const Billing: React.FC = () => {
    const authContext = useAuthContext();
    if (!authContext) {
        return <div>Authentication context not available.</div>;
    }

    const userEmail = authContext.user?.email || '';

    const [email] = useState(userEmail);
    const [loading, setLoading] = useState(false);
    const [subscriptionData, setSubscriptionData] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [cancellationMessage, setCancellationMessage] = useState<string | null>(null);

    useEffect(() => {
        if (userEmail) {
            handleFetchSubscriptions();
        }
    }, [userEmail]);

    const handleFetchSubscriptions = async () => {
        setLoading(true);
        setError(null);

        try {
            const data = await billingService.fetchSubscriptionsByEmail(email);
            setSubscriptionData(data);
        } catch (err) {
            setError('Failed to fetch subscriptions. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleCancelSubscription = async (subscriptionId: string) => {
        try {
            const { message, subscription } = await billingService.cancelSubscription(subscriptionId);
            setCancellationMessage(message);

            // Update the specific subscription's status in the frontend
            setSubscriptionData((prevSubscriptions) =>
                prevSubscriptions.map((sub) =>
                    sub.subscriptionId === subscriptionId
                        ? { ...sub, cancelAtPeriodEnd: subscription.cancelAtPeriodEnd }
                        : sub
                )
            );
        } catch (err) {
            console.error(`Failed to cancel subscription ${subscriptionId}:`, err);
            setCancellationMessage('Failed to cancel subscription. Please try again.');
        }
    };


    return (
        <div className="px-4 my-10 mx-auto max-w-8xl lg:px-4">
            <div className="mb-8">
                <h1 className="inline-block text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                    Billing Settings
                </h1>
                <p className="mt-1 text-lg text-gray-500 dark:text-gray-400">
                    View and update details for your account.
                </p>
            </div>

            <div className="mb-6">
                <Label htmlFor="email" value="Your Email" />
                <TextInput
                    id="email"
                    type="email"
                    value={email}
                    readOnly
                    className="mt-2 w-full"
                />
                <Button onClick={handleFetchSubscriptions} disabled={loading} className="mt-4">
                    {loading ? <Spinner size="sm" /> : 'Refresh Subscriptions'}
                </Button>
                {error && <Alert color="failure" className="mt-4">{error}</Alert>}
                {cancellationMessage && <Alert color="info" className="mt-4">{cancellationMessage}</Alert>}
            </div>

            <div className="mt-6">
                {subscriptionData.length > 0 ? (
                    subscriptionData.map((subscription) => (
                        <div
                            key={subscription.subscriptionId}
                            className="border p-4 rounded-lg shadow-md mb-4 bg-white dark:bg-gray-800"
                        >
                            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                                Subscription ID: {subscription.subscriptionId}
                            </h2>
                            <p className="text-gray-500 dark:text-gray-400">
                                Status: <span className="font-medium">{subscription.status}</span>
                            </p>
                            <p className="text-gray-500 dark:text-gray-400">
                                Period Start: {new Date(subscription.currentPeriodStart).toLocaleDateString()}
                            </p>
                            <p className="text-gray-500 dark:text-gray-400">
                                Period End: {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
                            </p>
                            <p className="text-gray-500 dark:text-gray-400">
                                {subscription.cancelAtPeriodEnd
                                    ? "This subscription is scheduled to be canceled at the end of the billing period."
                                    : ""}
                            </p>
                            <h3 className="mt-4 text-md font-semibold text-gray-900 dark:text-white">Items:</h3>
                            {subscription.items.map((item: any, index: number) => (
                                <div key={index} className="ml-4 mt-2">
                                    <p className="text-gray-500 dark:text-gray-400">Product: {item.productName}</p>
                                    <p className="text-gray-500 dark:text-gray-400">Price: ${(item.price / 100).toFixed(2)} {item.currency.toUpperCase()}</p>
                                    <h4 className="mt-2 text-md font-medium text-gray-900 dark:text-white">Invoices:</h4>
                                    {item.invoices.length > 0 ? (
                                        item.invoices.map((invoice: any, idx: number) => (
                                            <div key={idx} className="ml-4 mt-1">
                                                <a
                                                    href={invoice.invoiceUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-500 hover:underline"
                                                >
                                                    View Invoice
                                                </a>
                                                <span className="mx-2">|</span>
                                                <a
                                                    href={invoice.invoicePdf}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-500 hover:underline"
                                                >
                                                    Download PDF
                                                </a>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-gray-500 dark:text-gray-400">No invoices available.</p>
                                    )}
                                </div>
                            ))}
                            {/* Cancel Subscription Button */}
                            {subscription.status === "active" && (
                                <Button
                                    onClick={() => handleCancelSubscription(subscription.subscriptionId)}
                                    color={subscription.cancelAtPeriodEnd ? "gray" : "failure"}
                                    className="mt-4"
                                    disabled={subscription.cancelAtPeriodEnd}
                                >
                                    {subscription.cancelAtPeriodEnd ? "Cancellation Pending" : "Cancel Subscription"}
                                </Button>
                            )}
                        </div>
                    ))
                ) : (
                    !loading && <p className="text-gray-500 dark:text-gray-400">No subscriptions found.</p>
                )}
            </div>
        </div>
    );
};

export default Billing;
