// src/services/categoryService.ts
import { axiosWithInterceptor } from './axiosConfig';
import config from '../config/config';
import axios from 'axios';

export interface CategoryDTO {
    id: string;
    name: string;
    description: string;
    createdAt: string;
    lastEditedAt: string;
}

export interface UpdateCategoryDTO {
    oldCategoryName: string;
    newCategoryName: string;
    description: string;
}

export interface CategoryQueryAssignmentDTO {
    categoryId: string;
    viewRequestLogId: string;
}

export interface CategoryAssignmentByAppDTO {
    categoryId: string;
    applicationNumber: string;
}

export interface QueryCategoryAssignmentDTO {    
    viewRequestLogId: string;
    applicationNumber: string;
    categoryIds: string[];
    categoryNames: string[];
}


export interface CreateCategoryDTO {
    name: string;
    description: string;
}


const { SERVER_BASE_URL, SERVER_API_CATEGORIES } = config;

class CategoryService {
        static async getCategories(): Promise<CategoryDTO[]> {    
            try {
                const response = await axiosWithInterceptor.get(`${SERVER_BASE_URL}${SERVER_API_CATEGORIES}getCategories`);
                if (response.status === 200) {
                    return response.data;
                } else {
                    throw new Error('Unexpected response status');
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        // Handle unauthorized error
                        if (error.response.status === 401 || error.response.status === 403) {
                            throw new Error('You are not authorized to perform this action.');
                        }
                        // Handle bad request
                        if (error.response.status === 400) {
                            throw new Error(error.response.data || 'Bad request');
                        }
                    }
                    throw new Error(error.message || 'Network error');
                }
                throw error; // Throw other types of errors (non-Axios errors)
            }
        }

        static async deleteCategory(categoryId: string): Promise<any> {
    try {
        // Update the request method to DELETE and pass categoryId in the URL
        const response = await axiosWithInterceptor.delete(`${SERVER_BASE_URL}${SERVER_API_CATEGORIES}deleteCategory/${categoryId}`);
        
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response status');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Handle unauthorized error
                if (error.response.status === 401 || error.response.status === 403) {
                    throw new Error('You are not authorized to perform this action.');
                }
                // Handle not found
                if (error.response.status === 404) {
                    throw new Error(error.response.data || 'Category not found.');
                }
                // Handle bad request
                if (error.response.status === 400) {
                    throw new Error(error.response.data || 'Bad request');
                }
            }
            throw new Error(error.message || 'Network error');
        }
        throw error; // Throw other types of errors (non-Axios errors)
    }
}


       
}
export default CategoryService;


const fetchCategoryDetails = async (categoryId: string) => {
    try {
        const response = await axiosWithInterceptor.get(`${SERVER_BASE_URL}${SERVER_API_CATEGORIES}getCategory/${categoryId}`);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response status');
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Handle unauthorized error
                if (error.response.status === 401 || error.response.status === 403) {
                    throw new Error('You are not authorized to perform this action.');
                }
                // Handle not found
                if (error.response.status === 404) {
                    throw new Error('Category not found');
                }
                // Handle bad request
                if (error.response.status === 400) {
                    throw new Error(error.response.data || 'Bad request');
                }
            }
            throw new Error(error.message || 'Network error');
        }
        throw error; // Throw other types of errors (non-Axios errors)
    }
};
export { fetchCategoryDetails };


 const updateCategory = async (categoryData: UpdateCategoryDTO) => {
        try {
            const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_CATEGORIES}updateCategory`, categoryData);

            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    // Handle unauthorized error
                    if (error.response.status === 401 || error.response.status === 403) {
                        throw new Error('You are not authorized to perform this action.');
                    }
                    // Handle bad request
                    if (error.response.status === 400) {
                        throw new Error(error.response.data || 'Bad request');
                    }
                }
                throw new Error(error.message || 'Network error');
            }
            throw error; // Throw other types of errors (non-Axios errors)
        }
    };

    export { updateCategory };

    const createCategory = async (categoryData: CreateCategoryDTO) => {
        try {
            const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_CATEGORIES}createCategory`, categoryData);

            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    // Handle unauthorized error
                    if (error.response.status === 401 || error.response.status === 403) {
                        throw new Error('You are not authorized to perform this action.');
                    }
                    // Handle bad request
                    if (error.response.status === 400) {
                        throw new Error(error.response.data || 'Bad request');
                    }
                }
                throw new Error(error.message || 'Network error');
            }
            throw error; // Throw other types of errors (non-Axios errors)
        }
    };
    export { createCategory };

    //write an api call to assign a user to a category
   const assignQueryToCategory = async (categoryQueryAssignmentData: CategoryQueryAssignmentDTO) => {
    try {
        const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_CATEGORIES}assignCategory`, {
            categoryId: categoryQueryAssignmentData.categoryId, // Ensure correct property names
            viewRequestLogId: categoryQueryAssignmentData.viewRequestLogId,
        });

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Handle unauthorized error
                if (error.response.status === 401 || error.response.status === 403) {
                    throw new Error('You are not authorized to perform this action.');
                }
                // Handle bad request
                if (error.response.status === 400) {
                    throw new Error(error.response.data.join('\n') || 'Bad request'); // Assuming the errors are sent as an array of strings
                }
            }
            throw new Error(error.message || 'Network error');
        }
        throw error; // Throw other types of errors (non-Axios errors)
    }
};
export { assignQueryToCategory };


const unassignQueryToCategory = async (categoryQueryUnassignmentData: CategoryQueryAssignmentDTO) => {
    try {
        const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_CATEGORIES}unassignCategory`, {
            categoryId: categoryQueryUnassignmentData.categoryId, // Ensure correct property names
            viewRequestLogId: categoryQueryUnassignmentData.viewRequestLogId,
        });

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Handle unauthorized error
                if (error.response.status === 401 || error.response.status === 403) {
                    throw new Error('You are not authorized to perform this action.');
                }
                // Handle bad request
                if (error.response.status === 400) {
                    throw new Error(error.response.data.join('\n') || 'Bad request'); // Assuming the errors are sent as an array of strings
                }
            }
            throw new Error(error.message || 'Network error');
        }
        throw error; // Throw other types of errors (non-Axios errors)
    }
};
export { unassignQueryToCategory };


const categoryUnassignment = async (categoryUnassignmentData: CategoryAssignmentByAppDTO) => {
    try {
        const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_CATEGORIES}unassignCategoryByApplicationNumber`, {
            categoryId: categoryUnassignmentData.categoryId, // Ensure correct property names
            applicationNumber: categoryUnassignmentData.applicationNumber,
        });

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Handle unauthorized error
                if (error.response.status === 401 || error.response.status === 403) {
                    throw new Error('You are not authorized to perform this action.');
                }
                // Handle bad request
                if (error.response.status === 400) {
                    throw new Error(error.response.data.join('\n') || 'Bad request'); // Assuming the errors are sent as an array of strings
                }
            }
            throw new Error(error.message || 'Network error');
        }
        throw error; // Throw other types of errors (non-Axios errors)
    }
};
export { categoryUnassignment };


const categoryAssignment = async (categoryAssignmentData: CategoryAssignmentByAppDTO) => {
    try {
        const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_CATEGORIES}assignCategoryByApplicationNumber`, {
            categoryId: categoryAssignmentData.categoryId, // Ensure correct property names
            applicationNumber: categoryAssignmentData.applicationNumber,
        });

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Handle unauthorized error
                if (error.response.status === 401 || error.response.status === 403) {
                    throw new Error('You are not authorized to perform this action.');
                }
                // Handle bad request
                if (error.response.status === 400) {
                    throw new Error(error.response.data.join('\n') || 'Bad request'); // Assuming the errors are sent as an array of strings
                }
            }
            throw new Error(error.message || 'Network error');
        }
        throw error; // Throw other types of errors (non-Axios errors)
    }
};
export { categoryAssignment };


const getAllCategoryAssignments = async () => {
    try {
        const response = await axiosWithInterceptor.get(`${SERVER_BASE_URL}${SERVER_API_CATEGORIES}allCategoryAssignments`);
        const categoryAssignments: QueryCategoryAssignmentDTO[] = response.data;
        return categoryAssignments;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Handle unauthorized error
                if (error.response.status === 401 || error.response.status === 403) {
                    throw new Error('You are not authorized to perform this action.');
                }
                // Handle bad request
                if (error.response.status === 400) {
                    throw new Error(error.response.data.join('\n') || 'Bad request'); // Assuming the errors are sent as an array of strings
                }
            }
            throw new Error(error.message || 'Network error');
        }
        throw error; // Throw other types of errors (non-Axios errors)
    }
};

export { getAllCategoryAssignments };


export interface AllCategoryAssignmentsPerApplicationNumberDTO {
    applicationNumber: string;
    categoryIds: string[];
}

const getAllCategoryAssignmentsPerApplicationNumber = async (applicationNumber: string) => {
    try {
        const response = await axiosWithInterceptor.get(`${SERVER_BASE_URL}${SERVER_API_CATEGORIES}getAllCategoryAssignmentsPerApplicationNumber/${applicationNumber}`);
        const categoryAssignments: AllCategoryAssignmentsPerApplicationNumberDTO[] = response.data;
        return categoryAssignments;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Handle unauthorized error
                if (error.response.status === 401 || error.response.status === 403) {
                    throw new Error('You are not authorized to perform this action.');
                }
                // Handle bad request
                if (error.response.status === 400) {
                    throw new Error(error.response.data.join('\n') || 'Bad request'); // Assuming the errors are sent as an array of strings
                }
            }
            throw new Error(error.message || 'Network error');
        }
        throw error; // Throw other types of errors (non-Axios errors)
    }
};

export { getAllCategoryAssignmentsPerApplicationNumber };
